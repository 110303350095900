<template>
  <div class="markets-per-player">
    <div
      v-if="hasPlayersMarketsData || search"
      class="section-header"
    >
      <div class="section-header-content">
        <span> Markets per player </span>
        <TextInput
          class="search-field"
          :model-value="search"
          @update:modelValue="updateSearch"
          icon="search"
          placeholder="Search"
          small
        />
      </div>
      <div class="bulk-actions">
        <div
          v-if="isPlayerMarketActionsDisabled"
          @click.stop
          class="markets-per-player-actions-disabled-layer"
        />
        <Checkbox
          @update:modelValue="togglePlayersForMarketsModal"
          :model-value="allPlayersSelectedForMarketsModal"
          :disabled="!!search"
        />
        <span v-if="numOflayersForMarketsPopupList">
          {{ numOflayersForMarketsPopupList }} player{{ numOflayersForMarketsPopupList > 1 ? 's' : '' }} selected
        </span>
        <span v-else>
          No players selected
        </span>
      </div>
    </div>
    <div
      v-if="hasPlayersMarketsData"
      class="markets-container"
    >
      <div
        v-for="data in playersByTeamMarketsData"
        :key="data.playerId"
      >
        <MarketsPerPlayerSingle
          :player-market-info="data"
          :team-label="teamData.teamLabel"
          :sport-id="teamData.sportId"
        />
      </div>
    </div>
    <div
      v-if="!hasPlayersMarketsData"
      class="no-data-message"
    >
      <p>
        Currently there are no players offered for this team, and by that we
        can not offer any player props.
      </p>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import {
  map, filter, toLower, includes, every, orderBy, pickBy, identity,
} from 'lodash';
import { mapStatistics } from '@/services/helpers/players';
import sportIds from '@/services/helpers/sports';
import TextInput from '@/components/common/TextInput';
import Checkbox from '@/components/common/Checkbox';
import MarketsPerPlayerSingle from './MarketsPerPlayerSingle';

const {
  FOOTBALL_ID,
  BASEBALL_ID,
} = sportIds;

export default {
  components: {
    TextInput,
    Checkbox,
    MarketsPerPlayerSingle,
  },
  props: {
    teamData: {
      type: Object,
      default: () => {},
    },
    playerTypeActiveTab: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const store = useStore();
    const search = ref('');
    const data = computed(() => props.teamData);
    const playerSetupTableData = computed(() => store.getters.playerSetupTableData);
    const statsByPlayerId = computed(() => mapStatistics(props.teamData.lineup));
    const playersByTeamMarketsData = computed(() => {
      let players = [];
      if (props.teamData.sportId === BASEBALL_ID) {
        players = map(
          (playerSetupTableData.value?.[data.value?.teamLabel] || []),
          (playerData) => {
            const stats = statsByPlayerId.value?.[playerData.playerId];
            return {
              playerId: playerData.playerId,
              playerName: playerData.playerName,
              playerIndex: playerData.playerIndex,
              markets: playerData.marketCodeDetails || [],
              isSuspended: playerData.isSuspended,
              playerLocked: playerData.playerLocked,
              lineup: playerData.lineup,
              stats: pickBy({
                batter: stats?.batterDetails,
                pitcher: stats?.pitcherDetails,
              }, identity),
              playerPositionIndex: playerData.playerPositionIndex || 1,
              isPitcher: playerData.isPitcher,
              isHitter: playerData.isHitter,
            };
          },
        );
      } else {
        players = map(
          (playerSetupTableData.value?.[data.value?.teamLabel] || []),
          (playerData) => ({
            playerId: playerData.playerId,
            playerName: playerData.playerName,
            playerIndex: playerData.playerIndex,
            markets: playerData.marketCodeDetails || [],
            isSuspended: playerData.isSuspended,
            playerLocked: playerData.playerLocked,
            lineup: playerData.lineup,
            stats: statsByPlayerId.value?.[playerData.playerId],
            playerPositionIndex: playerData.playerPositionIndex || 1,
            // AF additional params
            isQuarterback: playerData.isQuarterback,
            isOffensiveNonQB: playerData.isOffensiveNonQB,
            isKicker: playerData.isKicker,
            isDefensive: playerData.isDefensive,
          }),
        );
      }
      if (props.playerTypeActiveTab && data.value?.sportId === BASEBALL_ID) {
        players = props.playerTypeActiveTab === 'pitchers'
          ? filter(players, { isPitcher: true })
          : filter(players, { isHitter: true });
      }
      if (props.playerTypeActiveTab && data.value?.sportId === FOOTBALL_ID) {
        if (props.playerTypeActiveTab === 'quarterbacks') {
          players = filter(players, { isQuarterback: true });
        } else if (props.playerTypeActiveTab === 'non-quarterbacks') {
          players = filter(players, { isOffensiveNonQB: true });
        } else if (props.playerTypeActiveTab === 'defensive') {
          players = filter(players, { isDefensive: true });
        } else {
          players = filter(players, { isKicker: true });
        }
      }
      return orderBy(filter(
        players,
        (player) => includes(toLower(player.playerName), toLower(search.value)),
      ), 'playerPositionIndex');
    });
    const hasPlayersMarketsData = computed(() => !!playersByTeamMarketsData.value.length);
    const allPlayersSelectedForMarketsModal = computed(() => {
      if (!playerSetupTableData.value) return false;

      let playersByTeam = playerSetupTableData.value[data.value.teamLabel];
      if (props.playerTypeActiveTa && data.value.sportId === BASEBALL_ID) {
        playersByTeam = props.playerTypeActiveTab === 'pitchers'
          ? filter(playersByTeam, { isPitcher: true })
          : filter(playersByTeam, { isHitter: true });
      }
      if (props.playerTypeActiveTab && data.value.sportId === FOOTBALL_ID) {
        if (props.playerTypeActiveTab === 'quarterbacks') {
          playersByTeam = filter(playersByTeam, { isQuarterback: true });
        } else if (props.playerTypeActiveTab === 'non-quarterbacks') {
          playersByTeam = filter(playersByTeam, { isOffensiveNonQB: true });
        } else if (props.playerTypeActiveTab === 'defensive') {
          playersByTeam = filter(playersByTeam, { isDefensive: true });
        } else {
          playersByTeam = filter(playersByTeam, { isKicker: true });
        }
      }
      if (!playersByTeam?.length) return false;

      const selectedPlayers = store.getters.playersForMarketsPopupList;

      return every(playersByTeam, (player) => includes(selectedPlayers, player.playerId));
    });
    const numOflayersForMarketsPopupList = computed(() => {
      if (!playerSetupTableData.value) return false;

      const playersByTeam = playerSetupTableData.value[data.value.teamLabel];
      if (!playersByTeam?.length) return false;

      const selectedPlayers = store.getters.playersForMarketsPopupList;

      return filter(playersByTeam, (player) => includes(selectedPlayers, player.playerId))?.length;
    });
    const isPlayerMarketActionsDisabled = computed(() => store.getters.playerSetupInferActive);

    const updateSearch = (val) => { search.value = val; };

    const togglePlayersForMarketsModal = () => {
      store.dispatch('toggleAllByTeamPlayersForMarketsPopupList',
        {
          teamLabel: data.value.teamLabel,
          isToggled: allPlayersSelectedForMarketsModal.value,
          activeTab: props.playerTypeActiveTab,
        });
    };

    return {
      search,
      statsByPlayerId,
      playersByTeamMarketsData,
      hasPlayersMarketsData,
      allPlayersSelectedForMarketsModal,
      numOflayersForMarketsPopupList,
      isPlayerMarketActionsDisabled,
      updateSearch,
      togglePlayersForMarketsModal,
    };
  },
};
</script>

<style lang="scss">
.markets-per-player {
  height: 50%;
  overflow: auto;
  border-top: 1px solid $gray300;
  position: relative;

  .section-header {
    padding: 32px 0 8px 0;
    position: sticky;
    top: 0;
    left: 0;
    background-color: $white;
    z-index: 200;

    .section-header-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 1px;
      span {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: $gray700;
      }

      .search-field {
        min-width: 200px;
        width: 45%;
        .icon {
          stroke: $gray600;
        }
      }
    }
    .bulk-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 16px;
      background-color: $gray300;
      min-height: 44px;
      margin-top: 8px;
      position: relative;

      .markets-per-player-actions-disabled-layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .5);
        z-index: 1;
      }

      span {
        font-size: 14px;
        line-height: 16px;
        color: $gray800;
      }
    }
  }
  .markets-container {
    padding-top: 12px;
  }
  .no-data-message {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    p {
      width: 55%;
      line-height: 17px;
      text-align: center;
      color: $gray700;
    }
  }
}
</style>
