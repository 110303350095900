<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.66634 12.0007C8.66634 12.3688 8.36786 12.6673 7.99967 12.6673C7.63148 12.6673 7.33301 12.3688 7.33301 12.0007C7.33301 11.6325 7.63148 11.334 7.99967 11.334C8.36786 11.334 8.66634 11.6325 8.66634 12.0007Z"
      fill="#175FFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.2766 5.85248C11.3898 6.45145 11.332 7.07263 11.109 7.63877C10.886 8.20512 10.5063 8.69457 10.0127 9.04149C9.72421 9.24427 9.3601 9.37264 9.04001 9.45141C8.94111 9.47575 8.84057 9.49676 8.74042 9.51424V9.66732C8.74042 10.0355 8.44194 10.334 8.07375 10.334C7.70556 10.334 7.40708 10.0355 7.40708 9.66732V8.90974C7.40708 8.54155 7.70556 8.24308 8.07375 8.24308C8.24434 8.24308 8.4836 8.21522 8.72141 8.1567C8.96781 8.09606 9.15075 8.01759 9.24597 7.95067C9.51984 7.75817 9.73821 7.48093 9.86842 7.15026C9.9987 6.81938 10.0333 6.45351 9.96645 6.10021C9.89966 5.74701 9.73556 5.42696 9.49974 5.1789C9.26426 4.93119 8.96844 4.76663 8.65168 4.70035C8.33521 4.63413 8.00678 4.66777 7.70639 4.79866C7.40553 4.92976 7.14288 5.15421 6.95565 5.44897C6.74719 5.77717 6.66634 6.0078 6.66634 6.33399C6.66634 6.70218 6.36786 7.00065 5.99967 7.00065C5.63148 7.00065 5.33301 6.70218 5.33301 6.33399C5.33301 5.68925 5.52586 5.21318 5.83017 4.73408C6.15572 4.22155 6.62191 3.81679 7.17378 3.57633C7.7261 3.33566 8.33598 3.27208 8.92476 3.39528C9.51324 3.51842 10.0489 3.82141 10.4661 4.26025C10.8829 4.69873 11.1633 5.2534 11.2766 5.85248Z"
      fill="#175FFF"
    />
  </svg>
</template>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
