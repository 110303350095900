<template>
  <div
    :class="['market-configurations-popup', {'close-popup': closePopupOpen}]"
    ref="configurationsPopupRef"
  >
    <div class="market-configurations-header">
      <div class="market-configurations-name">
        {{ configuration.name }}
      </div>
      <div class="market-configurations-date">
        {{ templateUpdateInfo }}
      </div>
    </div>
    <div class="market-configurations-rulesets">
      <div class="rulesets-header">
        <div class="rulesets-label">
          Rulesets
        </div>
        <Tooltip :text="'Set of rules that can be modified and applied based on the competition.'">
          <Icon :name="'info'" />
        </Tooltip>
      </div>
      <div
        class="rulesets-tabs"
        ref="rulesetTabRef"
      >
        <div
          :class="['ruleset-tab',
                   {'selected': selectedRuleset.sportsLogicTemplateId === ruleset.sportsLogicTemplateId},
                   {'disabled': !ruleset.enabled}]"
          v-for="(ruleset, index) in configuration.configurationRulesets"
          :key="index"
          @click="selectRuleset(ruleset)"
          @mouseenter="displayRulesetSettingsIcon(index, true)"
          @mouseleave="displayRulesetSettingsIcon(index)"
        >
          {{ ruleset.sportLogicTemplate.name }}
          <Icon
            :name="'more-dots'"
            v-if="ruleset.displaySettingsIcon || ruleset.dropdownOpened"
            @click.stop="rulesetDropdownToggle(index)"
          />
          <div
            class="ruleset-dropdown"
            v-if="ruleset.dropdownOpened"
          >
            <div
              class="ruleset-dropdown-option"
              @click.stop="disableRuleset(index)"
            >
              {{ ruleset.enabled ? 'Disable ruleset' : 'Enable ruleset' }}
            </div>
            <div
              class="ruleset-dropdown-option"
              @click.stop="restoreDefaultRuleset(index)"
            >
              Restore default
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="market-configuration-ps">
      <div
        class="disabled-ruleset"
        v-if="!selectedRuleset.enabled"
      >
        <div class="disabled-ruleset-label">
          <Icon :name="'alert-cycle'" />
          This ruleset is currently disabled
        </div>
        <div
          class="disabled-ruleset-enable"
          @click="enableRuleset"
        >
          Enable
        </div>
      </div>
      <div class="market-configurations-body">
        <div class="market-configurations-body-tabs-header">
          <div class="tabs-header-label">
            Market offering and displaying configuration
          </div>
          <div class="tabs-header-switch">
            Sync offering and displaying
            <Toggle
              :model-value="syncToggled"
              @update:model-value="value => { toggleSync(value) }"
            />
          </div>
        </div>
        <div class="market-configurations-body-tabs">
          <div
            :class="['market-configurations-body-tab', {'selected': selectedConfiguration === 'risk'}]"
            @click="selectConfiguration('risk')"
          >
            Risk
          </div>
          <div
            :class="['market-configurations-body-tab', {'selected': selectedConfiguration === 'offering'}]"
            @click="selectConfiguration('offering')"
          >
            {{ syncToggled ? 'Offering & displaying' : 'Offering' }}
          </div>
          <div
            :class="['market-configurations-body-tab', {'selected': selectedConfiguration === 'display'}]"
            @click="selectConfiguration('display')"
            v-if="!syncToggled"
          >
            Displaying
          </div>
          <div
            v-if="currentDefaultTemplateConfiguration"
            :class="['market-configurations-body-tab', {'selected': selectedConfiguration === 'line'}]"
            @click="selectConfiguration('line')"
          >
            Line Config.
          </div>
          <div
            :class="['market-configurations-body-tab', {'selected': selectedConfiguration === 'cashout'}]"
            @click="selectConfiguration('cashout')"
          >
            Cashout
          </div>
          <div
            :class="['market-configurations-body-tab', {'selected': selectedConfiguration === 'resulting'}]"
            @click="selectConfiguration('resulting')"
          >
            Resulting
          </div>
          <div
            :class="['market-configurations-body-tab', {'selected': selectedConfiguration === 'selections'}]"
            @click="selectConfiguration('selections')"
          >
            Selections
          </div>
        </div>
        <div
          class="market-configurations-content"
          v-if="selectedConfiguration === 'risk'"
        >
          <!-- Risk (Limits) -->
          <div
            class="risk-configuration-section limit"
            v-if="selectedRuleset.limitConfiguration"
          >
            <div
              class="risk-configuration-group limit"
              v-if="selectedRuleset.limitConfiguration.type === 'SIMPLE_TIERED'"
            >
              <div class="label">
                MARKET TIER
              </div>
              <div class="risk-dropdown">
                <Dropdown :label="limitTiers[selectedRuleset.limitConfiguration.tier]?.label">
                  <DropdownItem
                    v-for="tier in limitTiers"
                    :key="tier.id"
                    clickable
                    placeholder
                    @click="applyLimitTier(tier.id)"
                  >
                    {{ tier.label }}
                  </DropdownItem>
                </Dropdown>
              </div>
            </div>
            <div
              class="risk-configuration-group limit"
              v-if="selectedRuleset.limitConfiguration.type === 'MAIN_ALT_TIERED'"
            >
              <div class="label">
                MARKET TIER - MAIN LINE
              </div>
              <div class="risk-dropdown">
                <Dropdown :label="limitTiers[selectedRuleset.limitConfiguration.mainTier]?.label">
                  <DropdownItem
                    v-for="tier in limitTiers"
                    :key="tier.id"
                    clickable
                    @click="applyLimitTier(tier.id, 'main')"
                  >
                    {{ tier.label }}
                  </DropdownItem>
                </Dropdown>
              </div>
            </div>
            <div
              class="risk-configuration-group limit"
              v-if="selectedRuleset.limitConfiguration.type === 'MAIN_ALT_TIERED'"
            >
              <div class="label">
                MARKET TIER - ALTERNATIVE
              </div>
              <div class="risk-dropdown">
                <Dropdown :label="limitTiers[selectedRuleset.limitConfiguration.altTier]?.label">
                  <DropdownItem
                    v-for="tier in limitTiers"
                    :key="tier.id"
                    clickable
                    placeholder
                    @click="applyLimitTier(tier.id, 'alt')"
                  >
                    {{ tier.label }}
                  </DropdownItem>
                </Dropdown>
              </div>
            </div>
          </div>
          <!-- Hold (Margin) -->
          <div
            class="risk-configuration-section margin"
            v-if="selectedRuleset.marginConfiguration"
          >
            <div class="risk-configuration-group limit">
              <div class="label">
                HOLD APPLICATION
              </div>
              <div class="risk-dropdown">
                <Dropdown
                  :label="marginTypes[selectedRuleset.marginConfiguration.type]?.label"
                  :placeholder="'Select application'"
                >
                  <DropdownItem
                    v-for="margin in marginTypes"
                    :key="margin.id"
                    clickable
                    @click="applyMarginType(margin.type)"
                  >
                    {{ margin.label }}
                  </DropdownItem>
                </Dropdown>
              </div>
            </div>
            <div
              class="risk-configuration-group margin"
              v-if="selectedRuleset.marginConfiguration.type === MARGIN_TYPE.UNIFORM"
            >
              <div class="label">
                HOLD %
              </div>
              <div class="risk-input">
                <div
                  class="increase-decrease-button left"
                  @click="updateHold('-')"
                >
                  <Icon :name="'minus'" />
                </div>
                <NumberInput
                  :model-value="marginHold"
                  @update:modelValue="updateMarginHold"
                  :max="100"
                />
                <div
                  class="increase-decrease-button right"
                  @click="updateHold('+')"
                >
                  <Icon :name="'plus'" />
                </div>
              </div>
            </div>
            <div
              class="risk-configuration-group margin"
              v-if="selectedRuleset.marginConfiguration.type === MARGIN_TYPE.STEEPNESS_FLATNESS"
            >
              <div class="label">
                MAX HOLD %
              </div>
              <div class="risk-input">
                <div
                  class="increase-decrease-button left"
                  @click="updateMax('-')"
                >
                  <Icon :name="'minus'" />
                </div>
                <NumberInput
                  :model-value="marginMax"
                  @update:modelValue="updateMarginMax"
                  :max="100"
                />
                <div
                  class="increase-decrease-button right"
                  @click="updateMax('+')"
                >
                  <Icon :name="'plus'" />
                </div>
              </div>
            </div>
            <div
              class="risk-configuration-group"
              v-if="selectedRuleset.marginConfiguration.type === MARGIN_TYPE.UNIFORM_IP_PM || selectedRuleset.marginConfiguration.type === MARGIN_TYPE.UNIFORM_IP_PM_ALT"
            >
              <div class="label">
                PREGAME HOLD %
              </div>
              <div class="risk-input">
                <div
                  class="increase-decrease-button left"
                  @click="updatePrematchHold('-')"
                >
                  <Icon :name="'minus'" />
                </div>
                <NumberInput
                  :model-value="marginPrematchHold"
                  @update:modelValue="updatePrematchMarginHold"
                  :max="100"
                />
                <div
                  class="increase-decrease-button right"
                  @click="updatePrematchHold('+')"
                >
                  <Icon :name="'plus'" />
                </div>
              </div>
            </div>
            <div
              class="risk-configuration-group"
              v-if="selectedRuleset.marginConfiguration.type === MARGIN_TYPE.UNIFORM_IP_PM || selectedRuleset.marginConfiguration.type === MARGIN_TYPE.UNIFORM_IP_PM_ALT"
            >
              <div class="label">
                IN-PLAY HOLD %
              </div>
              <div class="risk-input">
                <div
                  class="increase-decrease-button left"
                  @click="updateInPlayHold('-')"
                >
                  <Icon :name="'minus'" />
                </div>
                <NumberInput
                  :model-value="marginInPlayHold"
                  @update:modelValue="updateInPlayMarginHold"
                  :max="100"
                />
                <div
                  class="increase-decrease-button right"
                  @click="updateInPlayHold('+')"
                >
                  <Icon :name="'plus'" />
                </div>
              </div>
            </div>
            <div
              class="risk-configuration-group margin"
              v-if="selectedRuleset.marginConfiguration.type === MARGIN_TYPE.STEEPNESS_FLATNESS_IP_PM"
            >
              <div class="label">
                PREGAME MAX HOLD %
              </div>
              <div class="risk-input">
                <div
                  class="increase-decrease-button left"
                  @click="updatePrematchMax('-')"
                >
                  <Icon :name="'minus'" />
                </div>
                <NumberInput
                  :model-value="marginPrematchMax"
                  @update:modelValue="updatePrematchMarginMax"
                  :max="100"
                />
                <div
                  class="increase-decrease-button right"
                  @click="updatePrematchMax('+')"
                >
                  <Icon :name="'plus'" />
                </div>
              </div>
            </div>
            <div
              class="risk-configuration-group margin"
              v-if="selectedRuleset.marginConfiguration.type === MARGIN_TYPE.STEEPNESS_FLATNESS_IP_PM"
            >
              <div class="label">
                IN-PLAY MAX HOLD %
              </div>
              <div class="risk-input">
                <div
                  class="increase-decrease-button left"
                  @click="updateInPlayMax('-')"
                >
                  <Icon :name="'minus'" />
                </div>
                <NumberInput
                  :model-value="marginInPlayMax"
                  @update:modelValue="updateInPlayMarginMax"
                  :max="100"
                />
                <div
                  class="increase-decrease-button right"
                  @click="updateInPlayMax('+')"
                >
                  <Icon :name="'plus'" />
                </div>
              </div>
            </div>
            <div
              class="risk-configuration-group margin"
              v-if="selectedRuleset.marginConfiguration.type === MARGIN_TYPE.US_CENT_LOOKUP_IP_PM"
            >
              <div class="label">
                PREGAME
              </div>
              <div class="risk-input">
                <Dropdown
                  :label="`${prematchCent} cent`"
                  class="us-cent-dd"
                >
                  <DropdownItem
                    clickable
                    @click="updatePrematchCent(20)"
                  >
                    20 cent
                  </DropdownItem>
                  <DropdownItem
                    clickable
                    @click="updatePrematchCent(30)"
                  >
                    30 cent
                  </DropdownItem>
                </Dropdown>
              </div>
            </div>
            <div
              class="risk-configuration-group margin"
              v-if="selectedRuleset.marginConfiguration.type === MARGIN_TYPE.US_CENT_LOOKUP_IP_PM"
            >
              <div class="label">
                IN-PLAY
              </div>
              <div class="risk-input">
                <Dropdown
                  :label="`${inPlayCent} cent`"
                  class="us-cent-dd"
                >
                  <DropdownItem
                    clickable
                    @click="updateInPlayCent(20)"
                  >
                    20 cent
                  </DropdownItem>
                  <DropdownItem
                    clickable
                    @click="updateInPlayCent(30)"
                  >
                    30 cent
                  </DropdownItem>
                </Dropdown>
              </div>
            </div>
          </div>
          <!-- Hold (Margin) step -->
          <div
            class="risk-configuration-section margin step"
            v-if="selectedRuleset.marginConfiguration && selectedRuleset.marginConfiguration.type === MARGIN_TYPE.UNIFORM_IP_PM_ALT"
          >
            <div
              class="risk-configuration-group margin step"
            >
              <div class="label">
                STEP %
                <Tooltip :text="'Determine the hold percentage increase for alt line based on its distance from the main line.'">
                  <Icon :name="'info'" />
                </Tooltip>
              </div>
              <div class="risk-input">
                <div
                  class="increase-decrease-button left"
                  @click="updateAltStepClick('-')"
                >
                  <Icon :name="'minus'" />
                </div>
                <NumberInput
                  :model-value="altStep"
                  @update:modelValue="updateAltStep"
                  :max="100"
                />
                <div
                  class="increase-decrease-button right"
                  @click="updateAltStepClick('+')"
                >
                  <Icon :name="'plus'" />
                </div>
              </div>
            </div>
            <div class="risk-configuration-group">
              <div :class="['label', {'error': maxAltHoldError}]">
                MAX ALT HOLD %
                <Tooltip :text="'Max alt hold % value must be greater then the pregame hold % and the in-play hold %.'">
                  <Icon :name="'info'" />
                </Tooltip>
              </div>
              <div class="risk-input">
                <div
                  class="increase-decrease-button left"
                  @click="updateAltHoldClick('-')"
                >
                  <Icon :name="'minus'" />
                </div>
                <NumberInput
                  :model-value="altHold"
                  @update:modelValue="updateAltHold"
                  :max="100"
                />
                <div
                  class="increase-decrease-button right"
                  @click="updateAltHoldClick('+')"
                >
                  <Icon :name="'plus'" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <LineConfigurationTab
          v-else-if="currentDefaultTemplateConfiguration && selectedConfiguration === 'line'"
          class="market-configurations-content"
          :default-template-configuration="currentDefaultTemplateConfiguration"
          :template-configuration="currentTemplateConfiguration"
          @update:template-configuration="updateCurrentTemplateConfiguration"
        />
        <CashoutConfigurationTab
          v-else-if="selectedConfiguration === 'cashout'"
          :cashout-configuration="cashoutConfiguration"
          @update:cashout-configuration="setCashoutConfiguration"
        />
        <ResultingConfigurationTab
          v-else-if="selectedConfiguration === 'resulting'"
          :resulting-configuration="resultingConfiguration"
          @update:resulting-configuration="setResultingConfiguration"
        />
        <SelectionsConfigurationTab
          v-else-if="selectedConfiguration === 'selections'"
          :market-selections="selectedRuleset.template.selectionTemplates"
          :disabled-selections="selectedRuleset.disabledSelections || []"
          :sport-label="selectedRuleset.template.sport"
          @update:disabled-selections="updateDisabledSelections"
        />
        <div
          class="market-configurations-content"
          v-else
        >
          <div class="bounds-wrapper">
            <div class="bound-headers">
              <div class="bound-header">
                {{ syncToggled ? 'Start offering & displaying market' : (selectedConfiguration === 'display' ? 'Start displaying market' : 'Start offering market') }}
              </div>
              <div class="bound-header">
                {{ syncToggled ? 'Stop offering & displaying market' : (selectedConfiguration === 'display' ? 'Stop displaying market' : 'Stop offering market') }}
              </div>
            </div>
            <div class="bound-content">
              <div
                class="bound"
                v-if="startBoundPeriod"
              >
                <input
                  type="text"
                  class="market-configuration-input"
                  v-model="startBoundValue.minutes"
                  @input="updateStartStopBounds('start')"
                >
                min
                <input
                  type="text"
                  class="market-configuration-input"
                  v-model="startBoundValue.seconds"
                  @input="updateStartStopBounds('start')"
                >
                sec into the {{ startBoundPeriod }}
              </div>
              <div
                class="bound fixed-bound"
                v-else
              >
                In Pregame
              </div>
              <div
                class="bound"
                v-if="stopBoundPeriod"
              >
                <input
                  type="text"
                  class="market-configuration-input"
                  v-model="stopBoundValue.minutes"
                  @input="updateStartStopBounds('stop')"
                >
                min
                <input
                  type="text"
                  class="market-configuration-input"
                  v-model="stopBoundValue.seconds"
                  @input="updateStartStopBounds('stop')"
                >
                sec left in the {{ stopBoundPeriod }}
              </div>
              <div
                class="bound fixed-bound"
                v-else
              >
                When incident happens
              </div>
            </div>
            <div class="offer-checkbox-root">
              <div class="offer-checkbox-item">
                <Checkbox
                  :model-value="selectedRuleset.offeringConfiguration.offerInPlay"
                  @update:modelValue="toggleInPlayOffer"
                />
                <span @click="toggleInPlayOffer">Offer Live</span>
              </div>
              <div class="offer-checkbox-item">
                <Checkbox
                  :model-value="selectedRuleset.offeringConfiguration.offerPreMatch"
                  @update:modelValue="togglePreMatchOffer"
                />
                <span @click="togglePreMatchOffer">Offer Prematch</span>
              </div>
            </div>
          </div>
          <div
            class="lines-configuration"
            v-if="selectedRuleset.templateConfiguration.type === 'WITH_LINE'"
          >
            <div class="lines-configuration-header">
              <div class="lines-configuration-header-tooltip">
                <div class="lines-configuration-header-label">
                  Lines configuration
                </div>
                <Tooltip :text="'Configure available lines for the market.'">
                  <Icon :name="'info'" />
                </Tooltip>
              </div>
              <div
                class="lines-configuration-information"
                @click="() => { linesInfoOpen = !linesInfoOpen }"
              >
                Show lines information
                <Icon :name="linesInfoOpen ? 'chevron-up' : 'chevron-down'" />
              </div>
            </div>
            <div
              class="lines-configuration-information-details"
              v-if="linesInfoOpen"
            >
              Minimum line
              <div class="info-details-value">
                {{ selectedRuleset.templateConfiguration.minLine }}
              </div>
              Maximum line
              <div class="info-details-value">
                {{ selectedRuleset.templateConfiguration.maxLine }}
              </div>
              Line type
              <div class="info-details-value">
                {{ selectedRuleset.templateConfiguration.maxLine % 2 === 0 ? 'Wholes' : 'Halves' }}
              </div>
              Line increments
              <div class="info-details-value">
                {{ selectedRuleset.templateConfiguration.lineIncrement }}
              </div>
            </div>
            <div
              class="skip-lines-row"
              v-if="skipLinesExists"
            >
              Skip
              <input
                type="number"
                min="0"
                class="market-configuration-input"
                v-if="selectedConfiguration === 'offering'"
                v-model="selectedRuleset.offeringConfiguration.currentTotalThreshold"
                @input="(e) => { updadeSkipLines(e.target.value) }"
              >
              <input
                type="number"
                min="0"
                class="market-configuration-input"
                v-else
                v-model="selectedRuleset.displayConfiguration.currentTotalThreshold"
              >
              lines over the total live score
              <Tooltip :text="'Define how many lines should be skipped for the in-play offer.'">
                <Icon :name="'info'" />
              </Tooltip>
            </div>
            <div :class="['lines-configuration-bounds-wrapper', { 'no-skip': !skipLinesExists }]">
              <div
                class="lines-configuration-bound"
                v-if="hasPreGame"
              >
                <span v-if="syncToggled">Offer & display</span>
                <span v-else>{{ selectedConfiguration === 'offering' ? 'Offer' : 'Display' }}</span>
                <input
                  type="text"
                  class="market-configuration-input"
                  v-model="selectedRuleset[selectedConfiguration + 'Configuration'].offeringBounds.PRE_GAME.overMainLine"
                  @blur="(e) => { updatePreGame(e.target.value, 'overMainLine', 'PRE_GAME') }"
                  v-if="selectedRuleset[selectedConfiguration + 'Configuration'].offeringBounds.PRE_GAME"
                >
                <input
                  type="text"
                  class="market-configuration-input"
                  v-model="selectedRuleset[selectedConfiguration + 'Configuration'].offeringBounds.PREMATCH.overMainLine"
                  @blur="(e) => { updatePreGame(e.target.value, 'overMainLine', 'PREMATCH') }"
                  v-else
                >
                lines over and
                <input
                  type="text"
                  class="market-configuration-input"
                  v-model="selectedRuleset[selectedConfiguration + 'Configuration'].offeringBounds.PRE_GAME.underMainLine"
                  @blur="(e) => { updatePreGame(e.target.value, 'underMainLine', 'PRE_GAME') }"
                  v-if="selectedRuleset[selectedConfiguration + 'Configuration'].offeringBounds.PRE_GAME"
                >
                <input
                  type="text"
                  class="market-configuration-input"
                  v-model="selectedRuleset[selectedConfiguration + 'Configuration'].offeringBounds.PREMATCH.underMainLine"
                  @blur="(e) => { updatePreGame(e.target.value, 'underMainLine', 'PREMATCH') }"
                  v-else
                >
                lines under the mainline for <strong>prematch</strong>.
              </div>
              <div
                class="lines-configuration-bound"
                v-if="hasEventLine"
              >
                <span v-if="syncToggled">Offer & display</span>
                <span v-else>{{ selectedConfiguration === 'offering' ? 'Offer' : 'Display' }}</span>
                <input
                  type="text"
                  class="market-configuration-input"
                  v-model="eventOverLine"
                >
                lines over and
                <input
                  type="text"
                  class="market-configuration-input"
                  v-model="eventUnderLine"
                >
                lines under the mainline for <strong>event</strong>.
              </div>
              <div
                class="lines-configuration-bound"
                v-for="(period, index) in uniquePeriods"
                :key="index"
              >
                <span v-if="syncToggled">Offer & display</span>
                <span v-else>{{ selectedConfiguration === 'offering' ? 'Offer' : 'Display' }}</span>
                <input
                  type="text"
                  class="market-configuration-input"
                  :value="period.details.overMainLine"
                  @blur="(e) => updateUniquePeriods(e.target.value, period, 'overMainLine')"
                >
                lines over and
                <input
                  type="text"
                  class="market-configuration-input"
                  :value="period.details.underMainLine"
                  @blur="(e) => updateUniquePeriods(e.target.value, period, 'underMainLine')"
                >
                lines under the mainline for
                <div
                  class="period-dropdown-wrapper"
                  ref="periodDropdownRef"
                >
                  <div
                    class="selected-period"
                    @click="() => { period.dropdownOpened = !period.dropdownOpened }"
                  >
                    {{ period.label }}
                    <Icon
                      :name="'chevron-down'"
                      v-if="!period.dropdownOpened"
                    />
                    <Icon
                      :name="'chevron-up'"
                      v-else
                    />
                  </div>
                  <div
                    class="period-dropdown"
                    v-if="period.dropdownOpened"
                  >
                    <div
                      class="dropdown-option"
                      v-for="(option, pIndex) in availablePeriods"
                      :key="pIndex"
                      @click="selectPeriod(period, option)"
                    >
                      {{ option.label }}
                    </div>
                  </div>
                </div>
                <Icon
                  :name="'x'"
                  @click="removeUniquePeriod(period)"
                />
              </div>
              <!-- baseball lines only for prematch and live and have no additional periods -->
              <div
                class="lines-configuration-bound"
                v-if="selectedRuleset[selectedConfiguration + 'Configuration'].preMatchBound"
              >
                <span v-if="syncToggled">Offer & display</span>
                <span v-else>{{ selectedConfiguration === 'offering' ? 'Offer' : 'Display' }}</span>
                <input
                  type="text"
                  class="market-configuration-input"
                  v-model="selectedRuleset[selectedConfiguration + 'Configuration'].preMatchBound.overMainLine"
                  @blur="(e) => { updateBaseballBounds(e.target.value, 'overMainLine', 'preMatchBound') }"
                >
                lines over and
                <input
                  type="text"
                  class="market-configuration-input"
                  v-model="selectedRuleset[selectedConfiguration + 'Configuration'].preMatchBound.underMainLine"
                  @blur="(e) => { updateBaseballBounds(e.target.value, 'underMainLine', 'preMatchBound') }"
                >
                lines under the mainline for <strong>prematch</strong>.
              </div>
              <div
                class="lines-configuration-bound"
                v-if="selectedRuleset[selectedConfiguration + 'Configuration'].inPlayBound"
              >
                <span v-if="syncToggled">Offer & display</span>
                <span v-else>{{ selectedConfiguration === 'offering' ? 'Offer' : 'Display' }}</span>
                <input
                  type="text"
                  class="market-configuration-input"
                  v-model="selectedRuleset[selectedConfiguration + 'Configuration'].inPlayBound.overMainLine"
                  @blur="(e) => { updateBaseballBounds(e.target.value, 'overMainLine', 'inPlayBound') }"
                >
                lines over and
                <input
                  type="text"
                  class="market-configuration-input"
                  v-model="selectedRuleset[selectedConfiguration + 'Configuration'].inPlayBound.underMainLine"
                  @blur="(e) => { updateBaseballBounds(e.target.value, 'underMainLine', 'inPlayBound') }"
                >
                lines under the mainline <strong>in play</strong>.
              </div>
              <div
                class="define-lines-button"
                v-if="selectedRuleset[selectedConfiguration + 'Configuration'].offeringBounds"
                @click="addUniquePeriod"
              >
                Define more lines
                <Icon
                  :name="'plus'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="market-configurations-footer">
      <div
        class="market-configurations-button"
        @click="closePopup"
      >
        Dismiss
      </div>
      <div
        :class="['market-configurations-button save', { 'disabled': isSaveConfigurationDisabled || savingConfiguration}]"
        @click="saveConfiguration"
      >
        Save
      </div>
    </div>
    <div
      class="close-popup-wrapper"
      v-if="closePopupOpen"
      ref="closePopupRef"
    >
      <div class="unsaved-changes-header">
        Unsaved changes
      </div>
      <div class="unsaved-changes-message">
        You have unsaved changes, do you want to continue?
      </div>
      <div class="unsaved-changes-buttons">
        <div
          class="unsaved-changes-button"
          @click="() => { closePopupOpen = false; }"
        >
          Back
        </div>
        <div
          class="unsaved-changes-button yes"
          @click="closeSecondPopup"
        >
          Yes
        </div>
      </div>
    </div>
    <div
      class="close-current-popup-bg-overlay"
      v-if="closePopupOpen"
    />
  </div>
  <div
    class="close-popup-bg-overlay"
    v-if="closePopupOpen"
  />
</template>

<script>
import {
  ref, computed, watch, onMounted, onBeforeUnmount,
} from 'vue';
import { useStore } from 'vuex';
import { onClickOutside } from '@vueuse/core';
import {
  cloneDeep, findKey, isNil, filter, findIndex,
  map, each, find, orderBy, assign, includes, sortBy,
  some,
} from 'lodash';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { periodLabels, periodPositions } from '@/services/helpers/periods-mapper';
import { createTierColumns, calculateAfterOperationValue } from '@/services/helpers/market-tiers';
import { composeLineConfiguration, isLineConfigurationInvalid } from '@/services/helpers/line-configuration';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';
import Toggle from '@/components/common/Toggle';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';
import NumberInput from '@/components/common/NumberInput';
import Checkbox from '@/components/common/Checkbox';
import CashoutConfigurationTab from './CashoutConfigurationTab';
import LineConfigurationTab from './LineConfigurationTab';
import ResultingConfigurationTab from './ResultingConfigurationTab';
import SelectionsConfigurationTab from './SelectionsConfigurationTab';

const MARGIN_TYPE = {
  UNIFORM: 'UNIFORM',
  STEEPNESS_FLATNESS: 'STEEPNESS_FLATNESS',
  UNIFORM_IP_PM: 'UNIFORM_IN_PLAY_AND_PRE_MATCH',
  UNIFORM_IP_PM_ALT: 'UNIFORM_IN_PLAY_AND_PRE_MATCH_ALT',
  STEEPNESS_FLATNESS_IP_PM: 'STEEPNESS_FLATNESS_IN_PLAY_AND_PRE_MATCH',
  US_CENT_LOOKUP_IP_PM: 'US_CENT_LOOKUP_IN_PLAY_AND_PRE_MATCH',
};

export default {
  components: {
    Tooltip,
    Icon,
    Toggle,
    Dropdown,
    DropdownItem,
    NumberInput,
    CashoutConfigurationTab,
    LineConfigurationTab,
    Checkbox,
    ResultingConfigurationTab,
    SelectionsConfigurationTab,
  },
  props: {
    configurationData: {
      required: true,
      type: Object,
      default: () => ({}),
    },
  },
  emits: [
    'closePopup',
  ],
  setup(props, { emit }) {
    const store = useStore();

    const configuration = ref({
      ...cloneDeep(props.configurationData),
      configurationRulesets: sortBy(cloneDeep(props.configurationData?.configurationRulesets), 'sportLogicTemplate.name'),
    });
    const startBoundValue = ref({
      minutes: '',
      seconds: '',
    });
    const stopBoundValue = ref({
      minutes: '',
      seconds: '',
    });
    const startBoundPeriod = ref('');
    const stopBoundPeriod = ref('');

    const hasPreGame = ref(false);
    const hasEventLine = ref(false);
    const eventUnderLine = ref('');
    const eventOverLine = ref('');
    const filteredPeriods = ref([]); // all periods except PRE_GAME
    const uniquePeriods = ref([]); // displayed periods
    const availablePeriods = ref([]); // dropdown list periods
    const syncToggled = ref(false);
    const linesInfoOpen = ref(false);
    const rulesetTabRef = ref(false);
    const unsavedChanges = ref(false);
    const closePopupOpen = ref(false);
    const closePopupRef = ref(false);
    const savingConfiguration = ref(false);

    const selectedRuleset = ref(configuration.value.configurationRulesets?.[0]);
    const selectedConfiguration = ref('risk');

    const setBounds = () => {
      if (selectedConfiguration.value === 'line' || selectedConfiguration.value === 'selections') return;
      const boundsArray = selectedRuleset.value[`${selectedConfiguration.value}Configuration`].offeringBounds;
      const startPeriod = findKey(boundsArray, (bound) => !isNil(bound.fromSecondInPeriod));
      const stopPeriod = findKey(boundsArray, (bound) => !isNil(bound.secondsLeftInPeriod));
      if (startPeriod) {
        startBoundValue.value = {
          minutes: parseInt(boundsArray[startPeriod].fromSecondInPeriod / 60, 10),
          seconds: boundsArray[startPeriod].fromSecondInPeriod % 60,
        };
        startBoundPeriod.value = periodLabels[startPeriod] || startPeriod;
      }
      if (stopPeriod) {
        stopBoundValue.value = {
          minutes: parseInt(boundsArray[stopPeriod].secondsLeftInPeriod / 60, 10),
          seconds: boundsArray[stopPeriod].secondsLeftInPeriod % 60,
        };
        stopBoundPeriod.value = periodLabels[stopPeriod] || stopPeriod;
      }
    };

    const updateStartStopBounds = (when) => {
      if (when === 'start' && (isNil(startBoundValue.value.minutes) || isNil(startBoundValue.value.seconds))) return;
      if (when === 'stop' && (isNil(stopBoundValue.value.minutes) || isNil(stopBoundValue.value.seconds))) return;
      const bounds = selectedRuleset.value[`${selectedConfiguration.value}Configuration`].offeringBounds;
      const startPeriodKey = findKey(bounds, (bound) => !isNil(bound.fromSecondInPeriod));
      const stopPeriodKey = findKey(bounds, (bound) => !isNil(bound.secondsLeftInPeriod));
      if (when === 'start') {
        bounds[startPeriodKey].fromSecondInPeriod = (Number(startBoundValue.value.minutes) * 60) + Number(startBoundValue.value.seconds);
      }
      if (when === 'stop') {
        bounds[stopPeriodKey].secondsLeftInPeriod = (Number(stopBoundValue.value.minutes) * 60) + Number(stopBoundValue.value.seconds);
      }
      if (syncToggled.value) {
        selectedRuleset.value.displayConfiguration.offeringBounds = cloneDeep(bounds);
      }
      unsavedChanges.value = true;
    };

    const setPeriodLines = () => {
      if (selectedConfiguration.value === 'line' || selectedRuleset.value.templateConfiguration.type !== 'WITH_LINE' || selectedConfiguration.value === 'selections') return;
      const bounds = selectedRuleset.value[`${selectedConfiguration.value}Configuration`].offeringBounds;
      hasPreGame.value = bounds?.PRE_GAME || bounds?.PREMATCH;
      filteredPeriods.value = filter(
        map(bounds, (bound, key) => ({
          name: key,
          details: bound,
          label: periodLabels[key] || key,
          position: periodPositions[key] || 999,
        })),
        (period) => period.name !== 'PRE_GAME' && period.name !== 'PREMATCH',
      );
      const linesObj = {};
      each(filteredPeriods.value, (period) => {
        if (!linesObj[`${period.details.underMainLine} ${period.details.overMainLine}`]) {
          linesObj[`${period.details.underMainLine} ${period.details.overMainLine}`] = 0;
        }
        linesObj[`${period.details.underMainLine} ${period.details.overMainLine}`] += 1;
      });
      hasEventLine.value = false;
      each(linesObj, (line, key) => {
        if (line > 1) {
          const splitValues = key.split(' ');
          [eventUnderLine.value, eventOverLine.value] = splitValues;
          hasEventLine.value = true;
        }
      });
      uniquePeriods.value = hasEventLine.value
        ? orderBy(filter(
          filteredPeriods.value, (period) => period.details.underMainLine !== Number(eventUnderLine.value)
            || period.details.overMainLine !== Number(eventOverLine.value), 'position',
        ))
        : [];
      availablePeriods.value = orderBy(filter(
        filteredPeriods.value, (period) => !find(uniquePeriods.value, { name: period.name }),
      ), 'position');
    };

    /* setPeriodLines(); */

    const addUniquePeriod = () => {
      if (!availablePeriods.value.length) return;
      uniquePeriods.value.push(availablePeriods.value[0]);
      uniquePeriods.value = orderBy(uniquePeriods.value, 'position');
      availablePeriods.value.splice(0, 1);
      unsavedChanges.value = true;
    };

    const removeUniquePeriod = (period) => {
      uniquePeriods.value = orderBy(filter(uniquePeriods.value, (uniquePeriod) => uniquePeriod.name !== period.name), 'position');
      availablePeriods.value.push(period);
      availablePeriods.value = orderBy(availablePeriods.value, 'position');
      assign(period, { dropdownOpened: false });
      unsavedChanges.value = true;
    };

    const selectPeriod = (oldPeriod, newPeriod) => {
      uniquePeriods.value = filter(uniquePeriods.value, (uniquePeriod) => uniquePeriod.name !== oldPeriod.name);
      uniquePeriods.value.push(newPeriod);
      uniquePeriods.value = orderBy(uniquePeriods.value, 'position');
      availablePeriods.value = filter(availablePeriods.value, (uniquePeriod) => uniquePeriod.name !== newPeriod.name);
      availablePeriods.value.push(oldPeriod);
      availablePeriods.value = orderBy(availablePeriods.value, 'position');
      assign(oldPeriod, { dropdownOpened: false });
      assign(newPeriod, { dropdownOpened: false });
      unsavedChanges.value = true;
    };

    const displayRulesetSettingsIcon = (rulesetIndex, value) => {
      configuration.value.configurationRulesets[rulesetIndex].displaySettingsIcon = !!value;
    };

    const rulesetDropdownToggle = (rulesetIndex) => {
      each(configuration.value.configurationRulesets, (ruleset) => {
        assign(ruleset, { dropdownOpened: false });
      });
      const toggled = !!configuration.value.configurationRulesets[rulesetIndex].dropdownOpened;
      configuration.value.configurationRulesets[rulesetIndex].dropdownOpened = !toggled;
    };

    const disableRuleset = (rulesetIndex) => {
      configuration.value.configurationRulesets[rulesetIndex].enabled = !configuration.value.configurationRulesets[rulesetIndex].enabled;
      configuration.value.configurationRulesets[rulesetIndex].dropdownOpened = false;
      displayRulesetSettingsIcon(rulesetIndex, false);
      unsavedChanges.value = true;
    };

    const restoreDefaultRuleset = (rulesetIndex) => {
      const { defaultDisplayConfiguration } = configuration.value.configurationRulesets[rulesetIndex].sportLogicTemplate;
      const { defaultOfferingConfiguration } = configuration.value.configurationRulesets[rulesetIndex].sportLogicTemplate;
      const { defaultLimitConfiguration } = configuration.value.configurationRulesets[rulesetIndex].sportLogicTemplate;
      const { defaultMarginConfiguration } = configuration.value.configurationRulesets[rulesetIndex].sportLogicTemplate;
      const { defaultCashoutConfiguration } = configuration.value.configurationRulesets[rulesetIndex].sportLogicTemplate;
      configuration.value.configurationRulesets[rulesetIndex].offeringConfiguration = defaultOfferingConfiguration;
      configuration.value.configurationRulesets[rulesetIndex].displayConfiguration = defaultDisplayConfiguration;
      configuration.value.configurationRulesets[rulesetIndex].limitConfiguration = defaultLimitConfiguration;
      configuration.value.configurationRulesets[rulesetIndex].marginConfiguration = defaultMarginConfiguration;
      configuration.value.configurationRulesets[rulesetIndex].cashoutConfiguration = defaultCashoutConfiguration;
      configuration.value.configurationRulesets[rulesetIndex].dropdownOpened = false;
      displayRulesetSettingsIcon(rulesetIndex, false);
      unsavedChanges.value = true;
    };

    const enableRuleset = () => {
      const rulesetIndex = findIndex(configuration.value.configurationRulesets, { sportsLogicTemplateId: selectedRuleset.value.sportsLogicTemplateId });
      if (rulesetIndex > -1) configuration.value.configurationRulesets[rulesetIndex].enabled = true;
      unsavedChanges.value = true;
    };

    const skipLinesExists = computed(
      () => !isNil(selectedRuleset.value[`${selectedConfiguration.value}Configuration`].currentTotalThreshold),
    );

    const updadeSkipLines = (targetValue) => {
      unsavedChanges.value = true;
      if (!syncToggled.value) return;
      const exceptionChars = ['', '.', ','];
      if (includes(exceptionChars, targetValue.charAt(targetValue.length - 1))) return;
      selectedRuleset.value.displayConfiguration.currentTotalThreshold = Number(targetValue);
    };

    const updatePreGame = (targetValue, line, prematch) => {
      unsavedChanges.value = true;
      if (!syncToggled.value) return;
      const exceptionChars = ['', '.', ','];
      if (includes(exceptionChars, targetValue.charAt(targetValue.length - 1))) return;
      selectedRuleset.value.displayConfiguration.offeringBounds[prematch][line] = Number(targetValue);
    };

    const updateBaseballBounds = (targetValue, line, bound) => {
      unsavedChanges.value = true;
      const exceptionChars = ['', '.', ','];
      if (includes(exceptionChars, targetValue.charAt(targetValue.length - 1))) return;
      selectedRuleset.value[`${selectedConfiguration.value}Configuration`][bound][line] = Number(targetValue);
      if (syncToggled.value) {
        selectedRuleset.value.displayConfiguration[bound][line] = Number(targetValue);
      }
      unsavedChanges.value = true;
    };

    const advancedSettingsVisible = ref(false);
    const templateUpdateInfo = computed(() => {
      if (props.configurationData.updatedAt) {
        return `Last updated on ${format(zonedTimeToUtc(configuration.value.updatedAt, 'UTC'), 'd/MMM/yyyy.')}`;
      }
      return `Created on ${format(zonedTimeToUtc(configuration.value.createdAt, 'UTC'), 'd/MM/yyyy.')}`;
    });

    const toggleAdvancedSettings = () => {
      advancedSettingsVisible.value = !advancedSettingsVisible.value;
    };

    const closePopup = () => {
      if (unsavedChanges.value) {
        closePopupOpen.value = true;
        return;
      }
      emit('closePopup');
    };

    const closeSecondPopup = () => {
      unsavedChanges.value = false;
      closePopup();
    };

    const configurationsPopupRef = ref(null);
    onClickOutside(configurationsPopupRef, () => {
      if (closePopupOpen.value) return;
      emit('closePopup');
    });

    onClickOutside(closePopupRef, () => {
      closePopupOpen.value = false;
    });

    onClickOutside(rulesetTabRef, () => {
      each(configuration.value.configurationRulesets, (ruleset) => {
        assign(ruleset, { dropdownOpened: false });
      });
    });

    /* setBounds(); */

    const updatePeriods = () => {
      uniquePeriods.value = hasEventLine.value
        ? orderBy(filter(
          filteredPeriods.value, (period) => (period.details.underMainLine !== Number(eventUnderLine.value))
            || (period.details.overMainLine !== Number(eventOverLine.value)), 'position',
        ))
        : [];
      availablePeriods.value = orderBy(filter(
        filteredPeriods.value, (period) => !find(uniquePeriods.value, { name: period.name }),
      ), 'position');
    };

    const updateUniquePeriods = (targetValue, period, line) => {
      const exceptionChars = ['', '.', ','];
      if (includes(exceptionChars, targetValue.charAt(targetValue.length - 1))) return;
      selectedRuleset.value[`${selectedConfiguration.value}Configuration`].offeringBounds[period.name][line] = Number(targetValue);
      if (syncToggled.value) {
        selectedRuleset.value.displayConfiguration.offeringBounds[period.name][line] = Number(targetValue);
      }
      const foundPeriod = find(filteredPeriods.value, { name: period.name });
      foundPeriod.details[line] = Number(targetValue);
      updatePeriods();
      unsavedChanges.value = true;
    };

    // risk configuration (limit and margin)
    const limitTiers = createTierColumns();

    const setDefaultLimitAndMargin = () => {
      if (!selectedRuleset.value.limitConfiguration) {
        selectedRuleset.value.limitConfiguration = selectedRuleset.value.sportLogicTemplate?.defaultLimitConfiguration;
      }
      if (!selectedRuleset.value.marginConfiguration) {
        selectedRuleset.value.marginConfiguration = selectedRuleset.value.sportLogicTemplate?.defaultMarginConfiguration;
      }
    };

    setDefaultLimitAndMargin();

    const applyLimitTier = (tier, type) => {
      if (!type) {
        selectedRuleset.value.limitConfiguration.tier = tier;
        return;
      }
      if (type === 'main') {
        selectedRuleset.value.limitConfiguration.mainTier = tier;
        return;
      }
      selectedRuleset.value.limitConfiguration.altTier = tier;
    };

    const marginHold = ref(Number(parseFloat(((selectedRuleset.value.marginConfiguration?.totalBook || 1) - 1) * 100).toFixed(2)));
    const marginMax = ref(Number(parseFloat((selectedRuleset.value.marginConfiguration?.max || 0.04) * 100).toFixed(2)));

    const altStep = ref(Number(parseFloat((selectedRuleset.value.marginConfiguration?.altStep || 0) * 100).toFixed(2)));
    const altHold = ref(Number(parseFloat(((selectedRuleset.value.marginConfiguration?.maxAltTotalBook || 1) - 1) * 100).toFixed(2)));

    const marginPrematchHold = ref(Number(parseFloat(((selectedRuleset.value.marginConfiguration?.preMatchTotalBook || 1) - 1) * 100).toFixed(2)));
    const marginInPlayHold = ref(Number(parseFloat(((selectedRuleset.value.marginConfiguration?.inPlayTotalBook || 1) - 1) * 100).toFixed(2)));

    const marginPrematchMax = ref(Number(parseFloat((selectedRuleset.value.marginConfiguration?.preMatchMax || 0.04) * 100).toFixed(2)));
    const marginInPlayMax = ref(Number(parseFloat((selectedRuleset.value.marginConfiguration?.inPlayMax || 0.04) * 100).toFixed(2)));

    const prematchCent = ref(selectedRuleset.value.marginConfiguration?.preMatchCent || 20);
    const inPlayCent = ref(selectedRuleset.value.marginConfiguration?.inPlayCent || 20);

    const maxAltHoldError = computed(() => selectedRuleset.value?.marginConfiguration?.type === MARGIN_TYPE.UNIFORM_IP_PM_ALT
      && (altHold.value <= marginInPlayHold.value || altHold.value <= marginPrematchHold.value));

    const setMarginValues = () => {
      marginHold.value = Number(parseFloat(((selectedRuleset.value.marginConfiguration?.totalBook || 1) - 1) * 100).toFixed(2));
      marginMax.value = Number(parseFloat((selectedRuleset.value.marginConfiguration?.max || 0.04) * 100).toFixed(2));

      altStep.value = ref(Number(parseFloat((selectedRuleset.value.marginConfiguration?.altStep || 0) * 100).toFixed(2)));
      altHold.value = Number(parseFloat(((selectedRuleset.value.marginConfiguration?.maxAltTotalBook || 1) - 1) * 100).toFixed(2));

      marginPrematchHold.value = Number(parseFloat(((selectedRuleset.value.marginConfiguration?.preMatchTotalBook || 1) - 1) * 100).toFixed(2));
      marginInPlayHold.value = Number(parseFloat(((selectedRuleset.value.marginConfiguration?.inPlayTotalBook || 1) - 1) * 100).toFixed(2));

      marginPrematchMax.value = Number(parseFloat((selectedRuleset.value.marginConfiguration?.preMatchMax || 0.04) * 100).toFixed(2));
      marginInPlayMax.value = Number(parseFloat((selectedRuleset.value.marginConfiguration?.inPlayMax || 0.04) * 100).toFixed(2));

      prematchCent.value = selectedRuleset.value.marginConfiguration?.preMatchCent || 20;
      inPlayCent.value = selectedRuleset.value.marginConfiguration?.inPlayCent || 20;
    };

    const selectRuleset = (ruleset) => {
      selectedRuleset.value = ruleset;
      if (selectedConfiguration.value !== 'risk') {
        setBounds();
        setPeriodLines();
        return;
      }
      setMarginValues();
    };

    const selectConfiguration = (conf) => {
      selectedConfiguration.value = conf;
      if (conf !== 'risk') {
        setBounds();
        setPeriodLines();
        return;
      }
      setMarginValues();
    };

    const toggleSync = (val) => {
      syncToggled.value = val;
      if (!val) return;
      if (!selectedConfiguration.value !== 'offering') selectConfiguration('offering');
      selectedRuleset.value.displayConfiguration = cloneDeep(selectedRuleset.value.offeringConfiguration);
      unsavedChanges.value = true;
    };

    const updateSelectedRulesetMarginConfiguration = () => {
      if (selectedRuleset.value.marginConfiguration?.type === MARGIN_TYPE.UNIFORM) {
        selectedRuleset.value.marginConfiguration = {
          totalBook: (+marginHold.value / 100) + 1,
          type: MARGIN_TYPE.UNIFORM,
        };
      }
      if (selectedRuleset.value.marginConfiguration?.type === MARGIN_TYPE.UNIFORM_IP_PM) {
        selectedRuleset.value.marginConfiguration = {
          preMatchTotalBook: (+marginPrematchHold.value / 100) + 1,
          inPlayTotalBook: (+marginInPlayHold.value / 100) + 1,
          type: MARGIN_TYPE.UNIFORM_IP_PM,
        };
      }
      if (selectedRuleset.value.marginConfiguration?.type === MARGIN_TYPE.UNIFORM_IP_PM_ALT) {
        selectedRuleset.value.marginConfiguration = {
          preMatchTotalBook: (+marginPrematchHold.value / 100) + 1,
          inPlayTotalBook: (+marginInPlayHold.value / 100) + 1,
          altStep: +altStep.value / 100,
          maxAltTotalBook: (+altHold.value / 100) + 1,
          type: MARGIN_TYPE.UNIFORM_IP_PM_ALT,
        };
      }
      if (selectedRuleset.value.marginConfiguration?.type === MARGIN_TYPE.STEEPNESS_FLATNESS) {
        selectedRuleset.value.marginConfiguration = {
          steepness: 1.75,
          flatness: 4.75,
          min: 0.005,
          max: +marginMax.value / 100,
          type: MARGIN_TYPE.STEEPNESS_FLATNESS,
        };
      }
      if (selectedRuleset.value.marginConfiguration?.type === MARGIN_TYPE.STEEPNESS_FLATNESS_IP_PM) {
        selectedRuleset.value.marginConfiguration = {
          preMatchSteepness: 1.75,
          inPlaySteepness: 1.75,
          preMatchFlatness: 4.75,
          inPlayFlatness: 4.75,
          preMatchMin: 0.005,
          inPlayMin: 0.005,
          preMatchMax: +marginPrematchMax.value / 100,
          inPlayMax: +marginInPlayMax.value / 100,
          type: MARGIN_TYPE.STEEPNESS_FLATNESS_IP_PM,
        };
      }
      if (selectedRuleset.value.marginConfiguration?.type === MARGIN_TYPE.US_CENT_LOOKUP_IP_PM) {
        selectedRuleset.value.marginConfiguration = {
          preMatchCent: prematchCent.value,
          inPlayCent: inPlayCent.value,
          type: MARGIN_TYPE.US_CENT_LOOKUP_IP_PM,
        };
      }
    };

    const updateMarginHold = (inputValue) => {
      marginHold.value = inputValue;
      updateSelectedRulesetMarginConfiguration();
    };
    const updateMarginMax = (inputValue) => {
      marginMax.value = inputValue;
      updateSelectedRulesetMarginConfiguration();
    };
    const updatePrematchMarginHold = (inputValue) => {
      marginPrematchHold.value = inputValue;
      updateSelectedRulesetMarginConfiguration();
    };
    const updateInPlayMarginHold = (inputValue) => {
      marginInPlayHold.value = inputValue;
      updateSelectedRulesetMarginConfiguration();
    };
    const updatePrematchMarginMax = (inputValue) => {
      marginPrematchMax.value = inputValue;
      updateSelectedRulesetMarginConfiguration();
    };
    const updateInPlayMarginMax = (inputValue) => {
      marginInPlayMax.value = inputValue;
      updateSelectedRulesetMarginConfiguration();
    };

    const updatePrematchCent = (inputValue) => {
      prematchCent.value = inputValue;
      updateSelectedRulesetMarginConfiguration();
    };
    const updateInPlayCent = (inputValue) => {
      inPlayCent.value = inputValue;
      updateSelectedRulesetMarginConfiguration();
    };

    const updateHold = (operation) => {
      marginHold.value = calculateAfterOperationValue(operation, marginHold.value);
      updateSelectedRulesetMarginConfiguration();
    };

    const updateAltStepClick = (operation) => {
      altStep.value = calculateAfterOperationValue(operation, altStep.value);
      updateSelectedRulesetMarginConfiguration();
    };
    const updateAltStep = (inputValue) => {
      altStep.value = inputValue;
      updateSelectedRulesetMarginConfiguration();
    };

    const updateAltHoldClick = (operation) => {
      altHold.value = calculateAfterOperationValue(operation, altHold.value);
      updateSelectedRulesetMarginConfiguration();
    };
    const updateAltHold = (inputValue) => {
      altHold.value = inputValue;
      updateSelectedRulesetMarginConfiguration();
    };

    const updateMax = (operation) => {
      marginMax.value = calculateAfterOperationValue(operation, marginMax.value);
      updateSelectedRulesetMarginConfiguration();
    };

    const updatePrematchHold = (operation) => {
      marginPrematchHold.value = calculateAfterOperationValue(operation, marginPrematchHold.value);
      updateSelectedRulesetMarginConfiguration();
    };
    const updateInPlayHold = (operation) => {
      marginInPlayHold.value = calculateAfterOperationValue(operation, marginInPlayHold.value);
      updateSelectedRulesetMarginConfiguration();
    };

    const updatePrematchMax = (operation) => {
      marginPrematchMax.value = calculateAfterOperationValue(operation, marginPrematchMax.value);
      updateSelectedRulesetMarginConfiguration();
    };
    const updateInPlayMax = (operation) => {
      marginInPlayMax.value = calculateAfterOperationValue(operation, marginInPlayMax.value);
      updateSelectedRulesetMarginConfiguration();
    };

    const applyMarginType = (type) => {
      if (selectedRuleset.value.marginConfiguration?.type === type) return;
      selectedRuleset.value.marginConfiguration.type = type;
    };

    const marginTypes = ref({
      [MARGIN_TYPE.UNIFORM]: {
        id: 1,
        label: 'Flat',
        type: MARGIN_TYPE.UNIFORM,
      },
      [MARGIN_TYPE.STEEPNESS_FLATNESS]: {
        id: 2,
        label: 'Hold curve',
        type: MARGIN_TYPE.STEEPNESS_FLATNESS,
      },
      [MARGIN_TYPE.UNIFORM_IP_PM]: {
        id: 3,
        label: 'Flat (pregame/in-play)',
        type: MARGIN_TYPE.UNIFORM_IP_PM,
      },
      [MARGIN_TYPE.UNIFORM_IP_PM_ALT]: {
        id: 4,
        label: 'Flat (pregame/in-play) - Alt',
        type: MARGIN_TYPE.UNIFORM_IP_PM_ALT,
      },
      [MARGIN_TYPE.STEEPNESS_FLATNESS_IP_PM]: {
        id: 5,
        label: 'Hold curve (pregame/in-play)',
        type: MARGIN_TYPE.STEEPNESS_FLATNESS_IP_PM,
      },
      [MARGIN_TYPE.US_CENT_LOOKUP_IP_PM]: {
        id: 6,
        label: 'US Cent Lookup',
        type: MARGIN_TYPE.US_CENT_LOOKUP_IP_PM,
      },

    });

    const currentDefaultTemplateConfiguration = computed(() => {
      if (selectedRuleset.value?.sportLogicTemplate?.defaultTemplateConfiguration?.type !== 'WITH_LINE') return null;
      return selectedRuleset.value.sportLogicTemplate.defaultTemplateConfiguration;
    });
    const currentTemplateConfiguration = computed(() => {
      if (selectedRuleset.value?.templateConfiguration?.type !== 'WITH_LINE') return null;
      return selectedRuleset.value.templateConfiguration;
    });
    const updateCurrentTemplateConfiguration = (newCurrentTemplateConfiguration) => {
      selectedRuleset.value.templateConfiguration = newCurrentTemplateConfiguration;
    };
    const isSaveConfigurationDisabled = computed(() => some(
      configuration.value.configurationRulesets,
      (ruleset) => isLineConfigurationInvalid(composeLineConfiguration(
        ruleset.templateConfiguration,
        ruleset.sportLogicTemplate.defaultTemplateConfiguration,
      )),
    ));

    const saveConfiguration = () => {
      if (isSaveConfigurationDisabled.value || savingConfiguration.value) return;
      savingConfiguration.value = true;
      const marketConfigurations = {};
      updateSelectedRulesetMarginConfiguration();

      each(configuration.value.configurationRulesets, (ruleset) => {
        marketConfigurations[ruleset.sportsLogicTemplateId] = {
          displayConfiguration: ruleset.displayConfiguration,
          enabled: ruleset.enabled,
          marketTemplateId: ruleset.marketTemplateId,
          offeringConfiguration: ruleset.offeringConfiguration,
          resultingConfiguration: ruleset.resultingConfiguration,
          cashoutConfiguration: ruleset.cashoutConfiguration,
          sportLogicTemplate: ruleset.sportLogicTemplate,
          templateConfiguration: composeLineConfiguration(
            ruleset.templateConfiguration,
            ruleset.sportLogicTemplate.defaultTemplateConfiguration,
          ),
          limitConfiguration: ruleset.limitConfiguration,
          marginConfiguration: ruleset.marginConfiguration,
          disabledSelections: ruleset.disabledSelections,
        };

        if (ruleset.sportLogicTemplate?.marketTemplate?.template) {
          marketConfigurations[ruleset.sportsLogicTemplateId].sportLogicTemplate = ruleset.sportLogicTemplate?.marketTemplate?.template;
        }
      });
      const payload = {
        sportTemplateId: configuration.value.sportTemplateId,
        name: configuration.value.sportTemplateName,
        sportId: configuration.value.sportId,
        marketConfigurations,
      };
      store.dispatch('saveMarketConfiguration', payload)
        .then(() => {
          emit('closePopup', 'reload');
          store.dispatch('addNotification', {
            message: 'Market configuration has been successfully saved.',
            type: 'success',
            duration: 3000,
          });
        })
        .catch((error) => {
          console.log('Saving configuration failed ---> ', error);
        })
        .finally(() => { savingConfiguration.value = false; });
    };

    watch(() => eventUnderLine.value, (newValue, oldValue) => {
      if (isNil(newValue)) return;
      each(filteredPeriods.value, (period) => {
        if (Number(period.details.underMainLine) === Number(oldValue) && Number(period.details.overMainLine) === Number(eventOverLine.value)) {
          assign(period.details, { underMainLine: Number(newValue) });
          selectedRuleset.value[`${selectedConfiguration.value}Configuration`].offeringBounds[period.name].underMainLine = Number(newValue);
          if (syncToggled.value) {
            selectedRuleset.value.displayConfiguration.offeringBounds[period.name].underMainLine = Number(newValue);
          }
        }
      });
      updatePeriods();
      unsavedChanges.value = true;
    });

    watch(() => eventOverLine.value, (newValue, oldValue) => {
      if (isNil(newValue)) return;
      each(filteredPeriods.value, (period) => {
        if (Number(period.details.overMainLine) === Number(oldValue) && Number(period.details.underMainLine) === Number(eventUnderLine.value)) {
          assign(period.details, { overMainLine: Number(newValue) });
          selectedRuleset.value[`${selectedConfiguration.value}Configuration`].offeringBounds[period.name].overMainLine = Number(newValue);
          if (syncToggled.value) {
            selectedRuleset.value.displayConfiguration.offeringBounds[period.name].overMainLine = Number(newValue);
          }
        }
      });
      updatePeriods();
      unsavedChanges.value = true;
    });

    const onEscEventListener = (e) => {
      if (e.key === 'Escape') {
        if (closePopupOpen.value) {
          closePopupOpen.value = false;
        } else { closePopup(); }
      }
    };
    onMounted(() => document.addEventListener('keydown', onEscEventListener));
    onBeforeUnmount(() => document.removeEventListener('keydown', onEscEventListener));

    const cashoutConfiguration = computed(() => selectedRuleset.value.cashoutConfiguration);
    const setCashoutConfiguration = (newCashoutConfiguration) => {
      selectedRuleset.value.cashoutConfiguration = newCashoutConfiguration;
      unsavedChanges.value = true;
    };
    const toggleInPlayOffer = () => {
      selectedRuleset.value.offeringConfiguration.offerInPlay = !selectedRuleset.value.offeringConfiguration.offerInPlay;
    };
    const togglePreMatchOffer = () => {
      selectedRuleset.value.offeringConfiguration.offerPreMatch = !selectedRuleset.value.offeringConfiguration.offerPreMatch;
    };

    const resultingConfiguration = computed(() => selectedRuleset.value.resultingConfiguration);
    const setResultingConfiguration = (newResultingConfiguration) => {
      selectedRuleset.value.resultingConfiguration = newResultingConfiguration;
      unsavedChanges.value = true;
    };

    const equalSelectionParams = (disabledSelection, selection) => (
      disabledSelection.bandSize === selection.bandSize
        && disabledSelection.homeScore === selection.homeScore
        && disabledSelection.awayScore === selection.awayScore
        && disabledSelection.goals === selection.goals
        && disabledSelection.order === selection.order
        && disabledSelection.lowerBound === selection.lowerBound
        && disabledSelection.upperBound === selection.upperBound
        && disabledSelection.team === selection.team
        && disabledSelection.number === selection.number
    );

    const updateDisabledSelections = ({ selection }) => {
      const foundDisabledSelection = find(selectedRuleset.value.disabledSelections || [],
        (disabledSelection) => disabledSelection.type === selection.type && equalSelectionParams(disabledSelection, selection));
      if (!foundDisabledSelection) {
        if (!selectedRuleset.value.disabledSelections) selectedRuleset.value.disabledSelections = [];
        selectedRuleset.value.disabledSelections.push(selection);
        return;
      }
      selectedRuleset.value.disabledSelections = filter(selectedRuleset.value.disabledSelections,
        (disabledSelection) => (disabledSelection.type !== selection.type) || (disabledSelection.type === selection.type && !equalSelectionParams(disabledSelection, foundDisabledSelection)));
    };

    return {
      configuration,
      closePopup,
      configurationsPopupRef,
      advancedSettingsVisible,
      toggleAdvancedSettings,
      templateUpdateInfo,
      selectedRuleset,
      selectRuleset,
      selectedConfiguration,
      selectConfiguration,
      syncToggled,
      startBoundPeriod,
      stopBoundPeriod,
      startBoundValue,
      stopBoundValue,
      linesInfoOpen,
      skipLinesExists,
      hasPreGame,
      hasEventLine,
      eventUnderLine,
      eventOverLine,
      uniquePeriods,
      availablePeriods,
      addUniquePeriod,
      removeUniquePeriod,
      selectPeriod,
      updateStartStopBounds,
      updateUniquePeriods,
      saveConfiguration,
      toggleSync,
      updadeSkipLines,
      updatePreGame,
      displayRulesetSettingsIcon,
      rulesetTabRef,
      rulesetDropdownToggle,
      disableRuleset,
      restoreDefaultRuleset,
      enableRuleset,
      closePopupOpen,
      closePopupRef,
      closeSecondPopup,
      savingConfiguration,
      limitTiers,
      applyLimitTier,
      marginTypes,
      applyMarginType,
      marginHold,
      marginPrematchHold,
      marginInPlayHold,
      updateMarginHold,
      updatePrematchMarginHold,
      updateInPlayMarginHold,
      updateHold,
      updatePrematchHold,
      updateInPlayHold,
      updateMax,
      updatePrematchMax,
      updateInPlayMax,
      marginMax,
      marginPrematchMax,
      marginInPlayMax,
      updateMarginMax,
      updatePrematchMarginMax,
      updateInPlayMarginMax,
      MARGIN_TYPE,
      prematchCent,
      inPlayCent,
      updatePrematchCent,
      updateInPlayCent,
      currentDefaultTemplateConfiguration,
      currentTemplateConfiguration,
      updateCurrentTemplateConfiguration,
      isSaveConfigurationDisabled,
      cashoutConfiguration,
      setCashoutConfiguration,
      updateBaseballBounds,
      toggleInPlayOffer,
      togglePreMatchOffer,
      resultingConfiguration,
      setResultingConfiguration,
      updateDisabledSelections,
      updateAltStepClick,
      updateAltStep,
      altStep,
      updateAltHoldClick,
      updateAltHold,
      altHold,
      maxAltHoldError,
    };
  },
};
</script>

<style lang="scss">
@import '@vueform/slider/themes/default.scss';

.market-configurations-popup {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: 0;
  margin: auto;
  height: 744px;
  width: 770px;
  background-color: #fff;
  border: 1px solid #F0F0F0;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-sizing: border-box;
  z-index: $modalZIndex;

  &.close-popup {
    border: 0;
  }

  .market-configurations-header {
    padding: 16px 32px 13px;
    box-shadow: inset 0px -1px 0px #F0F0F0;
    box-sizing: border-box;

    .market-configurations-name {
      height: 30px;
      line-height: 30px;
      font-size: 20px;
      font-weight: 600;
      font-family: 'Poppins';
      color: #191414;
      margin-bottom: 4px;
    }

    .market-configurations-date {
      height: 17px;
      line-height: 17px;
      font-weight: 400;
      font-size: 14px;
      color: #6D6D6D;
    }
  }

  .market-configurations-rulesets {
    padding-top: 16px;
    box-sizing: border-box;
    width: 100%;
    background-color: #FAFAFA;

    .rulesets-header {
      display: flex;
      height: 18px;
      align-items: center;
      padding-left: 32px;

      .rulesets-label {
        color: #000000;
        font-weight: 600;
      }
    }

    .rulesets-tabs {
      width: 100%;
      height: 42px;
      display: flex;
      box-shadow: inset 0px -2px 0px #CDCDCD;

      .ruleset-tab {
        height: 100%;
        padding: 0 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        font-weight: 400;
        cursor: pointer;

        &:first-child {
          margin-left: 32px;
        }

        &.selected {
          box-shadow: inset 0px -2px 0px #191414;
        }

        &.disabled {
          color: #A9A9A9;
        }

        .icon {
          position: absolute;
          right: 10px;
        }

        .ruleset-dropdown {
          position: absolute;
          top: 42px;
          left: 70%;
          width: 160px;
          background: #FFFFFF;
          border: 1px solid #F0F0F0;
          box-sizing: border-box;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
          z-index: 111;

          .ruleset-dropdown-option {
            height: 44px;
            width: 100%;
            padding: 0 12px;
            display: flex;
            align-items: center;
            cursor: pointer;
            color: #191414;

            &:hover {
              background-color: rgba(0, 0, 0, 0.03);
            }
          }
        }
      }
    }
  }

  .market-configuration-ps {
    height: 521px;
    overflow-y: auto;
    position: relative;
    z-index: 100;

    .disabled-ruleset {
      background-color: rgba(#fff, 0.9);
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 301;
      display: flex;
      align-items: center;
      padding-top: 200px;
      flex-direction: column;

      .disabled-ruleset-label {
        display: flex;
        height: 19px;
        align-items: center;
        margin-bottom: 16px;
      }

      .disabled-ruleset-enable {
        color: #175FFF;
        cursor: pointer;
      }

      .icon {
        height: 19px;
        width: 19px;
        margin-right: 8px;

        svg {
          height: 19px;
          width: 19px;
          path {
            stroke: #FF2E2D;
            stroke-width: 2;
          }
        }
      }
    }

    .market-configurations-body {
      width: 100%;
      padding: 32px 32px 18px;
      box-sizing: border-box;

      .market-configurations-body-tabs-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 17px;
        margin-bottom: 12px;

        .tabs-header-label {
          height: 100%;
          color: #000;
          line-height: 17px;
          font-weight: 600;
        }

        .tabs-header-switch {
          height: 100%;
          display: flex;
          align-items: center;
          color: #6D6D6D;
          font-weight: 400;

          .toggle {
            margin: 0 8px;
          }
        }
      }

      .market-configurations-body-tabs {
        width: 100%;
        height: 44px;
        display: flex;
        box-shadow: inset 0px -2px 0px #CDCDCD;

        .market-configurations-body-tab {
          min-width: 96px;
          height: 100%;
          padding: 0 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          font-weight: 400;
          color: #191414;
          cursor: pointer;

          &.selected {
            box-shadow: inset 0px -2px 0px #191414;
          }
        }

      }

      .market-configurations-content {
        width: 100%;
        padding-top: 20px;
        box-sizing: border-box;

        .bounds-wrapper {
          padding-left: 8px;
          box-sizing: border-box;
          width: 100%;
          margin-bottom: 70px;

          .bound-headers {
            width: 100%;
            height: 17px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 400;
            color: #191414;
            margin-bottom: 12px;
          }

          .bound-content {
            display: flex;
            width: 100%;
            justify-content: space-between;
            height: 33px;

            .bound {
              height: 100%;
              align-items: center;
              color:#6D6D6D;
              font-weight: 400;

              &.fixed-bound {
                background: #FAFAFA;
                border: 1px solid #F0F0F0;
                box-sizing: border-box;
                border-radius: 4px;
                color: #A9A9A9;
                display: flex;
                align-items: center;
                padding: 0 8px;
              }
            }
          }
          .offer-checkbox-root {
            display: flex;
            align-items: center;
            gap: 16px;
            padding-top: 16px;
            .offer-checkbox-item {
              display: flex;
              align-items: center;
              gap: 8px;
              span {
                cursor: pointer;
              }
            }
          }
        }

        .lines-configuration {
          width: 100%;

          .lines-configuration-header {
            display: flex;
            height: 18px;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;

            .lines-configuration-header-tooltip {
              display: flex;
              height: 100%;
              align-items: center;

              .lines-configuration-header-label {
                color: #000000;
                font-weight: 600;
              }

              .tooltip {
                height: 100%;
                margin-left: 10px;

                .tooltip-element {
                  height: 16px;
                  width: 16px;
                  display: flex;

                  .icon {
                    height: 16px;
                    width: 16px;
                  }
                }

                .tooltip-text {
                  padding: 8px;
                  white-space: nowrap;
                  height: unset;
                }
              }

            }

            .lines-configuration-information {
              display: flex;
              align-items: center;
              padding-right: 12px;
              cursor: pointer;

              .icon {
                stroke: #191414;
                margin-left: 4px;
              }
            }
          }

          .lines-configuration-information-details {
            display: flex;
            height: 65px;
            width: 100%;
            background: #FAFAFA;
            border: 1px solid #F0F0F0;
            box-sizing: border-box;
            border-radius: 4px;
            color: #6D6D6D;
            align-items: center;
            padding:  0 32px;
            margin-bottom: 6px;

            .info-details-value {
              margin: 0 16px 0 8px;
              height: 33px;
              background: #FAFAFA;
              border: 1px solid #F0F0F0;
              box-sizing: border-box;
              border-radius: 4px;
              color: #A9A9A9;
              box-sizing: border-box;
              padding: 0 8px;
              display: flex;
              align-items: center;
              justify-content: center;

              &:last-child {
                margin-right: 0;
              }
            }
          }

          .skip-lines-row {
            width: 100%;
            height: 64px;
            background: #FFFFFF;
            box-shadow: inset 0px -1px 0px #F0F0F0;
            color: #6D6D6D;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            margin-bottom: 16px;

            .tooltip {
              display: flex;
              align-items: center;
            }

            .market-configuration-input {
              height: 33px;

              &:first-child {
                margin-left: 8px;
                margin-right: 8px;
              }
            }
          }

          .lines-configuration-bounds-wrapper {
            width: 100%;

            &.no-skip {
              margin-top: 22px;
            }

            .lines-configuration-bound {
              height: 33px;
              width: 100%;
              color: #6D6D6D;
              font-weight: 400;
              margin-bottom: 12px;
              display: flex;
              align-items: center;

              strong {
                margin-left: 4px;
              }

              .market-configuration-input {
                margin: 0 8px;
              }

              .icon {
                margin-left: 18px;
                cursor: pointer;
                svg {
                  stroke: #191414;
                }
              }
            }

            .period-dropdown-wrapper {
              height: 33px;
              position: relative;
              margin-left: 8px;

              .selected-period {
                border: 1px solid #DDDDDD;
                box-sizing: border-box;
                border-radius: 4px;
                padding: 0 12px;
                display: flex;
                align-items: center;
                min-width: 175px;
                height: 100%;
                justify-content: space-between;
                cursor: pointer;
                font-weight: 400;
                color: #191414;

                .icon {
                  svg {
                    stroke: #191414;
                  }
                }
              }

              .period-dropdown {
                position: absolute;
                top: 36px;
                left: 0;
                width: 100%;
                background-color: #fff;
                border: 1px solid #EBEBEB;
                box-sizing: border-box;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
                border-radius: 4px;
                z-index: 103;

                .dropdown-option {
                  height: 44px;
                  display: flex;
                  align-items: center;
                  padding: 0 12px;
                  box-sizing: border-box;
                  cursor: pointer;
                  color: #191414;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;

                  &:hover {
                    background-color: rgba(0, 0, 0, 0.03);
                  }
                }
              }
            }

            .define-lines-button {
              height: 33px;
              border: 1px solid #DDDDDD;
              box-sizing: border-box;
              border-radius: 4px;
              padding: 0 12px;
              color: #191414;
              display: flex;
              align-items: center;
              width: fit-content;
              cursor: pointer;
              margin-bottom: 58px;

              &:hover {
                background-color: rgba(0, 0, 0, 0.03);
              }

              .icon {
                height: 14px;
                width: 14px;
                margin-left: 4px;

                svg {
                  stroke: #191414;
                }
              }
            }
          }
        }

        .market-configuration-input {
          height: 100%;
          width: 44px;
          color: #191414;
          border-radius: 4px;
          outline: none;
          line-height: 33px;
          border: 1px solid #CDCDCD;
          box-sizing: border-box;
          margin: 0 4px 0 8px;
          padding: 0 4px;
          text-align: center;

          &:first-child {
            margin-left: 0;
          }
        }

        .risk-configuration-section {
          width: 100%;
          margin-bottom: 16px;
          display: flex;

          .risk-configuration-group {
            margin-right: 16px;
            display: flex;
            flex-direction: column;

            .label {
              color: #A9A9A9;
              font-size: 10px;
              font-weight: 600;
              margin-bottom: 4px;
              display: flex;

              .tooltip {
                height: 100%;
                margin-left: 8px;

                .tooltip-element {
                  height: 14px;
                  width: 14px;
                  display: flex;

                  .icon {
                    height: 14px;
                    width: 14px;
                  }
                }

                .tooltip-text {
                  padding: 8px;
                  white-space: unset;
                  height: unset;
                  width: 244px;
                  font-weight: 400;
                }
              }

              &.error {
                .tooltip {
                  .tooltip-element {
                    .icon {
                      height: 14px;
                      width: 14px;
                      svg {
                        path {
                          stroke: #FF2E2D;
                        }
                      }
                    }
                  }
                }
              }
            }

            &.step {
              width: 220px;
            }

            .risk-dropdown {
              width: 220px;

              .dropdown {
                z-index: unset;

                .dropdown-button {
                  border-color: #DDDDDD;
                }
              }

              .dropdown-button {
                width: 100%;
                justify-content: space-between;
              }
            }

            .risk-input {
              display: flex;

              .increase-decrease-button {
                height: 33px;
                width: 33px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #DDDDDD;
                cursor: pointer;

                &.left {
                  border-top-left-radius: 4px;
                  border-bottom-left-radius: 4px;
                }

                &.right {
                  border-top-right-radius: 4px;
                  border-bottom-right-radius: 4px;
                }

                svg {
                  path {
                    stroke: #191414;
                  }
                }
              }

              .table-number-input {
                width: 94px;

                input {
                  text-align: center;
                  border-color: #DDDDDD;
                  height: 33px;
                  border-right: 0;
                  border-left: 0;
                  border-radius: 0;
                }
              }
              .us-cent-dd {
                width: 120px;
              }
            }
          }
        }
      }
    }
  }

  .market-configurations-footer {
    height: 65px;
    width: 100%;
    box-shadow: inset 0px 1px 0px #F0F0F0;
    padding: 0 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .market-configurations-button {
      height: 33px;
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #191414;
      width: 120px;
      justify-content: center;
      font-weight: 400;
      border-radius: 4px;

      &:hover {
        background-color: rgba(#000, 0.05);
      }

      &.save {
        background-color: #003C3C;
        margin-left: 5px;
        color: #fff;
      }

      &.disabled {
        opacity: 0.8;
        cursor: default;
      }
    }
  }

  .tooltip {
    height: 100%;
    margin-left: 10px;

    .tooltip-element {
      height: 16px;
      width: 16px;
      display: flex;

      .icon {
        height: 16px;
        width: 16px;
      }
    }

    .tooltip-text {
      padding: 8px;
      white-space: nowrap;
      height: unset;
    }
  }

  input[type='number'] {
    -moz-appearance:textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }

  .close-popup-wrapper {
    position: absolute;
    width: 440px;
    height: 151px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    z-index: 310;
    padding: 16px;

    .unsaved-changes-header {
      color: #191414;
      font-weight: 600;
      margin-bottom: 12px;
    }

    .unsaved-changes-message {
      margin-bottom: 32px;
    }

    .unsaved-changes-buttons {
      height: 33px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .unsaved-changes-button {
        height: 100%;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
          background-color: rgba(#000, 0.05);
        }

        &.yes {
          color: #fff;
          background: #003C3C;
          margin-left: 5px;
        }
      }
    }
  }

  .close-current-popup-bg-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(#000, 0.5);
    z-index: 105;
    left: 0;
    border-radius: 8px;
  }
}

.close-popup-bg-overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(#000, 0.5);
  z-index: $modalBackgroundZIndex;
  left: 0;
  top: 0;
}
</style>
