<template>
  <div
    :class="[
      'tooltip',
      { 'tooltip--right': right || position === 'right' },
      { 'tooltip--left': left || position === 'left' },
      { 'tooltip--top': top || position === 'top' },
      { 'tooltip--bottom': bottom || position === 'bottom' },
      { 'is-active': isVisible },
    ]"
    @mouseover="displayTooltip"
    @mouseleave="hideTooltip"
  >
    <div class="tooltip-element">
      <slot />
    </div>

    <div
      v-show="isVisible"
      class="tooltip-text"
      ref="tooltipText"
    >
      {{ text }}
    </div>

    <span
      v-show="isVisible"
      class="tooltip-text-arrow"
    />
  </div>
</template>

<script>
import {
  ref, watch, onMounted, nextTick, onBeforeUnmount,
} from 'vue';

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    left: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: false,
    },
    parentSelector: {
      type: String,
      default: null, // CSS selector of the parent container
    },
  },
  setup(props) {
    const isVisible = ref(false);
    const position = ref(null); // Tooltip position
    const tooltipText = ref(null);
    const parentElement = ref(null);

    const adjustTooltipPosition = () => {
      if (!tooltipText.value) return;

      const tooltip = tooltipText.value;
      const tooltipRect = tooltip.getBoundingClientRect();
      const parent = parentElement.value || document.documentElement;
      const parentRect = parent.getBoundingClientRect();

      // Determine available space
      const spaceRight = parentRect.right - tooltipRect.right;
      const spaceLeft = tooltipRect.left - parentRect.left;
      const spaceTop = tooltipRect.top - parentRect.top;
      const spaceBottom = parentRect.bottom - tooltipRect.bottom;

      if (props.bottom || props.top || props.left || props.right) {
        return; // Skip if position is fixed
      }

      // Reset and adjust position based on available space
      if (spaceRight >= tooltipRect.width) {
        position.value = 'right';
      } else if (spaceLeft >= tooltipRect.width) {
        position.value = 'left';
      } else if (spaceTop >= tooltipRect.height) {
        position.value = 'top';
      } else if (spaceBottom >= tooltipRect.height) {
        position.value = 'bottom';
      } else {
        position.value = 'right'; // Default to right
      }
    };

    const displayTooltip = async () => {
      if (props.hide) return;

      // Ensure visibility and reset position
      isVisible.value = true;
      position.value = null;

      // Wait for DOM updates, then calculate position
      await nextTick();
      adjustTooltipPosition();
    };

    const hideTooltip = () => {
      isVisible.value = false;
    };

    const handleResize = () => {
      if (isVisible.value) {
        adjustTooltipPosition(); // Recalculate position if tooltip is visible
      }
    };

    onMounted(() => {
      if (props.parentSelector) {
        parentElement.value = document.querySelector(props.parentSelector);
      }
      window.addEventListener('resize', handleResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });

    watch(() => props.hide, (newHide) => {
      if (newHide) {
        isVisible.value = false;
      }
    });

    return {
      isVisible,
      position,
      tooltipText,
      displayTooltip,
      hideTooltip,
    };
  },
};
</script>

<style lang="scss">
.tooltip {
  position: unset;
  width: auto;
  height: auto;
  z-index: 100;
  user-select: none;

  &.is-active {
    position: relative;
    z-index: 101;
  }

  .tooltip-element {
    width: 100%;
    height: 100%;
  }

  .tooltip-text {
    display: flex;
    align-items: center;
    position: absolute;
    font-size: 12px;
    background-color: $black;
    color: $white;
    white-space: nowrap;
    padding: 0 12px;
    height: 38px;
    border-radius: 8px;
    z-index: 100;
  }

  .tooltip-text-arrow {
    background-color: $black;
    position: absolute;
    width: 6px;
    height: 6px;
  }

  /** LEFT & RIGHT */
  &.tooltip--right,
  &.tooltip--left {
    .tooltip-text,
    .tooltip-text-arrow {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.tooltip--right {
    .tooltip-text {
      left: calc(100% + 6px + 10px);
    }

    .tooltip-text-arrow {
      left: calc(100% + 10px);
      clip-path: polygon(100% 0, 0 50%, 100% 100%);
    }
  }
  &.tooltip--left {
    .tooltip-text {
      right: calc(100% + 6px + 10px);
    }

    .tooltip-text-arrow {
      right: calc(100% + 10px);
      clip-path: polygon(100% 50%, 0 0, 0 100%);
    }
  }

  /** TOP & BOTTOM */
  &.tooltip--top,
  &.tooltip--bottom {
    .tooltip-text,
    .tooltip-text-arrow {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &.tooltip--top {
    .tooltip-text {
      bottom: calc(100% + 10px);
    }

    .tooltip-text-arrow {
      bottom: calc(100% + 4px);
      clip-path: polygon(0 0, 50% 100%, 100% 0);
    }
  }
  &.tooltip--bottom {
    .tooltip-text {
      top: calc(100% + 10px);
    }

    .tooltip-text-arrow {
      top: calc(100% + 4px);
      clip-path: polygon(0 100%, 50% 0, 100% 100%);
    }
  }
}
</style>
