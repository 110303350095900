<template>
  <div
    ref="rootElement"
    class="events-filter-dropdown"
  >
    <div class="events-filter-dropdown__search">
      <Icon
        class="events-filter-dropdown__search-icon"
        name="search"
      />
      <input
        :value="search"
        @input="onSearch"
        :class="
          ['events-filter-dropdown__search-field',
           {'has-selected-events': selectedEvents?.length}]"
        type="text"
        :placeholder="placeholder"
        @focus="openMenu"
      >
      <Icon
        v-if="!search"
        class="events-filter-dropdown__search-close"
        name="x"
        @click.stop="removeFilter"
      />
    </div>
    <div
      v-if="isMenuOpen"
      class="events-filter-dropdown__menu"
    >
      <div
        v-if="isLoading"
        class="events-filter-dropdown__menu-message"
      >
        <Spinner
          small
        />
        Finding events...
      </div>
      <div
        v-else-if="!events?.length"
        class="events-filter-dropdown__menu-message"
      >
        No events found.
      </div>
      <div
        v-for="event in events"
        v-show="!isLoading"
        :key="event.eventId"
        class="events-filter-dropdown__menu-item"
        @click="addEventToList(event.eventId)"
      >
        <div>
          <Checkbox
            :model-value="isEventInList(event.eventId)"
            @update:modelValue="addEventToList(event.eventId)"
          />
        </div>
        <div class="events-filter-dropdown__event">
          <div class="events-filter-dropdown__event-heading">
            {{ formatEventName(event.eventName, event.isUsaView) }}
          </div>
          <div class="events-filter-dropdown__event-subheading">
            {{ formatEventStartTime(event.startsAt) }}
            &bull;
            {{ event.competition?.competitionName || 'N/A' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import {
  split,
  debounce,
  reverse,
  join,
  pickBy,
  identity,
  isArray,
  set,
  replace,
  includes,
  filter,
  cloneDeep,
  take,
} from 'lodash';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { fetchBetTickerEventsResults } from '@/services/api';
import Icon from '@/components/common/Icon';
import Spinner from '@/components/common/Spinner';
import Checkbox from '@/components/common/Checkbox';
import isUuid from '@/utils/is-uuid';

export default {
  components: {
    Icon,
    Spinner,
    Checkbox,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  emits: {
    'update:filter': {
      type: Object,
    },
    removeFilter: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const currentFilter = ref(cloneDeep(props.filter));
    const syncCurrentFilter = () => {
      const updatedFilter = cloneDeep(currentFilter.value);
      emit('update:filter', updatedFilter);
      currentFilter.value = updatedFilter;
    };
    const rootElement = ref(null);
    const isMenuOpen = ref(false);
    const isLoading = ref(false);
    const events = ref([]);
    const search = ref('');
    const selectedEvents = computed(() => currentFilter.value?.parameter?.events);
    const placeholder = computed(() => {
      const selectedEventsCount = selectedEvents.value?.length || 0;
      if (selectedEventsCount === 0) return 'Search by Name or ID';
      if (selectedEventsCount === 1) return '1 event selected';
      return `${selectedEventsCount} events selected`;
    });

    const loadEvents = debounce(() => {
      events.value = [];
      isLoading.value = true;

      const queryOptions = {
        filter: {
          sport: { limbo: { equalTo: false } },
          eventIsCoveredsByEventId: {
            some: {
              available: {
                equalTo: true,
              },
            },
          },
        },
        includeSelectedEvents: false,
        condition: {},
      };

      set(queryOptions, 'orderBy', 'STARTS_AT_DESC');

      if (search.value) {
        if (isUuid(search.value)) {
          set(queryOptions, 'filter.eventId.equalTo', search.value);
        } else {
          set(queryOptions, 'condition.eventNameSimilar', search.value);
        }
      } else {
        set(queryOptions, 'filter.eventId.notIn', selectedEvents.value || []);
        set(queryOptions, 'selectedEventsfilter.eventId.in', selectedEvents.value || []);
        set(queryOptions, 'includeSelectedEvents', true);
      }

      fetchBetTickerEventsResults(pickBy(queryOptions, identity))
        .then((response) => {
          const eventsRes = response.data?.events?.nodes || [];
          const selectedEventsRes = response.data?.selectedEvents?.nodes || [];
          events.value = take([...selectedEventsRes, ...eventsRes], 10);
        })
        .catch((error) => {
          console.error(error);
          events.value = [];
        })
        .finally(() => {
          isLoading.value = false;
        });
    }, 300);
    const openMenu = () => {
      if (isMenuOpen.value) return;
      isMenuOpen.value = true;
      isLoading.value = true;
      loadEvents();
    };
    const closeMenu = () => {
      if (isMenuOpen.value) {
        isMenuOpen.value = false;
        isLoading.value = false;
        events.value = [];
        search.value = null;
        search.value = '';

        syncCurrentFilter();
      }
    };
    const onSearch = (event) => {
      search.value = event.target.value;
      loadEvents();
    };
    const formatEventName = (eventName, isUsaView) => {
      const competitors = split(eventName, ' v ');
      if (isUsaView) return join(reverse(competitors), ' @ ');
      return join(competitors, ' vs ');
    };
    const formatEventStartTime = (eventStartsAt) => {
      const formattedStartsAt = format(zonedTimeToUtc(eventStartsAt, 'UTC'), 'MMM d yyyy, hh:mm a');
      return replace(formattedStartsAt, ` ${new Date().getFullYear()}`, '');
    };
    const isEventInList = (eventId) => {
      let ids = selectedEvents.value || [];
      if (!isArray(ids)) ids = [ids];
      return includes(ids, eventId);
    };
    const addEventToList = (eventId) => {
      if (isEventInList(eventId)) {
        currentFilter.value.parameter.events = filter(
          currentFilter.value.parameter.events,
          (currentEventId) => currentEventId !== eventId,
        );
      } else {
        currentFilter.value.parameter.events = [
          ...currentFilter.value.parameter.events,
          eventId,
        ];
      }
    };

    const removeFilter = () => {
      isMenuOpen.value = false;
      isLoading.value = false;
      events.value = [];
      search.value = null;
      search.value = '';
      emit('removeFilter', props.filter);
    };

    onClickOutside(rootElement, closeMenu);

    return {
      rootElement,
      isMenuOpen,
      isLoading,
      events,
      search,
      placeholder,
      openMenu,
      closeMenu,
      onSearch,
      formatEventName,
      formatEventStartTime,
      isEventInList,
      addEventToList,
      removeFilter,
      selectedEvents,
    };
  },
};
</script>

<style lang="scss">
.events-filter-dropdown {
  position: relative;
  width: 100%;
  &__search {
    display: flex;
    width: 100%;
    height: 32px;
    min-width: var(--width-large, 44px);
    flex-direction: row;
    align-items: center;
    position: relative;

    &-icon {
      position: absolute;
      left: 8px;
      width: 16px;
      height: 16px;
      stroke: #A9A9A9;
    }

    &-close {
      position: absolute;
      right: 16px;
      stroke: #191414;
      cursor: pointer;
    }

    &-field {
      width: 100%;
      padding: 8px 8px 8px 32px;
      border-radius: var(--radius-medium, 4px);
      border: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
      background: var(--neutral-bg-default-resting, #FFF);
      height: 32px;
      font-size: 14px;

      &::placeholder {
        color: #CDCDCD;
      }

      &:focus {
        box-shadow: 0px 0px 0px 2px #A2BFFF;
        outline: none;
      }

      &.has-selected-events {
        &::placeholder {
        color: #777777;
      }
    }
    }
  }

  &__menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    overflow-y: auto;
    z-index: 9000;
    display: flex;
    flex-direction: column;
    border-radius: var(--radius-medium, 4px);
    border: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
    background: var(--neutral-bg-default-resting, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(25, 20, 20, 0.08);
    margin-top: 4px;
    padding: 4px 0;
    user-select: none;

    &-message {
      display: flex;
      align-items: center;
      padding: 8px;
      gap: 8px;
      background: var(--neutral-bg-default-resting, #FFF);
      overflow: hidden;
      color: var(--neutral-text-default-resting, #191414);
      text-overflow: ellipsis;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      white-space: nowrap;
      width: 100%;
      flex: 1;
      cursor: default;
    }

    &-item {
      display: flex;
      align-items: center;
      padding: 8px;
      gap: 8px;
      background: var(--neutral-bg-default-resting, #FFF);
      overflow: hidden;
      color: var(--neutral-text-default-resting, #191414);
      text-overflow: ellipsis;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      white-space: nowrap;
      width: 100%;
      flex: 1;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: #FAFAFA;
        outline: none;
      }

      &--is-disabled {
        cursor: not-allowed;

        &:hover,
        &:focus {
          background-color: #FFF;
        }

        .events-filter-dropdown__event-heading,
        .events-filter-dropdown__event-subheading,
        .events-filter-dropdown__fake-button {
          color: #A9A9A9;

          .icon svg path {
            stroke: #A9A9A9;
          }
        }
      }
    }
  }

  &__event {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    overflow: hidden;

    &-heading {
      font-size: 14px;
      color: #191414;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-subheading {
      font-size: 12px;
      color: #807f7f;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-metadata {
      display: flex;
      align-items: center;
      gap: 4px;
      color: #A9A9A9;
    }
  }

  &__fake-button {
    font-size: 12px;
    flex-shrink: 0;
    color: #191414;
  }
}
</style>
