<template>
  <div class="event-hidden-indicator">
    <div class="event-hidden-indicator__icon">
      <Icon name="eye-off" />
    </div>
    <div class="event-hidden-indicator__text">
      Event hidden
    </div>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
};
</script>

  <style lang="scss">
  .event-hidden-indicator {
    display: flex;
    align-items: center;
    gap: 4px;
    &__icon {
      svg {
        width: 14px;
        height: 14px;
        path {
          fill: $black;
        };
      }
      /* color: $color-danger; */
    }
  }
  </style>
