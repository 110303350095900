<template>
  <BaseballTradingTeamParams
    v-if="isBaseball"
    :team-params="teamParams"
    :selected-team="selectedTeam"
    :is-calculated-mode="isCalculatedMode"
    :global-params="globalParams"
    @onParamChange="onTeamParamChange"
    @onParamIncrease="onTeamParamIncrease"
    @onParamDecrease="onTeamParamDecrease"
  />
  <CommonSportsTeamParams
    v-else
    :team-params="teamParams"
    :selected-team="selectedTeam"
    :search-active="searchActive"
    :is-calculated-mode="isCalculatedMode"
    @onParamChange="onTeamParamChange"
    @onParamIncrease="onTeamParamIncrease"
    @onParamDecrease="onTeamParamDecrease"
  />
</template>

<script>
import { computed } from 'vue';
import sportIds from '@/services/helpers/sports';
import CommonSportsTeamParams from './CommonSportsTeamParams';
import BaseballTradingTeamParams from './baseball/BaseballTradingTeamParams';

const {
  BASEBALL_ID,
} = sportIds;

export default {
  emits: [
    'onParamChange',
    'onParamIncrease',
    'onParamDecrease',
  ],
  components: {
    CommonSportsTeamParams,
    BaseballTradingTeamParams,
  },
  props: {
    teamParams: {
      type: Object,
      required: true,
    },
    selectedTeam: {
      type: String,
      required: false,
      default: '',
    },
    searchActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCalculatedMode: {
      type: Boolean,
      default: false,
    },
    sportId: {
      type: String,
      default: '',
    },
    globalParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const isBaseball = computed(() => props.sportId === BASEBALL_ID);
    const showNoParamsMessage = computed(() => {
      if (
        props.selectedTeam === 'both'
        && !props.teamParams?.teamA?.params?.length
        && !props.teamParams?.teamB?.params?.length
      ) return true;
      if (
        props.selectedTeam === 'teamA'
        && !props.teamParams?.teamA?.params?.length
      ) return true;
      if (
        props.selectedTeam === 'teamB'
        && !props.teamParams?.teamB?.params?.length
      ) return true;

      return false;
    });
    const onTeamParamChange = (value, param) => emit('onParamChange', value, param);
    const onTeamParamIncrease = (param) => emit('onParamIncrease', param);
    const onTeamParamDecrease = (param) => emit('onParamDecrease', param);

    return {
      showNoParamsMessage,
      isBaseball,
      onTeamParamChange,
      onTeamParamIncrease,
      onTeamParamDecrease,
    };
  },
};
</script>

<style lang="scss">
.team-params-items-root {
  .team-name-header {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 16px;
    background-color: $gray300;
    padding: 12px 8px;
  }
  .team-params-items {
    margin-bottom: 28px;
  }
  .no-params-message {
    padding: 50px 0;
    text-align: center;
  }
}
</style>
