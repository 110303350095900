<template>
  <div class="game-param-number-input">
    <div class="game-param-single-header">
      <div class="short-name">
        {{ param.shortName }}
      </div>
      <Tooltip
        v-if="param.longName"
        :text="param.longName"
        :parent-selector="parentSelector"
      >
        <Icon
          class="game-param-single-name-icon"
          name="info"
        />
      </Tooltip>
    </div>
    <NumberInput
      class="game-param-single-input"
      :class="{ 'is-error': param.isError }"
      :model-value="param.value"
      :is-error="param.isError"
      :error-message="param.errorMessage"
      :readonly="param.readonly || disabled"
      :max="param.maxValue || 100"
      :has-arrows="true"
      :number-of-decimals="param.numberOfDecimals || 3"
      @update:modelValue="onParamChange($event, param)"
      @onKeyUp="onParamIncrease(param)"
      @onKeyDown="onParamDecrease(param)"
    />
  </div>
</template>

<script>
import NumberInput from '@/components/common/NumberInput';
import Tooltip from '@/components/common/Tooltip';
import Icon from '@/components/common/Icon';

export default {
  emits: ['paramChange', 'paramIncrease', 'paramDecrease'],
  props: {
    param: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    parentSelector: {
      type: String,
      default: null, // CSS selector of the parent container
    },
  },
  components: {
    NumberInput,
    Tooltip,
    Icon,
  },
  setup(_, { emit }) {
    const onParamChange = (value, param) => {
      emit('paramChange', value, param);
    };
    const onParamIncrease = (param) => {
      emit('paramIncrease', param);
    };
    const onParamDecrease = (param) => {
      emit('paramDecrease', param);
    };

    return {
      onParamChange,
      onParamIncrease,
      onParamDecrease,
    };
  },
};
</script>

<style lang="scss">
.game-param-number-input {
  width: 67px;
  &.is-error {
    width: 90px;
  }
  .game-param-single-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .short-name {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $gray800;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 12px;
      margin-bottom: 2px;
      font-weight: 600;
    }
    .game-param-single-name-icon {
      cursor: pointer;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
  .game-param-single-input {
    width: 100%;
    height: 32px;
    input {
      padding: 5px 8px;
    }
  }
}
</style>
