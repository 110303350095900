<template>
  <div
    v-if="columnKey === 'offering'"
    class="offering-cell"
  >
    <span v-if="!coreAndDerivativesSubscriptionInfo">
      -
    </span>
    <Chip
      v-if="bookedForPrematch"
      :variant="'blue'"
    >
      PM
    </Chip>
    <Chip
      v-if="bookedForLive"
      :variant="'green'"
    >
      LIVE
    </Chip>
  </div>
  <div
    class="features-cell"
    v-else-if="columnKey === 'features'"
  >
    <span v-if="!bookedKeys?.length">
      -
    </span>
    <Tooltip
      v-for="key in bookedKeys"
      :key="key"
      :text="getTooltip(key)"
      left
    >
      <Chip>
        {{ getLabel(key) }}
      </Chip>
    </Tooltip>
  </div>
  <div v-else-if="columnKey === 'mapping'">
    <Tooltip
      :text="competition?.mapped ? 'Competition mapped' : 'Competition not mapped'"
    >
      <span
        :class="[
          'icon-wrapper',
          { 'icon-wrapper--not-mapped': !competition?.mapped },
        ]"
      >
        <Icon :name="competition?.mapped ? 'link-not-broken' : 'link-broken'" />
      </span>
    </Tooltip>
  </div>
  <div
    v-else-if="columnKey === 'actions'"
    class="actions-cell"
  >
    <div
      :class="['actions-cell-button subscribe', {'active': !competition?.subscribed}]"
      @click="toggleSubscription"
    >
      <Icon
        v-if="competition?.subscribed"
        :name="'check'"
        class="check-icon"
      />
      {{ competition?.subscribed ? 'Subscribed' : 'Subscribe' }}
    </div>
    <div
      :class="['actions-cell-button customize', {'disabled': !competition?.subscribed}]"
      @click="openCustomizeModal"
    >
      <Icon :name="'settings'" />
      Customize
    </div>
  </div>
  <div
    v-else
    class="competition-table-cell"
  >
    <span
      :title="displayValue"
      v-html="displayValue"
    />
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { keys, pickBy, identity } from 'lodash';
import * as api from '@/services/api';
import { subscriptionTypes } from '@/services/helpers/subscriptions';
/* import {
  includes, startCase, toLower, replace,
} from 'lodash';
import MarketMapper from '@/services/helpers/market-mapper';
import OddsConverter from '@/services/helpers/odds-converter'; */
import Tooltip from '@/components/common/Tooltip';
import Chip from '@/components/common/Chip';
import Icon from '@/components/common/Icon';

const labelsByKey = {
  core: 'C&D',
  sameGameParlays: 'SGP',
  playerProps: 'PP',
  microMarkets: 'MM',
};

const tooltipsByKey = {
  core: 'Core and Derivative markets',
  sameGameParlays: 'Same Game Parlay',
  playerProps: 'Player Props markets',
  microMarkets: 'Micro Markets',
};

export default {
  components: {
    Tooltip,
    Chip,
    Icon,
  },
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    competition: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const displayValue = computed(() => props.competition[props.columnKey] || '-');

    const bookedMap = computed(() => pickBy({
      core: props.competition?.hasCoreMarkets,
      sameGameParlays: props.competition?.hasSgpMarkets,
      playerProps: props.competition?.hasPlayerPropsMarkets,
      microMarkets: props.competition?.hasMicroMarkets,
    }, identity));
    const bookedKeys = computed(() => keys(bookedMap.value));
    const coreAndDerivativesSubscriptionInfo = computed(() => props.competition?.core?.nodes[0]);
    const bookedForPrematch = computed(() => coreAndDerivativesSubscriptionInfo.value?.isOfferedPreMatch);
    const bookedForLive = computed(() => coreAndDerivativesSubscriptionInfo.value?.isOfferedLive);

    const getLabel = (key) => labelsByKey[key];
    const getTooltip = (key) => tooltipsByKey[key];

    const processing = ref(false);
    const subscribe = () => {
      processing.value = true;
      api.subscribe({ competitionId: props.competition?.competitionId })
        .then(() => {
          store.dispatch('loadSidebar');
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.errors?.length
            ? error.response?.data?.errors[0].detail
            : 'Unable to subscribe to competition';
          store.dispatch('addNotification', {
            message: errorMessage,
            type: 'error',
            duration: 5000,
          });
        })
        .finally(() => {
          processing.value = false;
        });
    };
    const unsubscribe = () => {
      processing.value = true;

      Promise.all([
        api.unsubscribe(props.competition?.competitionId),
        api.unsubscribeSameGameParlays(props.competition?.competitionId),
        api.unsubscribePlayerProps(props.competition?.competitionId),
        api.unsubscribeMicroMarkets(props.competition?.competitionId),
      ]).then(() => {
        store.dispatch('loadSidebar');
      }).catch((error) => {
        console.log(error);
        store.dispatch('addNotification', {
          message: 'Unable to unsubscribe from competition',
          type: 'error',
          duration: 5000,
        });
      }).finally(() => {
        processing.value = false;
      });
    };
    const toggleSubscription = () => {
      if (processing.value) return;
      if (!props.competition?.subscribed) {
        subscribe();
        return;
      }
      unsubscribe();
    };

    const openCustomizeModal = () => {
      if (!props.competition?.subscribed) return;
      store.dispatch('openSubscriptionModal', {
        type: subscriptionTypes.COMPETITION,
        id: props.competition?.competitionId,
      });
    };

    return {
      displayValue,
      bookedKeys,
      coreAndDerivativesSubscriptionInfo,
      bookedForPrematch,
      bookedForLive,
      getLabel,
      getTooltip,
      toggleSubscription,
      openCustomizeModal,
    };
  },
};
</script>

<style lang="scss">
  .competition-table-cell {
    overflow: hidden;

    span {
      width: 100%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .features-cell {
    display: flex;
    gap: 4px;
  }

  .actions-cell {
    display: flex;
    gap: 8px;

    .actions-cell-button {
      height: 32px;
      width: 110px;
      border: 1px solid #F0F0F0;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;

      &.subscribe {
        &.active {
          color: #fff;
          background-color: #003C3C;
        }
      }

      &.customize {
        &.disabled {
          color: #A9A9A9;
          background-color: #F0F0F0;

          .icon {
            svg {
              path {
                stroke: #A9A9A9;
                stroke-width: 1;
              }
            }
          }
        }
      }

      .icon {
        svg {
          path {
            stroke: #191414;
            stroke-width: 0;
          }
        }

        &.check-icon {
          svg {
            path {
              stroke-width: 1;
            }
          }
        }
      }
    }
  }

  .icon-wrapper {
    display: flex;
    .icon {
      width: 16px;
      height: 16px;
    }
    .icon path {
      fill: $black;
    }
  }

  .icon-wrapper.icon-wrapper--not-mapped {
    .icon path {
      fill: $error500;
    }
  }
</style>
