<template>
  <Modal
    title="Remove player from lineup"
    :visible="confirmModalActive"
    class="confirm-lineup-remove-modal"
    @close="onModalClose"
  >
    <div class="modal-text">
      <p>
        Unchecking this box will void all markets associated with this player and any selection representing this player in grouped markets.
      </p>
      <div class="alert-box">
        <Icon name="alert-triangle" />
        <div>
          <p>This action cannot be undone!</p>
          <p> Please check with or inform our clients about this action.</p>
        </div>
      </div>
    </div>
    <div class="modal-actions">
      <Button
        @click="onModalClose"
        variant="secondary"
      >
        Cancel
      </Button>
      <Button
        @click="onRemovePlayerFromLineup"
        variant="danger"
      >
        Delete
      </Button>
    </div>
  </Modal>
</template>

<script>
import Button from '@/components/common/Button';
import Modal from '@/components/common/Modal';
import Icon from '@/components/common/Icon';

export default {
  emits: ['onModalClose', 'onRemovePlayerFromLineup'],
  props: {
    confirmModalActive: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Button,
    Modal,
    Icon,
  },
  setup(_, { emit }) {
    const onModalClose = () => emit('onModalClose');
    const onRemovePlayerFromLineup = () => emit('onRemovePlayerFromLineup');
    return {
      onModalClose,
      onRemovePlayerFromLineup,
    };
  },
};
</script>

<style lang="scss">
  .confirm-lineup-remove-modal {
    .modal__dialog {
      max-width: 500px;
      .modal__header {
        .modal__title,
        .button {
          height: 100%;
          display: flex;
          align-items: center;
          margin-top: 0;
        }
        .button {
          height: 40px;
        }
      }
      .modal__content {
       .alert-box {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 8px;
        margin-bottom: 16px;
        padding: 8px;
        background-color: #FED5D5;
        border-radius: 4px;
        .icon {
          width: 24px;
          height: 24px;
          svg path {
            fill: $error500;
          }
        }
       }
       .modal-actions {
          padding-top: 16px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;
          border-top: 1px solid $gray400;
        }
      }
    }
  }
</style>
