<template>
  <div class="sgp-margin-configuration">
    <div class="sgp-margin-configuration__header">
      <div class="sgp-margin-configuration__header-title">
        SGP Hold Application
      </div>
      <Button
        variant="primary"
        :disabled="isSavingDisabled"
        @click="submit"
      >
        Save
      </Button>
    </div>
    <div class="sgp-margin-configuration__content">
      <div
        class="risk-configuration-section-sgp"
        v-if="sgpMarginConfiguration"
      >
        <div class="risk-configuration-group has-margin">
          <div class="label">
            HOLD APPLICATION
          </div>
          <div class="risk-dropdown">
            <Dropdown
              :label="marginTypes[sgpMarginConfiguration.type]?.label"
              :placeholder="'Select application'"
            >
              <DropdownItem
                v-for="margin in marginTypes"
                :key="margin.id"
                clickable
                @click="applyMarginType(margin.type)"
              >
                {{ margin.label }}
              </DropdownItem>
            </Dropdown>
          </div>
        </div>
        <div
          class="risk-configuration-group"
          v-if="sgpMarginConfiguration.type === MARGIN_TYPE.UNIFORM"
        >
          <div class="label">
            HOLD %
          </div>
          <div class="risk-input">
            <div
              class="increase-decrease-button left"
              @click="updateHold('-')"
            >
              <Icon :name="'minus'" />
            </div>
            <NumberInput
              :model-value="marginHold"
              @update:modelValue="updateMarginHold"
              :max="100"
            />
            <div
              class="increase-decrease-button right"
              @click="updateHold('+')"
            >
              <Icon :name="'plus'" />
            </div>
          </div>
        </div>
        <div
          class="risk-configuration-group"
          v-if="sgpMarginConfiguration.type === MARGIN_TYPE.STEEPNESS_FLATNESS"
        >
          <div class="label">
            MAX HOLD %
          </div>
          <div class="risk-input">
            <div
              class="increase-decrease-button left"
              @click="updateMax('-')"
            >
              <Icon :name="'minus'" />
            </div>
            <NumberInput
              :model-value="marginMax"
              @update:modelValue="updateMarginMax"
              :max="100"
            />
            <div
              class="increase-decrease-button right"
              @click="updateMax('+')"
            >
              <Icon :name="'plus'" />
            </div>
          </div>
        </div>
        <div class="risk-configuration-group multiple">
          <div
            class="risk-configuration-group"
            v-if="sgpMarginConfiguration.type === MARGIN_TYPE.UNIFORM_IP_PM || sgpMarginConfiguration.type === MARGIN_TYPE.UNIFORM_IP_PM_ALT"
          >
            <div class="label">
              PREGAME HOLD %
            </div>
            <div class="risk-input">
              <div
                class="increase-decrease-button left"
                @click="updatePrematchHold('-')"
              >
                <Icon :name="'minus'" />
              </div>
              <NumberInput
                :model-value="marginPrematchHold"
                @update:modelValue="updatePrematchMarginHold"
                :max="100"
              />
              <div
                class="increase-decrease-button right"
                @click="updatePrematchHold('+')"
              >
                <Icon :name="'plus'" />
              </div>
            </div>
          </div>
          <div
            class="risk-configuration-group"
            v-if="sgpMarginConfiguration.type === MARGIN_TYPE.UNIFORM_IP_PM || sgpMarginConfiguration.type === MARGIN_TYPE.UNIFORM_IP_PM_ALT"
          >
            <div class="label">
              IN-PLAY HOLD %
            </div>
            <div class="risk-input">
              <div
                class="increase-decrease-button left"
                @click="updateInPlayHold('-')"
              >
                <Icon :name="'minus'" />
              </div>
              <NumberInput
                :model-value="marginInPlayHold"
                @update:modelValue="updateInPlayMarginHold"
                :max="100"
              />
              <div
                class="increase-decrease-button right"
                @click="updateInPlayHold('+')"
              >
                <Icon :name="'plus'" />
              </div>
            </div>
          </div>
        </div>
        <div
          class="risk-configuration-group multiple"
          v-if="sgpMarginConfiguration.type === MARGIN_TYPE.UNIFORM_IP_PM_ALT"
        >
          <div
            class="risk-configuration-group"
          >
            <div class="label">
              STEP %
              <Tooltip :text="'Determine the hold percentage increase for alt line based on its distance from the main line.'">
                <Icon :name="'info'" />
              </Tooltip>
            </div>
            <div class="risk-input">
              <div
                class="increase-decrease-button left"
                @click="updateAltStepClick('-')"
              >
                <Icon :name="'minus'" />
              </div>
              <NumberInput
                :model-value="altStep"
                @update:modelValue="updateAltStep"
                :max="100"
              />
              <div
                class="increase-decrease-button right"
                @click="updateAltStepClick('+')"
              >
                <Icon :name="'plus'" />
              </div>
            </div>
          </div>
          <div class="risk-configuration-group">
            <div :class="['label', {'error': maxAltHoldError}]">
              MAX ALT HOLD %
              <Tooltip :text="'Max alt hold % value must be greater then the pregame hold % and the in-play hold %.'">
                <Icon :name="'info'" />
              </Tooltip>
            </div>
            <div class="risk-input">
              <div
                class="increase-decrease-button left"
                @click="updateAltHoldClick('-')"
              >
                <Icon :name="'minus'" />
              </div>
              <NumberInput
                :model-value="altHold"
                @update:modelValue="updateAltHold"
                :max="100"
              />
              <div
                class="increase-decrease-button right"
                @click="updateAltHoldClick('+')"
              >
                <Icon :name="'plus'" />
              </div>
            </div>
          </div>
        </div>
        <div class="risk-configuration-group multiple">
          <div
            class="risk-configuration-group"
            v-if="sgpMarginConfiguration.type === MARGIN_TYPE.STEEPNESS_FLATNESS_IP_PM"
          >
            <div class="label">
              PREGAME MAX HOLD %
            </div>
            <div class="risk-input">
              <div
                class="increase-decrease-button left"
                @click="updatePrematchMax('-')"
              >
                <Icon :name="'minus'" />
              </div>
              <NumberInput
                :model-value="marginPrematchMax"
                @update:modelValue="updatePrematchMarginMax"
                :max="100"
              />
              <div
                class="increase-decrease-button right"
                @click="updatePrematchMax('+')"
              >
                <Icon :name="'plus'" />
              </div>
            </div>
          </div>
          <div
            class="risk-configuration-group"
            v-if="sgpMarginConfiguration.type === MARGIN_TYPE.STEEPNESS_FLATNESS_IP_PM"
          >
            <div class="label">
              IN-PLAY MAX HOLD %
            </div>
            <div class="risk-input">
              <div
                class="increase-decrease-button left"
                @click="updateInPlayMax('-')"
              >
                <Icon :name="'minus'" />
              </div>
              <NumberInput
                :model-value="marginInPlayMax"
                @update:modelValue="updateInPlayMarginMax"
                :max="100"
              />
              <div
                class="increase-decrease-button right"
                @click="updateInPlayMax('+')"
              >
                <Icon :name="'plus'" />
              </div>
            </div>
          </div>
        </div>
        <div class="risk-configuration-group multiple">
          <div
            class="risk-configuration-group"
            v-if="sgpMarginConfiguration.type === MARGIN_TYPE.US_CENT_LOOKUP_IP_PM"
          >
            <div class="label">
              PREGAME
            </div>
            <div class="risk-input">
              <Dropdown
                :label="`${prematchCent} cent`"
                class="us-cent-dd"
              >
                <DropdownItem
                  clickable
                  @click="updatePrematchCent(20)"
                >
                  20 cent
                </DropdownItem>
                <DropdownItem
                  clickable
                  @click="updatePrematchCent(30)"
                >
                  30 cent
                </DropdownItem>
              </Dropdown>
            </div>
          </div>
          <div
            class="risk-configuration-group"
            v-if="sgpMarginConfiguration.type === MARGIN_TYPE.US_CENT_LOOKUP_IP_PM"
          >
            <div class="label">
              IN-PLAY
            </div>
            <div class="risk-input">
              <Dropdown
                :label="`${inPlayCent} cent`"
                class="us-cent-dd"
              >
                <DropdownItem
                  clickable
                  @click="updateInPlayCent(20)"
                >
                  20 cent
                </DropdownItem>
                <DropdownItem
                  clickable
                  @click="updateInPlayCent(30)"
                >
                  30 cent
                </DropdownItem>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { cloneDeep, isEqual } from 'lodash';
import Button from '@/components/common/Button';
import { calculateAfterOperationValue } from '@/services/helpers/market-tiers';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';
import NumberInput from '@/components/common/NumberInput';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';

const MARGIN_TYPE = {
  UNIFORM: 'UNIFORM',
  STEEPNESS_FLATNESS: 'STEEPNESS_FLATNESS',
  UNIFORM_IP_PM: 'UNIFORM_IN_PLAY_AND_PRE_MATCH',
  UNIFORM_IP_PM_ALT: 'UNIFORM_IN_PLAY_AND_PRE_MATCH_ALT',
  STEEPNESS_FLATNESS_IP_PM: 'STEEPNESS_FLATNESS_IN_PLAY_AND_PRE_MATCH',
  US_CENT_LOOKUP_IP_PM: 'US_CENT_LOOKUP_IN_PLAY_AND_PRE_MATCH',
};

export default {
  components: {
    Button,
    Dropdown,
    DropdownItem,
    NumberInput,
    Icon,
    Tooltip,
  },
  props: {
    sgpMarginConfigurationProp: {
      type: Object,
      required: true,
    },
  },
  emits: {
    submit: {
    },
  },
  setup(props, { emit }) {
    const sgpMarginConfiguration = ref(cloneDeep(props.sgpMarginConfigurationProp));
    const marginHold = ref(Number(parseFloat(((sgpMarginConfiguration.value?.totalBook || 1) - 1) * 100).toFixed(2)));
    const marginMax = ref(Number(parseFloat((sgpMarginConfiguration.value?.max || 0.04) * 100).toFixed(2)));

    const altStep = ref(Number(parseFloat((sgpMarginConfiguration.value?.altStep || 0) * 100).toFixed(2)));
    const altHold = ref(Number(parseFloat(((sgpMarginConfiguration.value?.maxAltTotalBook || 1) - 1) * 100).toFixed(2)));

    const marginPrematchHold = ref(Number(parseFloat(((sgpMarginConfiguration.value?.preMatchTotalBook || 1) - 1) * 100).toFixed(2)));
    const marginInPlayHold = ref(Number(parseFloat(((sgpMarginConfiguration.value?.inPlayTotalBook || 1) - 1) * 100).toFixed(2)));

    const marginPrematchMax = ref(Number(parseFloat((sgpMarginConfiguration.value?.preMatchMax || 0.04) * 100).toFixed(2)));
    const marginInPlayMax = ref(Number(parseFloat((sgpMarginConfiguration.value?.inPlayMax || 0.04) * 100).toFixed(2)));

    const prematchCent = ref(sgpMarginConfiguration.value?.preMatchCent || 20);
    const inPlayCent = ref(sgpMarginConfiguration.value?.inPlayCent || 20);

    const maxAltHoldError = computed(() => sgpMarginConfiguration.value?.type === MARGIN_TYPE.UNIFORM_IP_PM_ALT
      && (altHold.value <= marginInPlayHold.value || altHold.value <= marginPrematchHold.value));

    const updateSelectedRulesetMarginConfiguration = () => {
      if (sgpMarginConfiguration.value?.type === MARGIN_TYPE.UNIFORM) {
        sgpMarginConfiguration.value = {
          totalBook: (+marginHold.value / 100) + 1,
          type: MARGIN_TYPE.UNIFORM,
        };
      }
      if (sgpMarginConfiguration.value?.type === MARGIN_TYPE.UNIFORM_IP_PM) {
        sgpMarginConfiguration.value = {
          preMatchTotalBook: (+marginPrematchHold.value / 100) + 1,
          inPlayTotalBook: (+marginInPlayHold.value / 100) + 1,
          type: MARGIN_TYPE.UNIFORM_IP_PM,
        };
      }
      if (sgpMarginConfiguration.value?.type === MARGIN_TYPE.UNIFORM_IP_PM_ALT) {
        sgpMarginConfiguration.value = {
          preMatchTotalBook: (+marginPrematchHold.value / 100) + 1,
          inPlayTotalBook: (+marginInPlayHold.value / 100) + 1,
          altStep: +altStep.value / 100,
          maxAltTotalBook: (+altHold.value / 100) + 1,
          type: MARGIN_TYPE.UNIFORM_IP_PM_ALT,
        };
      }
      if (sgpMarginConfiguration.value?.type === MARGIN_TYPE.STEEPNESS_FLATNESS) {
        sgpMarginConfiguration.value = {
          steepness: 1.75,
          flatness: 4.75,
          min: 0.005,
          max: +marginMax.value / 100,
          type: MARGIN_TYPE.STEEPNESS_FLATNESS,
        };
      }
      if (sgpMarginConfiguration.value?.type === MARGIN_TYPE.STEEPNESS_FLATNESS_IP_PM) {
        sgpMarginConfiguration.value = {
          preMatchSteepness: 1.75,
          inPlaySteepness: 1.75,
          preMatchFlatness: 4.75,
          inPlayFlatness: 4.75,
          preMatchMin: 0.005,
          inPlayMin: 0.005,
          preMatchMax: +marginPrematchMax.value / 100,
          inPlayMax: +marginInPlayMax.value / 100,
          type: MARGIN_TYPE.STEEPNESS_FLATNESS_IP_PM,
        };
      }
      if (sgpMarginConfiguration.value?.type === MARGIN_TYPE.US_CENT_LOOKUP_IP_PM) {
        sgpMarginConfiguration.value = {
          preMatchCent: prematchCent.value,
          inPlayCent: inPlayCent.value,
          type: MARGIN_TYPE.US_CENT_LOOKUP_IP_PM,
        };
      }
    };

    const updateMarginHold = (inputValue) => {
      marginHold.value = inputValue;
      updateSelectedRulesetMarginConfiguration();
    };
    const updateMarginMax = (inputValue) => {
      marginMax.value = inputValue;
      updateSelectedRulesetMarginConfiguration();
    };
    const updatePrematchMarginHold = (inputValue) => {
      marginPrematchHold.value = inputValue;
      updateSelectedRulesetMarginConfiguration();
    };
    const updateInPlayMarginHold = (inputValue) => {
      marginInPlayHold.value = inputValue;
      updateSelectedRulesetMarginConfiguration();
    };
    const updatePrematchMarginMax = (inputValue) => {
      marginPrematchMax.value = inputValue;
      updateSelectedRulesetMarginConfiguration();
    };
    const updateInPlayMarginMax = (inputValue) => {
      marginInPlayMax.value = inputValue;
      updateSelectedRulesetMarginConfiguration();
    };

    const updatePrematchCent = (inputValue) => {
      prematchCent.value = inputValue;
      updateSelectedRulesetMarginConfiguration();
    };
    const updateInPlayCent = (inputValue) => {
      inPlayCent.value = inputValue;
      updateSelectedRulesetMarginConfiguration();
    };

    const updateHold = (operation) => {
      marginHold.value = calculateAfterOperationValue(operation, marginHold.value);
      updateSelectedRulesetMarginConfiguration();
    };

    const updateAltStepClick = (operation) => {
      altStep.value = calculateAfterOperationValue(operation, altStep.value);
      updateSelectedRulesetMarginConfiguration();
    };
    const updateAltStep = (inputValue) => {
      altStep.value = inputValue;
      updateSelectedRulesetMarginConfiguration();
    };

    const updateAltHoldClick = (operation) => {
      altHold.value = calculateAfterOperationValue(operation, altHold.value);
      updateSelectedRulesetMarginConfiguration();
    };
    const updateAltHold = (inputValue) => {
      altHold.value = inputValue;
      updateSelectedRulesetMarginConfiguration();
    };

    const updateMax = (operation) => {
      marginMax.value = calculateAfterOperationValue(operation, marginMax.value);
      updateSelectedRulesetMarginConfiguration();
    };

    const updatePrematchHold = (operation) => {
      marginPrematchHold.value = calculateAfterOperationValue(operation, marginPrematchHold.value);
      updateSelectedRulesetMarginConfiguration();
    };
    const updateInPlayHold = (operation) => {
      marginInPlayHold.value = calculateAfterOperationValue(operation, marginInPlayHold.value);
      updateSelectedRulesetMarginConfiguration();
    };

    const updatePrematchMax = (operation) => {
      marginPrematchMax.value = calculateAfterOperationValue(operation, marginPrematchMax.value);
      updateSelectedRulesetMarginConfiguration();
    };
    const updateInPlayMax = (operation) => {
      marginInPlayMax.value = calculateAfterOperationValue(operation, marginInPlayMax.value);
      updateSelectedRulesetMarginConfiguration();
    };

    const applyMarginType = (type) => {
      if (sgpMarginConfiguration.value?.type === type) return;
      sgpMarginConfiguration.value.type = type;
      updateSelectedRulesetMarginConfiguration();
    };

    const marginTypes = ref({
      [MARGIN_TYPE.UNIFORM]: {
        id: 1,
        label: 'Flat',
        type: MARGIN_TYPE.UNIFORM,
      },
      [MARGIN_TYPE.STEEPNESS_FLATNESS]: {
        id: 2,
        label: 'Hold curve',
        type: MARGIN_TYPE.STEEPNESS_FLATNESS,
      },
      [MARGIN_TYPE.UNIFORM_IP_PM]: {
        id: 3,
        label: 'Flat (pregame/in-play)',
        type: MARGIN_TYPE.UNIFORM_IP_PM,
      },
      [MARGIN_TYPE.UNIFORM_IP_PM_ALT]: {
        id: 4,
        label: 'Flat (pregame/in-play) - Alt',
        type: MARGIN_TYPE.UNIFORM_IP_PM_ALT,
      },
      [MARGIN_TYPE.STEEPNESS_FLATNESS_IP_PM]: {
        id: 5,
        label: 'Hold curve (pregame/in-play)',
        type: MARGIN_TYPE.STEEPNESS_FLATNESS_IP_PM,
      },
      [MARGIN_TYPE.US_CENT_LOOKUP_IP_PM]: {
        id: 6,
        label: 'US Cent Lookup',
        type: MARGIN_TYPE.US_CENT_LOOKUP_IP_PM,
      },
    });
    const isSavingDisabled = computed(() => isEqual(props.sgpMarginConfigurationProp, sgpMarginConfiguration.value));

    const submit = () => {
      emit('submit', sgpMarginConfiguration.value);
    };

    return {
      marginTypes,
      applyMarginType,
      marginHold,
      marginPrematchHold,
      marginInPlayHold,
      updateMarginHold,
      updatePrematchMarginHold,
      updateInPlayMarginHold,
      updateHold,
      updatePrematchHold,
      updateInPlayHold,
      updateMax,
      updatePrematchMax,
      updateInPlayMax,
      marginMax,
      marginPrematchMax,
      marginInPlayMax,
      updateMarginMax,
      updatePrematchMarginMax,
      updateInPlayMarginMax,
      MARGIN_TYPE,
      prematchCent,
      inPlayCent,
      updatePrematchCent,
      updateInPlayCent,
      sgpMarginConfiguration,
      isSavingDisabled,
      submit,
      updateAltStepClick,
      updateAltStep,
      altStep,
      updateAltHoldClick,
      updateAltHold,
      altHold,
      maxAltHoldError,
    };
  },
};
</script>

<style lang="scss">
.sgp-margin-configuration {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #DDD;
  border-radius: 4px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 16px;

    &-title {
      display: flex;
      align-items: center;
      gap: 4px;
      font-family: 'Poppins', sans-serif;
      font-size: 15.72px;
      font-weight: 600;
      line-height: 23.58px;
      color: #191414;

      .tooltip {
        font-family: 'Rubik', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.22px;
      }

      .icon {
        svg {
          g {
            path {
              stroke: #CDCDCD;
            }
          }
        }
      }
    }
  }

  &__content {
    padding: 0 16px 16px;
  }

  &__nav {
    &-list {
      display: flex;
      margin-bottom: 16px;
      border-bottom: 2px solid #F0F0F0;
    }

    &-item {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      font-size: 14px;
      line-height: 16px;
      border-bottom: 2px solid #F0F0F0;
      margin-bottom: -2px;
      min-width: 45px;
      cursor: pointer;

      &--is-active {
        border-bottom: 2px solid #003C3C;
      }
    }
  }

  &__tab-panel {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__text {
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    line-height: 16px;
    color: #191414;
  }

  &__grid {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__parameter {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    overflow: hidden;
    padding: 0 2px;

    &-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.risk-configuration-section-sgp {
  width: 100%;
  display: flex;
  flex-direction: column;

  .risk-configuration-group {
    display: flex;
    flex-direction: column;
    margin-right: 16px;

    &.has-margin {
      margin-bottom: 8px;
    }

    &.multiple {
      flex-direction: row;
      margin-bottom: 4px;
    }

    .label {
      color: #A9A9A9;
      font-size: 10px;
      font-weight: 600;
      margin-bottom: 4px;
      display: flex;

      .tooltip {
        height: 100%;
        margin-left: 8px;

        .tooltip-element {
          height: 14px;
          width: 14px;
          display: flex;

          .icon {
            height: 14px;
            width: 14px;
          }
        }

        .tooltip-text {
          padding: 8px;
          white-space: unset;
          height: unset;
          width: 244px;
          font-weight: 400;
        }
      }

      &.error {
        .tooltip {
          .tooltip-element {
            .icon {
              height: 14px;
              width: 14px;
              svg {
                path {
                  stroke: #FF2E2D;
                }
              }
            }
          }
        }
      }
    }

    .risk-dropdown {
      width: 220px;

      .dropdown {
        z-index: unset;

        .dropdown-button {
          border-color: #DDDDDD;
        }
      }

      .dropdown-button {
        width: 100%;
        justify-content: space-between;
      }
    }

    .risk-input {
      display: flex;

      .increase-decrease-button {
        height: 33px;
        width: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #DDDDDD;
        cursor: pointer;

        &.left {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        &.right {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        svg {
          path {
            stroke: #191414;
          }
        }
      }

      .table-number-input {
        width: 94px;

        input {
          text-align: center;
          border-color: #DDDDDD;
          height: 33px;
          border-right: 0;
          border-left: 0;
          border-radius: 0;
        }
      }
      .us-cent-dd {
        width: 120px;
      }
    }
  }
}
</style>
