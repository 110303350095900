<template>
  <div
    class="team-params-items-root"
  >
    <div
      v-if="teamParams.teamA.params.length"
      class="team-name-header"
    >
      {{ selectedTeam === 'both' ? teamParams.teamA.name : 'Team params' }}
    </div>
    <GameParams
      v-if="selectedTeam === 'teamA' || selectedTeam === 'both'"
      class="team-params-items"
      :global-params="teamParams.teamA.params"
      :is-tooltip-right="true"
      :is-calculated-mode="isCalculatedMode"
      @onParamChange="onTeamParamChange"
      @onParamIncrease="onTeamParamIncrease"
      @onParamDecrease="onTeamParamDecrease"
    />
    <div
      v-if="selectedTeam === 'both' && teamParams.teamB.params.length"
      class="team-name-header"
    >
      {{ teamParams.teamB.name }}
    </div>
    <GameParams
      v-if="selectedTeam === 'teamB' || selectedTeam === 'both'"
      class="team-params-items"
      :global-params="teamParams.teamB.params"
      :is-tooltip-right="true"
      :is-calculated-mode="isCalculatedMode"
      @onParamChange="onTeamParamChange"
      @onParamIncrease="onTeamParamIncrease"
      @onParamDecrease="onTeamParamDecrease"
    />
    <div
      v-if="showNoParamsMessage"
      class="no-params-message"
    >
      {{ searchActive ? 'Searched parameters not found.' : 'Currently, there are no parameters.' }}
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import GameParams from './GameParams';

export default {
  emits: [
    'onParamChange',
    'onParamIncrease',
    'onParamDecrease',
  ],
  components: {
    GameParams,
  },
  props: {
    teamParams: {
      type: Object,
      required: true,
    },
    selectedTeam: {
      type: String,
      required: false,
      default: '',
    },
    searchActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCalculatedMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const params = computed(() => props.globalParams);
    const showNoParamsMessage = computed(() => {
      if (
        props.selectedTeam === 'both'
        && !props.teamParams?.teamA?.params?.length
        && !props.teamParams?.teamB?.params?.length
      ) return true;
      if (
        props.selectedTeam === 'teamA'
        && !props.teamParams?.teamA?.params?.length
      ) return true;
      if (
        props.selectedTeam === 'teamB'
        && !props.teamParams?.teamB?.params?.length
      ) return true;

      return false;
    });
    const onTeamParamChange = (value, param) => emit('onParamChange', value, param);
    const onTeamParamIncrease = (param) => emit('onParamIncrease', param);
    const onTeamParamDecrease = (param) => emit('onParamDecrease', param);

    return {
      params,
      showNoParamsMessage,
      onTeamParamChange,
      onTeamParamIncrease,
      onTeamParamDecrease,
    };
  },
};
</script>

<style lang="scss">
.team-params-items-root {
  .team-name-header {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 16px;
    background-color: $gray300;
    padding: 12px 8px;
  }
  .team-params-items {
    margin-bottom: 28px;
  }
  .no-params-message {
    padding: 50px 0;
    text-align: center;
  }
}
</style>
