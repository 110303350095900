<template>
  <transition name="slide-left">
    <div
      v-if="eventId"
      class="event-details"
    >
      <div
        :class="[
          'event-details__header',
          { 'event-details__header--is-expanded': isHeaderExpanded },
        ]"
      >
        <div class="event-details__header-base">
          <Spinner v-if="eventLoading" />

          <div
            v-show="!eventLoading"
            class="event-details__header-meta"
          >
            <div class="event-details__header-subtitle">
              {{ headerSubtitle }}
            </div>

            <div class="event-details__header-title">
              <Icon
                v-if="event?.isReplay"
                name="repeat-fourth"
                class="repeat-icon"
              />
              {{ headerTitle }}
            </div>
          </div>

          <ButtonGroup
            class="event-details__header-actions"
            small
          >
            <Button
              v-show="!eventLoading && eventLoaded"
              variant="tertiary"
              :icon="isHeaderExpanded ? 'chevron-up' : 'chevron-down'"
              icon-only
              @click="toggleHeaderExpansion"
            />

            <Button
              variant="tertiary"
              icon="x"
              icon-only
              @click="closeEvent"
            />
          </ButtonGroup>
        </div>

        <div
          v-show="isHeaderExpanded"
        >
          <div class="event-details__header-extras">
            <div class="event-details__header-column">
              <div class="event-details__header-input">
                <span class="event-details__header-input-label">
                  Event ID:
                </span>

                <ActionInput
                  class="event-details__header-input-field"
                  icon="copy"
                  message="Event ID copied"
                  :value="eventId"
                  @click="copyEventId"
                />
              </div>

              <div class="event-details__header-input">
                <span class="event-details__header-input-label">
                  Market template:
                </span>

                <ActionInput
                  class="event-details__header-input-field"
                  icon="external"
                  :value="event?.sportTemplate?.name"
                  @click="goToSportTemplate"
                />
              </div>
            </div>

            <div class="event-details__header-indicator-column">
              <div
                :class="[
                  'event-details__prices-indicator',
                  { 'event-details__prices-indicator--internal': hasInternalPrices },
                  { 'event-details__prices-indicator--external': hasExternalPrices },
                ]"
              >
                <div class="icon-wrapper">
                  <Icon
                    v-if="hasPrices"
                    name="check"
                  />
                </div>

                {{ hasPricesLabel }}
              </div>

              <div
                :class="[
                  'event-details__mapping-indicator',
                  { 'event-details__mapping-indicator--not-mapped': !isMapped },
                ]"
              >
                <Icon :name="isMappedIcon" />
                {{ isMappedLabel }}
              </div>
            </div>
          </div>
          <div class="event-details__highlight">
            <Toggle
              :model-value="isEventHighlighted"
              @update:model-value="value => { eventHighlighted(value) }"
            />
            <p>Highlight Event</p>
          </div>
        </div>
      </div>

      <div
        ref="scrollbar"
        class="event-details__wrapper"
        @scroll="onScroll"
      >
        <Spinner v-if="eventLoading" />
        <div
          v-else-if="!eventLoaded"
          class="event-detials__content-404"
        >
          No event found under this ID
        </div>

        <div
          v-else
          ref="content"
          class="event-details__content"
        >
          <Scoreboard />
          <PricingDetails :sport-id="event.sportId" />
        </div>
      </div>

      <div class="event-details__footer">
        <Spinner v-if="eventLoading" />

        <div
          v-else-if="eventLoaded && !isBooked && !event.limbo"
          class="event-details__footer-booking"
        >
          <Button
            variant="primary"
            large
            :loading="processingBooking"
            @click="bookEvent"
          >
            Book event
          </Button>
        </div>

        <div
          v-else-if="eventLoaded"
          class="event-details__footer-suspension"
        >
          <div class="left-side">
            <Button
              variant="secondary"
              :icon="'external'"
              class="audit-log-button"
              @click="openAuditLog"
              v-if="auditLogEnabled"
            >
              Visit Audit log
            </Button>
            <div class="event-details__booked-indicator">
              <Icon name="check" />
              Event booked
            </div>
            <EventDisabledIndicator
              v-if="isEventDisabled"
            />
            <EventHiddenIndicator
              v-else-if="isEventHidden"
            />
          </div>
          <div
            v-if="isBooked"
            class="event-suspension"
          >
            <Button
              v-if="!eventSuspended"
              variant="danger"
              :loading="isSuspendEventActionLoading"
              @click="toggleEventSuspend(true)"
            >
              Suspend event
            </Button>
            <Button
              v-else
              variant="danger-secondary"
              :loading="isSuspendEventActionLoading"
              @click="toggleEventSuspend(false)"
            >
              Unsuspend event
            </Button>
          </div>
        </div>
      </div>

      <div
        class="event-details__scroll-to-top"
        @click="scrollToTop"
        v-show="scrollY > 24"
      >
        <Icon
          class="event-details__scroll-to-top-icon"
          name="chevron-up"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import {
  split, reverse, join, max, throttle,
} from 'lodash';
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useResizeObserver } from '@vueuse/core';
import { useOnlineStatus } from '@/composables';
import * as api from '@/services/api';
import Spinner from '@/components/common/Spinner';
import ButtonGroup from '@/components/common/ButtonGroup';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import ActionInput from '@/components/common/ActionInput';
import Toggle from '@/components/common/Toggle';
import { getSuperAdminData } from '@/services/helpers/super-admin';
import Scoreboard from './Scoreboard';
import PricingDetails from './PricingDetails';
import EventDisabledIndicator from './EventDisabledIndicator';
import EventHiddenIndicator from './EventHiddenIndicator';

export default {
  components: {
    Spinner,
    ButtonGroup,
    Button,
    Icon,
    ActionInput,
    Scoreboard,
    PricingDetails,
    Toggle,
    EventDisabledIndicator,
    EventHiddenIndicator,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const eventId = computed(() => route.query.event || '');

    const store = useStore();
    const event = computed(() => store.getters.event);
    const isEventDisabled = computed(() => event.value?.limbo);
    const eventLoading = computed(() => store.getters.eventLoading);
    const eventLoaded = computed(() => store.getters.eventLoaded);
    const hasMarkets = computed(() => store.getters.eventMarkets?.length);
    const eventSuspended = computed(() => event.value.isSuspended);
    const isSuspendEventActionLoading = computed(() => store.getters.suspendEventActionLoading(event.value.id));
    const isHeaderExpanded = ref(false);
    const isEventHighlighted = computed(() => event.value?.isHighlighted);
    const isEventHidden = computed(() => event.value?.isHidden);
    const { isSuperAdmin } = getSuperAdminData();
    const auditLogEnabled = computed(() => isSuperAdmin);
    const toggleHeaderExpansion = () => {
      isHeaderExpanded.value = !isHeaderExpanded.value;
    };
    const eventHighlighted = (isHighlighted) => {
      store.dispatch('toggleEventHighlight', {
        eventId: event.value.id,
        isHighlighted,
      });
    };
    const headerTitle = computed(() => {
      if (eventLoading.value) return '';
      if (!event.value) return '404 Not Found';
      return (event.value.isUSAView ? join(reverse(split(event.value.name, ' v ')), ' @ ') : event.value.name);
    });
    const headerSubtitle = computed(() => {
      if (eventLoading.value || !eventLoaded.value) return '';
      return [
        event.value.sport?.name || 'Unspecified Sport',
        event.value.region?.name || 'Unspecified Region',
        event.value.competition?.name || 'Unspecified Competition',
      ].join(' • ');
    });
    const copyEventId = () => {
      if (!navigator?.clipboard) return;
      navigator.clipboard.writeText(eventId.value);
    };
    const goToSportTemplate = () => {
      const { href } = router.resolve({
        name: 'sport-template',
        params: {
          id: event.value.sportTemplate.id,
        },
      });
      window.open(href, '_blank');
    };
    const isMapped = computed(() => eventLoaded.value && event.value.isMapped);
    const isMappedIcon = computed(() => (eventLoaded.value && event.value.isMapped ? 'link-not-broken' : 'link-broken'));
    const isMappedLabel = computed(() => (eventLoaded.value && event.value.isMapped ? 'Mapped' : 'Not mapped'));
    const hasInternalPrices = computed(() => eventLoaded.value && event.value.hasInternalPrices);
    const hasExternalPrices = computed(() => eventLoaded.value && event.value.hasExternalPrices);
    const hasPrices = computed(() => hasInternalPrices.value || hasExternalPrices.value);
    const hasPricesLabel = computed(() => {
      if (hasInternalPrices.value) return 'Huddle has prices';
      if (hasExternalPrices.value) return 'Market has prices';
      return 'No prices';
    });
    const isBooked = computed(() => event.value?.isBooked);
    const processingBooking = ref(false);
    const bookEvent = () => {
      processingBooking.value = true;
      api.bookEvent({ eventId: eventId.value })
        .then(() => {
          store.dispatch('loadEvents');
          store.dispatch('reloadEvent');
          store.dispatch('addNotification', {
            message: 'Successfully booked event(s)',
            type: 'success',
            duration: 5000,
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          processingBooking.value = false;
        });
    };

    const closeEvent = () => {
      const { event: eventParam, ...query } = route.query || {};
      router.push({
        name: route.name,
        query,
      });
    };

    const toggleEventSuspend = (isSuspended) => {
      store.dispatch('toggleEventSuspend', { eventId: event.value.id, isSuspended });
    };

    watch(
      eventId,
      (newValue) => {
        if (newValue) {
          store.dispatch('loadEvent', newValue);
        } else {
          store.dispatch('unloadEvent', eventId);
        }

        isHeaderExpanded.value = false;
      },
      { immediate: true },
    );

    const content = ref(null);
    const scrollbar = ref(null);
    const scrollY = ref(0);
    const onScroll = throttle((e) => {
      scrollY.value = e.target.scrollTop;
      if (scrollY.value > 0) isHeaderExpanded.value = false;
    }, 100);
    useResizeObserver(content, (entries) => {
      if (!content.value) return;
      const { scrollTop, offsetHeight } = scrollbar.value;
      const bottom = entries[0].contentRect.bottom - 80;
      if (scrollTop > bottom) {
        scrollbar.value.scrollTop = max([0, bottom - offsetHeight]);
      }
    });
    const scrollToTop = () => {
      scrollbar.value.scrollTop = 0;
    };

    const openAuditLog = () => {
      const routeOptions = {
        name: 'audit-log',
        params: {
          eventId: eventId.value,
        },
      };

      router.push(routeOptions);
    };

    useOnlineStatus({
      onReconnect() {
        store.dispatch('reloadEvent');
      },
    });

    return {
      eventId,
      event,
      eventLoading,
      eventLoaded,
      isHeaderExpanded,
      toggleHeaderExpansion,
      headerTitle,
      headerSubtitle,
      copyEventId,
      goToSportTemplate,
      isMapped,
      isMappedIcon,
      isMappedLabel,
      hasInternalPrices,
      hasExternalPrices,
      hasPrices,
      hasPricesLabel,
      isBooked,
      processingBooking,
      bookEvent,
      closeEvent,
      content,
      scrollbar,
      scrollY,
      scrollToTop,
      onScroll,
      hasMarkets,
      eventSuspended,
      toggleEventSuspend,
      isSuspendEventActionLoading,
      isEventHighlighted,
      eventHighlighted,
      openAuditLog,
      auditLogEnabled,
      isEventDisabled,
      isEventHidden,
    };
  },
};
</script>

<style lang="scss">
.event-details {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  width: 660px;
  height: 100%;
  background: #FAFAFA;
  border-left: 2px solid #F0F0F0;
  z-index: $eventDetailsZIndex;

  .event-detials__content-404 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 38px;
    color: #6D6D6D;
  }

  .event-details__header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    padding-left: 16px;
    padding-right: 12px;
    height: 81px;
    box-shadow: inset 0px -1px 0px #F0F0F0;
    position: relative;
    z-index: 98;

    .event-details__header-base {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .event-details__header-meta {
        padding-right: 8px;
      }

      .event-details__header-title {
        display: flex;
        align-items: center;
        font-family: Poppins;
        font-weight: 600;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
        font-size: 15.72px;
        line-height: 23.58px;

        & .repeat-icon {
          margin-right: 6px;
        }
      }

      .event-details__header-subtitle {
        font-family: Rubik;
        font-size: 12px;
        color: #A9A9A9;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
      }

      .event-details__header-meta {
        width: calc(100% - 85px);
      }

      .event-details__header-actions {
        stroke: #000;
        width: 85px;
        justify-content: flex-end;
      }
    }

    .event-details__header-extras {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .event-details__highlight {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 8px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #003C3C;
      border-radius: 4px;
      border: 1px solid #003C3C;
    }

    &.event-details__header--is-expanded {
      .event-details__header-base {
        box-shadow: inset 0px -1px 0px #F0F0F0;
        padding-bottom: 8px;
      }
    }

    &.event-details__header--is-expanded {
      height: 266px;
      justify-content: flex-start;
      padding-top: 20px;

      .event-details__header-extras {
        display: flex;

        .event-details__header-column {
          flex: 1;

          .event-details__header-input {
            display: inline-flex;
            align-items: center;

            &:not(:last-of-type) {
              margin-bottom: 12px;
            }

            .event-details__header-input-label {
              font-family: Rubik;
              font-size: 14px;
              color: #6D6D6D;
              width: 120px;
            }

            .event-details__header-input-field {
              width: 235px;
            }
          }

          .event-details__header-input_empty {
            height: 44px;
          }
        }

        .event-details__header-indicator-column {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 25px;
          padding-right: 6px;

          .event-details__header-indicator {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 8px;
            width: 100%;

            .icon {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }

  .event-details__wrapper {
    flex: 1;
    height: calc(100% - 81px - 84px);
    overflow-y: auto;
  }

  .event-details__content {
    padding: 8px;
  }

  .event-details__scroll-to-top {
    position: absolute;
    bottom: calc(84px + 16px);
    left: 29px;
    background: #003C3C;
    border-radius: 100px;
    width: 33px;
    height: 33px;
    stroke: #fff;
    padding: 6px;
    cursor: pointer;
  }

  .event-details__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 0 16px;
    height: 84px;
    box-shadow: inset 0px 1px 0px #F0F0F0;

    .event-details__footer-booking {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .event-details__footer-suspension {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left-side {
        display: flex;
        align-items: center;
        gap: 8px;

        .audit-log-button {
          .icon svg path {
            stroke: $black;
          }
        }
      }
    }
  }

  .event-details__mapping-indicator,
  .event-details__prices-indicator,
  .event-details__booked-indicator {
    display: flex;
    align-items: center;
    text-align: right;
    gap: 8px;
    width: 100%;
    stroke: #191414;
    color: #191414;
    text-align: right;
    font-size: 14px;
    white-space: nowrap;

    .icon {
      width: 16px;
      height: 16px;
    }
  }

  .event-details__mapping-indicator {
    &.event-details__mapping-indicator--not-mapped {
      stroke: #f00;
    }
  }

  .event-details__prices-indicator {
    .icon-wrapper {
      width: 16px;
      height: 16px;
      border: 2px solid #F0F0F0;
      border-radius: 100%;
      stroke: $white;

      .icon {
        width: 0.75rem;
        height: 0.75rem;
      }
    }

    &.event-details__prices-indicator--external {
      .icon-wrapper {
        background-color: #F0F0F0;
        border-color: #F0F0F0;
      }
    }

    &.event-details__prices-indicator--internal {
      .icon-wrapper {
        background-color: #003C3C;
        border-color: #003C3C;
      }
    }
  }

  .event-details__booked-indicator {
    flex: 0.5;
    color: #6D6D6D;
    stroke: #6D6D6D;
    font-size: 14px;
    justify-content: flex-start;
  }
}
</style>
