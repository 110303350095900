<template>
  <div
    :class="[
      'competition-configuration',
      { 'competition-configuration--has-sidebar': isExpanded },
    ]"
  >
    <CompetitionConfigurationSidebar
      class="competition-configuration__sidebar"
      :model-value="sportId"
      :options="sports"
      :expanded="isExpanded"
      @update:model-value="setSportId"
      @update:expanded="setExpanded"
    />
    <div class="competition-configuration__page">
      <div class="competition-configuration__page-header">
        <Heading
          as="h1"
          size="md"
        >
          {{ pageTitle }}
        </Heading>
        <div class="page__header-right-side">
          <CreateCompetitionControl
            :competition-to-update="competitionToUpdate"
            :number-of-feeds="feeds.length"
            @onModalClose="updateCompetition(null)"
            @onSubmit="reloadCompetitions"
          />
          <CompetitionConfigurationSearch
            v-model="search"
          />
        </div>
      </div>
      <div class="competition-configuration__page-content">
        <CompetitionConfigurationTable
          :feeds="feeds"
          :competitions="competitions"
          :configurations="configurations"
          v-model:sort="order"
          @update:configurations="updateConfigurations"
          @update:competition="updateCompetition"
          @crudOperationCompleted="reloadCompetitions"
          @update:competitionFeedPriority="updateCompetitionFeedPriority"
        />
      </div>
      <CompetitionConfigurationPagination
        class="competition-configuration__page-footer"
        v-model:page="page"
        v-model:per-page="perPage"
        :total-count="totalCount"
      />
      <div
        v-if="showSpinner"
        class="competition-configuration__page-loader"
      >
        <Spinner />
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
  computed,
  watch,
  onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import {
  find,
  pickBy,
  identity,
  difference,
  values,
  cloneDeep,
  map,
} from 'lodash';
import { updateCompetitionFeedConfiguration } from '@/services/api';
import {
  loadSports,
  loadFeeds,
  loadCompetitions,
} from '@/services/helpers/competition-configuration';
import Heading from '@/components/common/Heading';
import Spinner from '@/components/common/Spinner';
import CompetitionConfigurationSidebar from './CompetitionConfigurationSidebar';
import CompetitionConfigurationSearch from './CompetitionConfigurationSearch';
import CompetitionConfigurationTable from './CompetitionConfigurationTable';
import CompetitionConfigurationPagination from './CompetitionConfigurationPagination';
import CreateCompetitionControl from './CreateCompetitionControl';

export default {
  components: {
    Heading,
    Spinner,
    CompetitionConfigurationSidebar,
    CompetitionConfigurationSearch,
    CompetitionConfigurationTable,
    CompetitionConfigurationPagination,
    CreateCompetitionControl,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const initialized = ref(false);
    const loading = ref(false);
    const sports = ref([]);
    const feeds = ref([]);
    const competitions = ref([]);
    const configurations = ref({});

    const page = ref(1);
    const perPage = ref(50);
    const totalCount = ref(0);
    const search = ref('');
    const order = ref('');
    const sportId = computed(() => route.query.sport);
    const pageTitle = computed(() => {
      const sport = find(sports.value, { id: sportId.value });
      return sport?.name || 'Competition Configuration';
    });
    const showSpinner = computed(() => !initialized.value || loading.value);

    const setSportId = (newSportId) => {
      router.push({
        name: route.name,
        query: pickBy({
          ...route.query,
          sport: newSportId,
        }, identity),
      });
    };
    const updateConfigurations = (updatedConfigurations) => {
      const oldConfigurations = cloneDeep(configurations.value);
      configurations.value = updatedConfigurations;

      const changedConfigurations = difference(values(updatedConfigurations), values(configurations.value));
      const payloads = changedConfigurations.map(({ competitionName, ...configuration }) => configuration);
      updateCompetitionFeedConfiguration(payloads)
        .then(() => {
          store.dispatch('addNotification', {
            message: 'Competition configuration(s) were successfully updated',
            type: 'success',
            duration: 5000,
          });
        })
        .catch((error) => {
          console.error(error);
          store.dispatch('addNotification', {
            message: 'Competition configuration(s) could not be updated',
            type: 'error',
            duration: 5000,
          });
          configurations.value = oldConfigurations;
        });
    };

    const updateCompetitionFeedPriority = ({
      competitionId,
      feed,
      priority,
    }) => {
      const competition = find(competitions.value, { competitionId });
      if (!competition) return;

      const updatedCompetition = {
        ...competition,
        feedConfiguration: {
          ...competition.feedConfiguration,
          nodes: map(
            competition.feedConfiguration.nodes,
            (feedConfig) => {
              if (feedConfig.feed === feed) {
                return {
                  ...feedConfig,
                  priority: parseInt(priority, 10),
                };
              }
              return feedConfig;
            },
          ),
        },
      };

      competitions.value = map(
        competitions.value,
        (comp) => (comp.competitionId === competitionId ? updatedCompetition : comp),
      );
    };

    const isExpanded = computed(() => store.getters.sidebarExpanded);
    const setExpanded = (newIsExpanded) => {
      store.dispatch('setSidebarExpanded', newIsExpanded);
    };

    const reloadSports = async () => {
      sports.value = await loadSports();
      const foundSport = find(sports.value, { id: sportId.value });
      if (!foundSport) setSportId(sports.value?.[0]?.id || '');
    };
    const reloadFeeds = async () => {
      if (!sportId.value) return;
      feeds.value = await loadFeeds(sportId.value);
    };
    const reloadCompetitions = async () => {
      if (!sportId.value) return;
      competitions.value = [];
      loading.value = true;
      const payload = await loadCompetitions({
        sportId: sportId.value,
        search: search.value,
        order: order.value,
        offset: (page.value - 1) * perPage.value,
        first: perPage.value,
      });
      totalCount.value = payload.totalCompetitionsCount;
      competitions.value = payload.competitions;
      configurations.value = payload.configurations;
      loading.value = false;
    };
    const initialize = async () => {
      try {
        initialize.value = false;
        loading.value = true;
        await reloadSports();
        await reloadFeeds();
        await reloadCompetitions();
        initialized.value = true;
      } catch (error) {
        console.error(error);
      } finally {
        loading.value = false;
      }
    };

    const competitionToUpdate = ref(null);
    const updateCompetition = (competition) => {
      competitionToUpdate.value = competition;
    };

    onMounted(initialize);
    watch(sportId, (newSportId) => {
      if (!initialized.value) return;

      const foundSport = find(sports.value, { id: newSportId || '' });
      if (!foundSport) setSportId(sports.value?.[0]?.id || '');

      page.value = 1;
      totalCount.value = 0;
      search.value = '';
      order.value = '';
      competitions.value = [];
      configurations.value = {};

      loading.value = true;
      Promise.all([
        reloadFeeds(),
        reloadCompetitions(),
      ])
        .finally(() => {
          loading.value = false;
        });
    });
    watch(
      [search, order, perPage, page],
      (
        [newSearch, newOrder, newPerPage],
        [oldSearch, oldOrder, oldPerPage],
      ) => {
        if (!initialized.value) return;
        if (newSearch !== oldSearch || newOrder !== oldOrder || newPerPage !== oldPerPage) {
          page.value = 1;
        }
        if (newSearch !== oldSearch) {
          totalCount.value = 0;
        }
        loading.value = true;
        reloadCompetitions()
          .finally(() => {
            loading.value = false;
          });
      },
    );

    return {
      initialized,
      loading,
      sports,
      feeds,
      competitions,
      configurations,
      search,
      page,
      perPage,
      order,
      totalCount,
      sportId,
      pageTitle,
      showSpinner,
      setSportId,
      updateConfigurations,
      isExpanded,
      setExpanded,
      reloadCompetitions,
      competitionToUpdate,
      updateCompetition,
      updateCompetitionFeedPriority,
    };
  },
};
</script>

<style lang="scss">
.competition-configuration {
  display: flex;

  &--has-sidebar {
    .competition-configuration__page {
      width: calc(100% - $sidebarWidth);
    }
  }

  &__page {
    display: flex;
    flex-direction: column;
    width: calc(100% - $sidebarWidthCollapsed);
    height: 100%;
    position: relative;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 8px 16px;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 90;
      background: #fff;
      height: 48px;
      min-height: 48px;
      max-height: 40px;
    }
    .page__header-right-side {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    &-content {
      display: grid;
      width: 100%;
      height: calc(100% - 48px - 64px);
      padding: 0 16px;
      overflow: auto;
    }

    &-loader {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
