import {
  filter,
  orderBy,
  map,
  find,
} from 'lodash';
import qs from 'query-string';
import {
  filterSupportedBetHighlights,
  mapMatchedHighlights,
  mapClashingHighlights,
} from '@/services/helpers/highlights';
import router from '@/router';
import base64 from '@/utils/base64';

export default {
  betTickerList: (state, getters, rootState, rootGetters) => mapMatchedHighlights(state.betTickerList, {
    rowHighlights: getters.betTickerRowHighlights,
    cellHighlights: getters.betTickerCellHighlights,
    oddsFormat: rootGetters.selectedOddFormat.name,
  }),
  betTickerListLoading: (state) => state.betTickerListLoading,
  betTickerListSearch: (state) => state.betTickerListSearch,
  activeSidebarOption: (state) => state.activeSidebarOption,
  selectedSearchField: (state) => state.selectedSearchField,
  betTickerConfigurations: (state) => state.betTickerConfigurations,
  betTickerHighlights: (state) => {
    const betTickerConfiguration = find(state.betTickerConfigurations, { betTickerId: state.selectedBetTicker?.betTickerId });
    if (!betTickerConfiguration) return [];
    const supportedHighlights = filterSupportedBetHighlights(betTickerConfiguration.highlights || []);
    const mappedHighlights = mapClashingHighlights(map(supportedHighlights, (highlight, index) => ({ ...highlight, index })));
    const indexedHighlights = map(mappedHighlights, (highlight, index) => ({ ...highlight, index }));
    return orderBy(indexedHighlights, 'index');
  },
  betTickerRowHighlights: (state, getters) => filter(getters.betTickerHighlights, { highlightType: 'ROW' }),
  betTickerCellHighlights: (state, getters) => filter(getters.betTickerHighlights, { highlightType: 'CELL' }),
  selectedBetTicker: (state) => state.selectedBetTicker,
  marketDisplayConfigurationByIds: (state) => ({ sportId, marketCode, selections }) => state.allSportsMarketDisplayConfigurations[`${sportId}_${marketCode}_${selections}`] || null,
  showDisabledTickers: (state) => state.showDisabledTickers,
  onlyFlaggedBets: (state) => state.onlyFlaggedBets,

  liabilityEvents: (state) => state.liabilityEvents?.events || [],
  liabilityEventsRowCount: (state) => state.liabilityEvents?.totalCount || 0,
  liabilityEventsLoading: (state) => state.liabilityEventsLoading,
  liabilitySports: (state) => state.liabilityFiltersMeta.sports,
  liabilityCompetitions: (state) => state.liabilityFiltersMeta.competitions,
  liabilityEvent: (state) => state.liabilityEvent,
  liabilityEventLoading: (state) => state.liabilityEventLoading,
  displayMarketsConfiguration: (state) => state.liabilityEventMarketsDisplayConfiguration || null,
  getSingleMarketDisplayConfiguration: (state) => (marketCode, selections) => state.liabilityEventMarketsDisplayConfiguration[`${marketCode}_${selections}`] || null,
  liabilityEventMarkets: (state) => state.liabilityMarketsObject,
  getLiabilityMarketById: (state) => (marketId) => state.liabilityMarketsObject[marketId],
  getLiabilityMarketSelectionsById: (state) => (marketId) => state.liabilityMarketSelectionsObject[marketId],
  marketGroupsBySportId: (state) => (sportId) => filter(state.marketGroups,
    (group) => sportId === group.sportId && group.active),

  allCustomersIsInitializing: (state) => state.allCustomers.isInitializing,
  allCustomersIsLoading: (state) => state.allCustomers.isLoading,
  allCustomersInitialized: (state) => state.allCustomers.initialized,
  allCustomersFilters: (state) => state.allCustomers.filters,
  allCustomersPage: (state) => state.allCustomers.page,
  allCustomersLimit: (state) => state.allCustomers.limit,
  allCustomersColumns: (state) => state.allCustomers.columns,
  allCustomersTotalCount: (state) => state.allCustomers.totalCount,
  allCustomersData: (state) => state.allCustomers.data,
  allCustomersSidebar: (state) => state.allCustomers.sidebar,
  customerIsInitializing: (state) => state.customer.isInitializing,
  customerIsLoading: (state) => state.customer.isLoading,
  customerKey: (state) => state.customer.key,
  customerData: (state) => state.customer.data,
  customerSidebar: (state) => state.customer.sidebar,
  customerEditNickname: (state) => state.customer.editNickname,
  customerBetsIsLoading: (state) => state.customer.bets.isLoading,
  customerBetsFilters: (state) => state.customer.bets.filters,
  customerBetsPage: (state) => state.customer.bets.page,
  customerBetsLimit: (state) => state.customer.bets.limit,
  customerBetsColumns: (state) => state.customer.bets.columns,
  customerBetsTotalCount: (state) => state.customer.bets.totalCount,
  customerBetsData: (state) => state.customer.bets.data,
  customerNotesIsInitializing: (state) => state.customer.notes.isInitializing,
  customerNotesIsLoading: (state) => state.customer.notes.isLoading,
  customerNotesOrderBy: (state) => state.customer.notes.orderBy,
  customerNotesFirst: (state) => state.customer.notes.first,
  customerNotesTotalCount: (state) => state.customer.notes.totalCount,
  customerNotesData: (state) => state.customer.notes.data,
  customerNotesHasMore: (state, getters) => getters.customerNotesData.length < getters.customerNotesTotalCount,
  customerNotesThreadTotalCount: (commentId) => (state) => find(state.customer.notes.data, { id: commentId })?.replies?.totalCount || 0,
  customerNotesThreadData: (commentId) => (state) => find(state.customer.notes.data, { id: commentId })?.replies?.data || [],
  customerListAppliedSort: () => {
    const path = router.currentRoute.value.fullPath;
    const parsedUrl = qs.parseUrl(path);
    let appliedSort = parsedUrl.query?.sort;
    if (!appliedSort) {
      return {
        field: 'firstPlacedBet',
        order: 'DESC',
      };
    }

    appliedSort = base64.decodeAndParse(appliedSort);
    return appliedSort;
  },
};
