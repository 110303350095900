<template>
  <div
    class="game-params-items-root"
  >
    <BaseballTradingGameParams
      v-if="isBaseball"
      :global-params="globalParams"
      :is-calculated-mode="isCalculatedMode"
      @onParamChange="onGlobalParamChange"
      @onParamIncrease="onGlobalParamIncrease"
      @onParamDecrease="onGlobalParamDecrease"
    />
    <CommonSportsGameParams
      v-else
      :global-params="globalParams"
      :is-calculated-mode="isCalculatedMode"
      @onParamChange="onGlobalParamChange"
      @onParamIncrease="onGlobalParamIncrease"
      @onParamDecrease="onGlobalParamDecrease"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import CommonSportsGameParams from './CommonSportsGameParams';
import BaseballTradingGameParams from './baseball/BaseballTradingGameParams';
import sportIds from '@/services/helpers/sports';

const {
  BASEBALL_ID,
} = sportIds;

export default {
  emits: [
    'onParamChange',
    'onParamIncrease',
    'onParamDecrease',
  ],
  components: {
    CommonSportsGameParams,
    BaseballTradingGameParams,
  },
  props: {
    globalParams: {
      type: undefined,
      default: null,
    },
    isCalculatedMode: {
      type: Boolean,
      default: false,
    },
    sportId: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const params = computed(() => props.globalParams);
    const isBaseball = computed(() => props.sportId === BASEBALL_ID);
    const onGlobalParamChange = (value, param) => emit('onParamChange', value, param);
    const onGlobalParamIncrease = (param) => emit('onParamIncrease', param);
    const onGlobalParamDecrease = (param) => emit('onParamDecrease', param);

    return {
      params,
      isBaseball,
      onGlobalParamChange,
      onGlobalParamIncrease,
      onGlobalParamDecrease,
    };
  },
};
</script>

<style lang="scss">
.game-params-items-root {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  .game-param-single {
    width: 67px;
    &.is-error {
      width: 90px;
    }
    .game-param-single-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .short-name {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $gray800;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 12px;
        margin-bottom: 2px;
        font-weight: 600;
      }
      .game-param-single-name-icon {
        cursor: pointer;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
    .game-param-single-input {
      width: 100%;
      input {
        padding: 5px 8px;
      }
    }
  }
}
</style>
