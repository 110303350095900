<template>
  <div class="market-name">
    <!-- TODO: Add Tooltip instead of title once NM-599 is done -->
    <span
      :title="market.displayName"
    >{{ market.displayName }}</span>
  </div>
  <div class="market-selections">
    <Tooltip
      v-if="resultingStatusText"
      :text="resultingStatusText"
      bottom
    >
      <Icon
        :name="resultingStatusIcon"
      />
    </Tooltip>
    <ManualResultingEventMarketSelection
      v-for="selection in market.selections"
      :key="selection.selectionId"
      :market="market"
      :selection="selection"
      :selected-event="selectedEvent"
      @onSelectResulting="selectResulting"
      :resulting-payload="resultingPayload"
      :is-usa-view="isUsaView"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import MarketMapper from '@/services/helpers/market-mapper';
import ManualResultingEventMarketSelection from './ManualResultingEventMarketSelection';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';

export default {
  components: {
    ManualResultingEventMarketSelection,
    Tooltip,
    Icon,
  },
  props: {
    marketId: {
      type: String,
      required: true,
    },
    selectedEvent: {
      type: Object,
      required: true,
    },
    resultingPayload: {
      type: Object,
      required: true,
    },
    isUsaView: {
      type: Boolean,
      required: true,
    },
    homeTeam: {
      type: Object,
      required: true,
    },
    awayTeam: {
      type: Object,
      required: true,
    },
  },
  emits: ['onSelectResulting'],
  setup(props, { emit }) {
    const store = useStore();

    const market = computed(() => {
      const storeMarket = store.getters.getManualResultingEventMarketById(props.marketId);
      const marketDisplayConfiguration = store.getters.getSingleMarketDisplayConfiguration(storeMarket.marketCode, storeMarket.marketType.params.SELECTIONS);
      const mappedMarket = MarketMapper.mapUngroupedMarket({
        marketDisplayConfiguration,
        market: storeMarket,
        homeTeam: props.homeTeam,
        awayTeam: props.awayTeam,
      });
      return {
        ...mappedMarket,
        selections: MarketMapper.sortSelections({ selections: mappedMarket.selections, isUSAView: props.isUsaView }),
      };
    });

    const selectResulting = (payload) => {
      emit('onSelectResulting', { item: payload.item, market: payload.market, selectionId: payload.selectionId });
    };

    const resultingStatusIcon = computed(() => {
      if (market.value.resultingStatus) {
        if (market.value.resultingStatus === 'PENDING_RESULTING') {
          return 'question-mark';
        }
        if (market.value.resultingStatus === 'RESULT_AMENDED') {
          return 'exclamation-mark';
        }
      }
      return null;
    });

    const resultingStatusText = computed(() => {
      if (market.value.resultingStatus) {
        if (market.value.resultingStatus === 'PENDING_RESULTING') {
          return 'Settlement Pending';
        }
        if (market.value.resultingStatus === 'RESULT_AMENDED') {
          return 'Settlement Amended';
        }
      }
      return null;
    });

    return {
      market,
      selectResulting,
      resultingStatusIcon,
      resultingStatusText,
    };
  },
};
</script>

<style lang="scss">
</style>
