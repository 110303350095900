<template>
  <div class="odds-checker-table-row-cell">
    <div
      :class="['cell-state-icon', { 'live': event.matchState === 'LIVE' }]"
      v-if="column.type === 'label-icon'"
    >
      <Icon name="signal" />
    </div>
    <div
      class="odds-checker-cell-label"
      v-if="column.type !== 'market'"
    >
      <div
        class="odds-checker-cell-label-row"
        v-if="cellValue.topRow"
      >
        {{ cellValue.topRow }}
      </div>
      <div
        :class="['odds-checker-cell-label-row', {'player-props': column.id === 'playerName'}]"
        v-if="cellValue.bottomRow"
      >
        {{ cellValue.bottomRow }}
      </div>
    </div>
    <div
      v-if="column.type === 'market'"
      class="column-market-cell"
    >
      <div
        class="column-market-suspended"
        v-if="cellValue.offeringStatus === 'SUSPENDED'"
      >
        Suspended
      </div>
      <div class="column-market-cell-selections">
        <div
          :class="['column-market-cell-selection',
                   { 'up': cellValue.selections[0]?.price?.changedPrice === 'up' },
                   { 'down': cellValue.selections[0]?.price?.changedPrice === 'down'}]"
        >
          <div
            class="column-market-cell-selection-line"
            v-if="displayLine(cellValue.selections[0])"
          >
            {{ displayLine(cellValue.selections[0]) }}
          </div>
          <div class="column-market-cell-selection-price">
            {{ odd(cellValue.selections[0]?.price) }}
          </div>
        </div>
        <div
          :class="['column-market-cell-selection',
                   { 'up': cellValue.selections[1]?.price?.changedPrice === 'up' },
                   { 'down': cellValue.selections[1]?.price?.changedPrice === 'down'}]"
        >
          <div
            class="column-market-cell-selection-line"
            v-if="displayLine(cellValue.selections[1])"
          >
            {{ displayLine(cellValue.selections[1]) }}
          </div>
          <div class="column-market-cell-selection-price">
            {{ odd(cellValue.selections[1]?.price) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import {
  split, filter, includes, find, isNil,
} from 'lodash';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import Icon from '@/components/common/Icon';
import MarketMapper from '@/services/helpers/market-mapper';
import OddsConverter from '@/services/helpers/odds-converter';

export default {
  components: {
    Icon,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    column: {
      type: Object,
      required: true,
    },
    selectedMarket: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const cellValue = computed(() => {
      if (props.column.id === 'startsAt') {
        const time = props.event?.startsAt;
        const formatDate = format(zonedTimeToUtc(time, 'UTC'), 'MMM d');
        const formatTime = format(zonedTimeToUtc(time, 'UTC'), 'H:mm a');
        return {
          topRow: formatDate,
          bottomRow: formatTime,
        };
      }
      if (props.column.id === 'eventName') {
        const eventNameSplit = split(props.event?.eventName, ' v ');
        return props.event?.isUsaView
          ? {
            topRow: `${eventNameSplit[1]} @`,
            bottomRow: eventNameSplit[0],
          }
          : {
            topRow: `${eventNameSplit[0]}`,
            bottomRow: eventNameSplit[1],
          };
      }

      if (props.column.id === 'playerName') {
        const homeTeamShortName = props.event?.competitors?.[0]?.shortName;
        const awayTeamShortName = props.event?.competitors?.[1]?.shortName;
        const eventName = props.event?.isUsaView
          ? `${awayTeamShortName} @ ${homeTeamShortName}`
          : `${awayTeamShortName} @ ${homeTeamShortName}`;
        return {
          topRow: props.event.playerName,
          bottomRow: eventName,
        };
      }
      // If column is market
      const columnMarkets = filter(props.event?.markets?.nodes,
        (propMarket) => includes(propMarket.marketCode, props.selectedMarket) && propMarket.marketType.params.SELECTIONS === 2
        && (props.event?.matchState !== 'LIVE' || (props.event?.matchState === 'LIVE' && propMarket.inPlay)));
      const cellMarket = find(columnMarkets, (market) => ((market.source === props.column.id && market.operatorId === props.column.operatorId)
        && (props.selectedMarket === 'RESULT' || (props.selectedMarket !== 'RESULT' && market.marketSummary?.isMainLine))));
      if (!cellMarket) {
        return {
          offeringStatus: '',
          selections: [],
        };
      }
      return {
        ...cellMarket,
        selections: MarketMapper.sortSelections({ selections: cellMarket?.selections, isUSAView: props.event?.isUsaView }),
      };
    });

    const displayLine = (selection) => {
      if (!cellValue.value) return '';
      if (isNil(cellValue.value.marketType?.params?.LINE)) return '';
      const line = cellValue.value.marketType.params.LINE;
      if (includes(props.selectedMarket, 'OVER_UNDER')) {
        return `${selection.selectionType.selectionCode === 'OVER' ? 'o' : 'u'} ${line}`;
      }
      let lineValue = '';
      lineValue = line;
      if (selection.selectionType.selectionCode === 'HOME' && line > 0) {
        lineValue = `+${line}`;
      }
      if (selection.selectionType.selectionCode === 'AWAY' && line < 0) {
        lineValue = `+${Math.abs(line)}`;
      }
      if (selection.selectionType.selectionCode === 'AWAY' && line > 0) {
        lineValue = `-${line}`;
      }
      return lineValue;
    };

    const selectedOddFormat = computed(() => store.getters.selectedOddFormat?.name);

    const odd = (price) => {
      const americanOdd = price?.originalFormattedValue;
      if (!americanOdd) return '-';
      if (americanOdd && selectedOddFormat.value === 'american') return `${americanOdd.signIsPlus ? '+' : '-'}${americanOdd.value}`;
      if (selectedOddFormat.value === 'probability') {
        const probability = parseFloat((price.probability + price.bias) * 100).toFixed(1);
        return (probability % 1 > 0) ? `${probability}%` : `${Math.round(probability)}%`;
      }
      return OddsConverter.getMappedOddValue(price, selectedOddFormat.value);
    };

    return {
      cellValue,
      displayLine,
      odd,
    };
  },
};
</script>
<style lang="scss">
.odds-checker-table-row-cell {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  color: #191414;

  .cell-state-icon {
    height: 100%;
    width: 24px;
    align-items: center;
    display: flex;

    .icon {
      width: 16px;
      height: 16px;

      svg {
        path {
          fill: #CDCDCD;
        }
      }
    }

    &.live {
      .icon {
        svg {
          path {
            fill: #00BC57;
          }
        }
      }
    }
  }

  .odds-checker-cell-label {
    height: 42px;
    display: flex;
    flex-direction: column;

    .odds-checker-cell-label-row {
      &.player-props {
        color: #6D6D6D;
        font-size: 12px;
        margin-top: 1px;
      }
    }
  }

  .column-market-cell {
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    position: relative;

    .column-market-suspended {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      color: #661212;
      background-color: #FFEEEE;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }

    .column-market-cell-selections {
      height: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;

      &.line {
        color: #6D6D6D;

        .column-market-cell-selection {
          height: 50%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }

      .column-market-cell-selection {
        text-align: right;
        height: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &.up {
          background-color: #CCF2DD;
        }

        &.down {
          background-color: #FFD5D5;
        }

        .column-market-cell-selection-line {
          color: #6D6D6D;
        }

        .column-market-cell-selection-price {
          width: 48px;
          padding-right: 8px;
        }
      }

      .column-market-cell-selection-icon {
        width: 25px;
        height: 18px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        .icon {
          width: 12px;
          height: 12px;
        }
      }

      .empty-indicator {
        width: 25px;
        height: 18px;
      }
    }

    .icon {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
