import sportIds from '@/services/helpers/sports';

const {
  BASKETBALL_ID,
  FOOTBALL_ID,
  HOCKEY_ID,
  BASEBALL_ID,
} = sportIds;

export const generateInfoEventColumns = () => ([
  {
    id: 'startsAt',
    label: 'Time',
    type: 'label-icon',
    position: 0,
  },
  {
    id: 'eventName',
    label: 'Event',
    type: 'label-text',
    position: 1,
  },
]);

export const generateInfoPlayerColumns = () => ([
  {
    id: 'startsAt',
    label: 'Time',
    type: 'label-icon',
    position: 0,
  },
  {
    id: 'playerName',
    label: 'Player',
    type: 'label-text',
    position: 1,
  },
]);

export const generateInternalEventColumns = (client) => ([
  {
    id: 'INTERNAL_AGGREGATION',
    label: 'Huddle',
    type: 'market',
    position: 11,
    operatorId: client,
  },
]);

export const generateInternalClientsEventColumns = () => ([
  {
    id: 'INTERNAL_AGGREGATION',
    label: 'Huddle',
    type: 'market',
    position: 11,
    operatorId: 'Huddle',
  },
  {
    id: 'INTERNAL_AGGREGATION',
    label: 'Betr',
    type: 'market',
    position: 12,
    operatorId: 'Betr',
  },
  {
    id: 'INTERNAL_AGGREGATION',
    label: 'Bet Victor',
    type: 'market',
    position: 13,
    operatorId: 'BetVictor',
  },
  {
    id: 'INTERNAL_AGGREGATION',
    label: 'Instabet',
    type: 'market',
    position: 14,
    operatorId: 'InstaBet',
  },
  {
    id: 'INTERNAL_AGGREGATION',
    label: 'Rivalry',
    type: 'market',
    position: 15,
    operatorId: 'Rivalry',
  },
  {
    id: 'INTERNAL_AGGREGATION',
    label: 'Superbet',
    type: 'market',
    position: 16,
    operatorId: 'Superbet',
  },
  {
    id: 'INTERNAL_AGGREGATION',
    label: 'Tipwin',
    type: 'market',
    position: 17,
    operatorId: 'TipWin',
  },
]);

export const generateLSportsEventColumns = () => ([
  {
    id: 'FANDUEL',
    label: 'Fanduel',
    type: 'market',
    position: 22,
    operatorId: null,
  },
  {
    id: 'DRAFTKINGS',
    label: 'Draftkings',
    type: 'market',
    position: 23,
    operatorId: null,
  },
  {
    id: 'WILLIAMHILL',
    label: 'William Hill',
    type: 'market',
    position: 24,
    operatorId: null,
  },
  {
    id: 'PINNACLE',
    label: 'Pinnacle',
    type: 'market',
    position: 25,
    operatorId: null,
  },
  {
    id: 'BET_365',
    label: 'Bet365',
    type: 'market',
    position: 26,
    operatorId: null,
  },
  {
    id: 'BETVICTOR',
    label: 'Bet Victor',
    type: 'market',
    position: 27,
    operatorId: null,
  },
  {
    id: 'SBO',
    label: 'SBO Bet',
    type: 'market',
    position: 28,
    operatorId: null,
  },
  {
    id: 'UNIBET',
    label: 'Unibet',
    type: 'market',
    position: 29,
    operatorId: null,
  },
]);

export const generatePlayerFilterMarkets = () => ({
  [FOOTBALL_ID]: [
    {
      id: 'PLAYER_PASSING_ATTEMPTS_OVER_UNDER',
      label: 'Passing attempts',
    },
    {
      id: 'PLAYER_PASSING_COMPLETIONS_OVER_UNDER',
      label: 'Passing completions',
    },
    {
      id: 'PLAYER_PASSING_TOUCHDOWNS_OVER_UNDER',
      label: 'Passing TD',
    },
    {
      id: 'PLAYER_PASSING_YARDS_OVER_UNDER',
      label: 'Passing yards',
    },
    {
      id: 'PLAYER_RECEIVING_YARDS_OVER_UNDER',
      label: 'Receiving yards',
    },
    {
      id: 'PLAYER_RECEPTIONS_OVER_UNDER',
      label: 'Receptions',
    },
    {
      id: 'PLAYER_RUSHING_AND_PASSING_YARDS_OVER_UNDER',
      label: 'Rushing and passing yards',
    },
    {
      id: 'PLAYER_RUSHING_YARDS_OVER_UNDER',
      label: 'Rushing yards',
    },
    {
      id: 'PLAYER_TOUCHDOWN_X_OR_MORE',
      label: 'X or more TD',
    },
  ],
  [BASKETBALL_ID]: [
    {
      id: 'PLAYER_POINT_OVER_UNDER',
      label: 'Points',
    },
    {
      id: 'PLAYER_REBOUND_OVER_UNDER',
      label: 'Rebounds',
    },
    {
      id: 'PLAYER_ASSIST_OVER_UNDER',
      label: 'Assists',
    },
    {
      id: 'PLAYER_THREE_POINTER_OVER_UNDER',
      label: 'Three pointers',
    },
  ],
  [HOCKEY_ID]: [
    {
      id: 'PLAYER_POINT_OVER_UNDER',
      label: 'Points',
    },
    {
      id: 'PLAYER_GOAL_OVER_UNDER',
      label: 'Goals',
    },
    {
      id: 'PLAYER_ASSIST_OVER_UNDER',
      label: 'Assists',
    },
    {
      id: 'PLAYER_FIRST_GOALSCORER',
      label: 'First goalscorer',
    },
    {
      id: 'PLAYER_LAST_GOALSCORER',
      label: 'Last goalscorer',
    },
    {
      id: 'PLAYER_ANYTIME_GOALSCORER',
      label: 'Anytime goalscorer',
    },
  ],
  [BASEBALL_ID]: [
    {
      id: 'PLAYER_BASES_OVER_UNDER',
      label: 'Bases',
    },
    /* {
      id: 'PLAYER_HOME_RUN',
      label: 'Home runs',
    }, */
    /* {
      id: 'PLAYER_ON_BASE',
      label: 'On base',
    }, */
    /* {
      id: 'PLAYER_ON_BASE_TWO_PLUS',
      label: 'On Base Twice or More',
    }, */
    {
      id: 'PLAYER_RUN_OVER_UNDER',
      label: 'Runs',
    },
    {
      id: 'PLAYER_STRIKEOUT_OVER_UNDER',
      label: 'Strikeouts',
    },
  ],
});
