<template>
  <Modal
    title="List of markets"
    :visible="isActive"
    class="modal-container"
    @close="close"
  >
    <div class="include-markets-modal__content">
      <p class="modal-subtitle">
        Select the markets you want to include in this template.
      </p>
      <div class="search-container">
        <TextInput
          v-model="search"
          placeholder="Search markets"
          icon="search"
          small
        />
      </div>
      <div class="market-list">
        <div
          v-for="marketGroup in filteredMarketGroups"
          :key="marketGroup.groupCode"
          class="market-group__container"
        >
          <div class="group-item">
            <Checkbox
              :model-value="isMarketGroupApplied(marketGroup.groupCode)"
              @update:modelValue="marketGroupToggled(marketGroup.groupCode)"
            />
            <p>
              {{ marketGroup.groupName }}
            </p>
          </div>
          <div
            v-for="market in findMarketsByGroup(marketGroup.groupCode)"
            :key="market.marketTemplateId"
            class="market-item"
          >
            <Checkbox
              @click="marketToggled(market)"
              :model-value="market.included"
            />
            <p>{{ market.name }}</p>
          </div>
        </div>
      </div>
      <div class="actions__root">
        <Button
          variant="secondary"
          @click="close"
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          @click="addNewMarkets"
        >
          Save
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import {
  ref, computed, onMounted, onBeforeUnmount,
} from 'vue';
import {
  uniqBy, filter, includes, toLower, orderBy,
  cloneDeep, find, reduce, map, forEach, flatMap,
} from 'lodash';
import Modal from '@/components/common/Modal';
import Checkbox from '@/components/common/Checkbox';
import TextInput from '@/components/common/TextInput';
import Button from '@/components/common/Button';

export default {
  components: {
    Modal,
    Checkbox,
    TextInput,
    Button,
  },
  emits: ['close', 'onSave'],
  props: {
    isActive: {
      type: Boolean,
      default: () => false,
    },
    markets: {
      type: Array,
      default: () => [],
    },
    marketGroups: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const search = ref('');
    const marketList = ref(cloneDeep(props.markets));
    const changedMarketList = ref([]);
    const uniqueMarketTemplates = computed(() => uniqBy(marketList.value, 'marketTemplateId'));
    const getMarketsByCode = (marketCode) => filter(uniqueMarketTemplates.value, { marketCode });
    const marketsWithGroups = computed(() => flatMap(props.marketGroups, (group) => (reduce(
      group.markets.nodes,
      (allMarkets, market) => [
        ...allMarkets,
        ...map(getMarketsByCode(market.marketCode), (m) => ({
          ...m,
          groupName: group.displayName,
          groupCode: group.code,
        })),
      ], [],
    ))));
    const marketsWithoutGroups = computed(() => {
      const marketCodesWithGroups = map(marketsWithGroups.value, 'marketTemplateId');
      const withoutGroups = filter(marketList.value, (market) => !includes(marketCodesWithGroups, market.marketTemplateId));
      return map(withoutGroups, (market) => ({
        ...market,
        groupCode: 'UNGROUPED',
        groupName: 'Ungrouped',
      }));
    });
    const allMarkets = computed(() => uniqBy([
      ...orderBy(marketsWithGroups.value, 'position'),
      ...orderBy(marketsWithoutGroups.value, 'position'),
    ], 'marketTemplateId'));
    const filteredMarkets = computed(() => filter(
      allMarkets.value,
      (market) => (
        includes(toLower(market.name), toLower(search.value))
        || includes(toLower(market.groupName), toLower(search.value))
      ),
    ));
    const filteredMarketGroups = computed(() => uniqBy(map(filteredMarkets.value, ({ groupName, groupCode }) => ({ groupName, groupCode })), 'groupCode'));
    const findMarketsByGroup = (code) => filter(filteredMarkets.value, { groupCode: code });

    const isMarketGroupApplied = (groupCode) => {
      const marketsByGroup = findMarketsByGroup(groupCode);
      const appliedMarkets = filter(marketsByGroup, (c) => c.included);
      return marketsByGroup.length === appliedMarkets.length;
    };

    const marketToggled = (market) => {
      const originalChangedMarket = find(marketList.value, { marketTemplateId: market.marketTemplateId });
      originalChangedMarket.included = !originalChangedMarket.included;

      const changedMarket = find(changedMarketList.value, { marketTemplateId: market.marketTemplateId });
      if (changedMarket) {
        changedMarketList.value = filter(changedMarketList.value, (m) => m.marketTemplateId !== changedMarket.marketTemplateId);
      } else {
        changedMarketList.value.push(originalChangedMarket);
      }
    };

    const marketGroupToggled = (groupCode) => {
      const regionApplied = isMarketGroupApplied(groupCode);
      const originalMarketGroups = findMarketsByGroup(groupCode);
      forEach(originalMarketGroups, (m) => {
        if (regionApplied) {
          marketToggled(m);
        } else if (!m.included) {
          marketToggled(m);
        }
      });
    };

    const close = () => {
      emit('close');
    };
    const addNewMarkets = () => {
      emit('onSave', changedMarketList.value);
      close();
    };

    const onEscEventListener = (e) => {
      if (e.key === 'Escape') { close(); }
    };
    onMounted(() => document.addEventListener('keydown', onEscEventListener));
    onBeforeUnmount(() => document.removeEventListener('keydown', onEscEventListener));

    return {
      search,
      filteredMarketGroups,
      findMarketsByGroup,
      close,
      marketToggled,
      addNewMarkets,
      isMarketGroupApplied,
      marketGroupToggled,
    };
  },
};
</script>
<style lang="scss">
.modal-container .modal__dialog {
    height: 80%;
    min-width: 530px;

    .modal__content {
        overflow: hidden;
    }
}
.include-markets-modal__content {
    height: 100%;
    padding: 0 32px 32px 32px;

    .modal-subtitle {
        font-size: 12px;
        color: $gray700;
    }

    .search-container {
        height: 70px;
        padding-top: 13px;
    }

    .market-list {
        padding-left: 2px;
        height: calc(100% - 140px);
        overflow-y: auto;
        .market-group__container {
          margin-bottom: 30px;

          .group-item {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            p {
                margin-left: 8px;
            }
          }
        }
        .market-item {
            display: flex;
            align-items: center;
            padding: 17px 0 17px 12px;
            border-left: 1px solid $gray400;
            p {
                margin-left: 8px;
            }
        }
    }

    .actions__root {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        padding-top: 18px;

        .button {
            display: flex;
            justify-content: center;

            &.button--primary {
                width: 90px;
            }
        }
    }
}
</style>
