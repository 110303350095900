<template>
  <div class="market-configuration-selections-tab">
    <div class="market-configuration-selections-tab-header-label">
      Market selections
    </div>
    <div
      class="market-configuration-selection"
      v-for="(selection, index) in marketSelections"
      :key="index"
    >
      <Toggle
        :model-value="isEnabledSelection(selection)"
        @update:model-value="(newValue) => updateDisabledSelections(selection, newValue)"
      />
      {{ getSelectionName(selection) }}
    </div>
  </div>
</template>§

<script>
/* import { computed } from 'vue'; */
import { find } from 'lodash';
import Toggle from '@/components/common/Toggle';
import { selectionName } from '@/services/helpers/market-template-mapper';

export default {
  components: {
    Toggle,
  },
  props: {
    marketSelections: {
      type: Array,
      default: () => [],
    },
    disabledSelections: {
      type: Array,
      default: () => [],
    },
    sportLabel: {
      type: String,
      default: '',
    },
  },
  emits: {
    'update:disabledSelections': {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const updateDisabledSelections = (selection, value) => {
      emit('update:disabledSelections', { selection, newValue: value });
    };

    const equalSelectionParams = (disabledSelection, selection) => (
      disabledSelection.bandSize === selection.bandSize
        && disabledSelection.homeScore === selection.homeScore
        && disabledSelection.awayScore === selection.awayScore
        && disabledSelection.goals === selection.goals
        && disabledSelection.order === selection.order
        && disabledSelection.lowerBound === selection.lowerBound
        && disabledSelection.upperBound === selection.upperBound
        && disabledSelection.team === selection.team
        && disabledSelection.number === selection.number
    );

    const isEnabledSelection = (selection) => !find(props.disabledSelections, (disabledSelection) => disabledSelection?.type === selection?.type && equalSelectionParams(disabledSelection, selection));

    const getSelectionName = (selection) => selectionName(selection, props.sportLabel);

    return {
      updateDisabledSelections,
      isEnabledSelection,
      getSelectionName,
    };
  },
};
</script>

<style lang="scss">
.market-configuration-selections-tab {
  display: flex;
  flex-direction: column;
  padding: 20px 0 0;
  box-sizing: border-box;
  width: 100%;
  min-height: 55px;

  .market-configuration-selections-tab-header-label {
    margin-bottom: 16px;
    font-weight: 600;
  }

  .market-configuration-selection {
    display: flex;
    height: 32px;
    align-items: center;
    gap: 8px;
  }
}
</style>
