<template>
  <div class="customer-profiling-filters-field">
    <div
      v-if="!modelValue.meta.hideLabel"
      class="customer-profiling-filters-field__label"
    >
      {{ modelValue.label }}
    </div>
    <component
      v-if="component"
      :is="component"
      :key="JSON.stringify(modelValue)"
      :model-value="modelValue"
      :error="error"
      @update:model-value="updateFilter"
      @remove="removeFilter"
    />
    <div
      v-if="!modelValue.meta.hideError && error"
      class="customer-profiling-filters-field__error"
    >
      <Icon
        class="customer-profiling-filters-field__error-icon"
        name="exclamation"
      />
      <span class="customer-profiling-filters-field__error-message">
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { FilterType } from '@/services/helpers/filters';
import Icon from '@/components/common/Icon';
import CustomerProfilingFiltersFieldDate from './CustomerProfilingFiltersFieldDate';
import CustomerProfilingFiltersFieldDecimal from './CustomerProfilingFiltersFieldDecimal';
import CustomerProfilingFiltersFieldInteger from './CustomerProfilingFiltersFieldInteger';
import CustomerProfilingFiltersFieldSport from './CustomerProfilingFiltersFieldSport';
import CustomerProfilingFiltersFieldString from './CustomerProfilingFiltersFieldString';
import CustomerProfilingFiltersEventsSelect from './CustomerProfilingFiltersEventsSelect';

export default {
  components: {
    Icon,
    CustomerProfilingFiltersFieldDate,
    CustomerProfilingFiltersFieldDecimal,
    CustomerProfilingFiltersFieldInteger,
    CustomerProfilingFiltersFieldSport,
    CustomerProfilingFiltersFieldString,
    CustomerProfilingFiltersEventsSelect,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    error: {
      type: String,
      default: '',
    },
  },
  emits: {
    'update:modelValue': {
      type: Object,
    },
    remove: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const component = computed(() => {
      switch (props.modelValue.type) {
      case FilterType.DATE:
        return 'CustomerProfilingFiltersFieldDate';
      case FilterType.DECIMAL:
      case FilterType.ODDS:
        return 'CustomerProfilingFiltersFieldDecimal';
      case FilterType.INTEGER:
        return 'CustomerProfilingFiltersFieldInteger';
      case FilterType.SPORT:
        return 'CustomerProfilingFiltersFieldSport';
      case FilterType.EVENTS:
        return 'CustomerProfilingFiltersEventsSelect';
      default:
        return 'CustomerProfilingFiltersFieldString';
      }
    });

    const updateFilter = (newModelValue) => {
      emit('update:modelValue', newModelValue);
    };
    const removeFilter = () => {
      emit('remove', props.modelValue);
    };

    return {
      component,
      updateFilter,
      removeFilter,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-filters-field {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;

  &__label {
    color: #6D6D6D;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    font-size: 10px;
    line-height: 11.85px;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__error {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 10px;
    margin-top: 4px;
    width: 100%;

    &-icon {
      flex-shrink: 0;
      background-color: #ff2e2d;
      fill: #fff;
      border-radius: 100%;
      width: 12px;
      min-width: 12px;
      max-width: 12px;
      height: 12px;
      min-height: 12px;
      max-height: 12px;
    }

    &-message {
      color: #ff2e2d;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
  }
}
</style>
