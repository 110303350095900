<template>
  <div class="customer-profiling-list">
    <div
      v-if="isInitializing"
      class="customer-profiling-list__content"
    >
      <Spinner />
    </div>
    <div
      v-else
      class="customer-profiling-list__content"
    >
      <CustomerProfilingListHeader />
      <CustomerProfilingListTable />
      <CustomerProfilingListFooter />
      <CustomerProfilingListSidebar />
    </div>
  </div>
</template>

<script>
import { isEqual, filter as filterArray, debounce } from 'lodash';
import { computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { transformFilters, isFilterApplied } from '@/services/helpers/filters';
import { deserializeCustomerListFilterQuery } from '@/services/helpers/customer-profiling';
import Spinner from '@/components/common/Spinner';
import CustomerProfilingListTable from './CustomerProfilingListTable';
import CustomerProfilingListHeader from './CustomerProfilingListHeader';
import CustomerProfilingListFooter from './CustomerProfilingListFooter';
import CustomerProfilingListSidebar from './CustomerProfilingListSidebar';

export default {
  components: {
    Spinner,
    CustomerProfilingListTable,
    CustomerProfilingListHeader,
    CustomerProfilingListFooter,
    CustomerProfilingListSidebar,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const isInitializing = computed(() => store.getters['betTicker/allCustomersIsInitializing']);
    const customers = computed(() => store.getters['betTicker/allCustomersData']);
    const filters = computed((() => store.getters['betTicker/allCustomersFilters']));
    const appliedFilters = computed(() => filterArray(filters.value, isFilterApplied));
    const page = computed(() => parseInt(route.query.page || '1', 10));
    const limit = computed(() => parseInt(route.query.limit || '50', 10));

    onMounted(async () => {
      const allDeserializedFilters = await deserializeCustomerListFilterQuery(route.query.filter, {
        oddsFormat: store.getters.selectedOddFormat?.id || '',
      });
      const allFilters = await transformFilters(allDeserializedFilters);
      store.dispatch('betTicker/initAllCustomers', {
        filters: allFilters,
        limit: limit.value,
        page: page.value,
      });
    });
    watch(
      () => route.query.filter,
      async (newFilterQuery) => {
        const newDeserializedFilters = await deserializeCustomerListFilterQuery(newFilterQuery, {
          oddsFormat: store.getters.selectedOddFormat?.id || '',
        });
        const newFilters = await transformFilters(newDeserializedFilters);
        const newAppliedFilters = filterArray(newFilters, isFilterApplied);
        if (isEqual(appliedFilters.value, newAppliedFilters)) {
          store.dispatch('betTicker/setAllCustomersFilters', newFilters);
        } else {
          store.dispatch('betTicker/loadAllCustomers', {
            filters: newFilters,
            limit: limit.value,
            page: 1,
            reset: true,
          });
        }
      },
    );
    watch(
      () => [
        route.query.limit,
        route.query.page,
      ],
      ([newLimit, newPage], [oldLimit, oldPage]) => {
        if (
          isInitializing.value
          || (
            newLimit === oldLimit
            && newPage === oldPage
          )
        ) return;
        store.dispatch('betTicker/loadAllCustomers', {
          filters: filters.value,
          limit: newLimit,
          page: newPage,
          reset: oldLimit !== newLimit,
        });
      },
    );
    watch(
      () => route.query.sort,
      debounce((newValues, oldValues) => {
        if (isEqual(newValues, oldValues)) return;

        store.dispatch('betTicker/loadAllCustomers', {
          filters: filters.value,
          limit: limit.value,
          page: 1,
          reset: true,
        });
      }, 1),
    );
    return {
      isInitializing,
      customers,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-list {
  width: 100%;
  height: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
</style>
