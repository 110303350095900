<template>
  <div
    :class="[
      'customer-profiling-details-bets-table-cell',
      'customer-profiling-details-bets-table-cell--is-stake-cell',
    ]"
  >
    <span class="customer-profiling-details-bets-table-cell__text">
      {{ displayValue }}
    </span>
    <Tooltip
      v-if="bet.freeStake > 0"
      text="Bet has free stake"
    >
      <Chip>
        FREE
      </Chip>
    </Tooltip>
  </div>
</template>

<script>
import { get, isNil } from 'lodash';
import { computed } from 'vue';
import Chip from '@/components/common/Chip';
import Tooltip from '@/components/common/Tooltip';

export default {
  components: {
    Chip,
    Tooltip,
  },
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    bet: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const displayValue = computed(() => {
      const value = get(props.bet, props.field);
      return isNil(value) ? '-' : value;
    });

    return {
      displayValue,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-bets-table-cell {
  &--is-stake-cell {
    background: inherit;
    overflow: visible;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 8px;

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    >.tooltip .chip{
        background-color: $white;
      }
  }
}
</style>
