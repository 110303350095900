<template>
  <div
    :class="['liability-table-row', { 'event-disabled': isEventDisabled }]"
    @click="loadEvent"
  >
    <div class="liability-table-row-column label-type">
      <Icon
        v-if="showLiabilitiesByClient && hasLiabilitiesByClient"
        class="chevron-icon"
        :name="isShowLiabilitiesByClient ? 'chevron-up' : 'chevron-down'"
        @click.stop="toggleShowLiabilitiesByClient"
      />
      <div
        :class="['label-event-name', { 'has-liability': hasLiabilitiesByClient }]"
      >
        <div
          class="column-label"
          :title="eventName"
        >
          {{ eventName }}
        </div>
        <div :class="['column-label info', {'live': matchState === 'Live'}]">
          {{ event.competition?.competitionName }} •
          <span>{{ matchState }}</span>
        </div>
      </div>
    </div>
    <LiabilitiesTableRowValues :liabilities="liabilities" />
  </div>
  <div
    v-if="showLiabilitiesByClient && isShowLiabilitiesByClient"
    class="liabilities-by-client-root"
  >
    <div
      v-for="client in liabilitiesByClient"
      :key="client.operatorId"
      :class="['liability-table-row row-by-client']"
    >
      <div class="liability-table-row-column label-type">
        <div
          class="column-label operator-label"
          :title="eventName"
        >
          {{ client.operatorId }}
        </div>
      </div>
      <LiabilitiesTableRowValues :liabilities="client.liabilities" />
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import {
  split, capitalize, find, includes,
  map, filter, uniqBy,
} from 'lodash';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { useQueryParameters } from '@/composables';
import { getQueryModel } from './liability-table-helper';
import { getSuperAdminData } from '@/services/helpers/super-admin';
import Icon from '@/components/common/Icon';
import LiabilitiesTableRowValues from './LiabilityEventsTableRowValues';

const queryModel = getQueryModel();

export default {
  components: {
    Icon,
    LiabilitiesTableRowValues,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { state, setParameters } = useQueryParameters(queryModel);
    const dollarValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const numberFormat = new Intl.NumberFormat();
    const eventName = computed(() => {
      const eventNameSplit = split(props.event?.eventName, ' v ');
      return props.event?.isUsaView
        ? `${eventNameSplit[1]} @ ${eventNameSplit[0]}`
        : `${eventNameSplit[0]} - ${eventNameSplit[1]}`;
    });
    const { isSuperAdmin, SUPER_ADMIN_CLIENT, client } = getSuperAdminData();

    const matchState = computed(() => {
      if (props.event?.matchState === 'FINISHED' || props.event?.matchState === 'CANCELED') return capitalize(props.event?.matchState?.toLowerCase() || '');
      if (props.event?.matchState === 'LIVE') return 'Live';
      const time = props.event?.startsAt;
      const formatDate = format(zonedTimeToUtc(time, 'UTC'), 'd MMM');
      const formatTime = format(zonedTimeToUtc(time, 'UTC'), 'H:mm');
      return `${formatDate} (${formatTime})`;
    });

    const isEventDisabled = computed(() => props.event?.inLimbo);

    const fillerObj = {
      betsCount: '-',
      volume: '-',
      pnl: '-',
    };
    const createLiabilities = (obj) => {
      if (!obj) {
        return fillerObj;
      }
      return {
        betsCount: obj.betsCount,
        volume: dollarValue.format(obj.volume / 100),
        pnl: `${obj.pnl > 0 ? '+' : ''}${numberFormat.format(obj.pnl / 100)}`,
        operatorId: obj.operatorId,
      };
    };

    // Liabilities sum
    const globalEventLiabilities = computed(() => {
      const liabilityObj = props.event?.globalEventLiabilities?.nodes?.[0];
      return createLiabilities(liabilityObj);
    });

    const globalMoneyLineLiabilities = computed(() => {
      const foundMarket = find(props.event?.globalMarketLiabilities?.nodes,
        (market) => market.marketCode === 'RESULT' && market.marketType?.params?.SELECTIONS === 2);
      return createLiabilities(foundMarket);
    });

    const globalSpreadLiabilities = computed(() => {
      const foundMarket = find(props.event?.globalLineMarketLiabilities?.nodes,
        (market) => includes(market.marketCode, 'HANDICAP') && market.marketTypeWithoutLine?.params?.SELECTIONS === 2);
      return createLiabilities(foundMarket);
    });

    const globalTotalLiabilities = computed(() => {
      const foundMarket = find(props.event?.globalLineMarketLiabilities?.nodes,
        (market) => includes(market.marketCode, 'OVER_UNDER') && market.marketTypeWithoutLine?.params?.SELECTIONS === 2);
      return createLiabilities(foundMarket);
    });

    // Liability per client
    const eventLiabilities = computed(() => map(props.event?.eventLiabilities?.nodes, (liabilityObj) => createLiabilities(liabilityObj)));
    const moneyLineLiabilities = computed(() => map(
      filter(props.event?.marketLiabilities?.nodes, (market) => market.marketCode === 'RESULT'
        && market.marketType?.params?.SELECTIONS === 2),
      (market) => createLiabilities(market),
    ));

    const spreadLiabilities = computed(() => map(
      filter(props.event?.lineMarketLiabilities?.nodes, (market) => includes(market.marketCode, 'HANDICAP')
        && market.marketTypeWithoutLine?.params?.SELECTIONS === 2),
      (market) => createLiabilities(market),
    ));

    const totalLiabilities = computed(() => map(
      filter(props.event?.lineMarketLiabilities?.nodes, (market) => includes(market.marketCode, 'OVER_UNDER')
        && market.marketTypeWithoutLine?.params?.SELECTIONS === 2),
      (market) => createLiabilities(market),
    ));

    const hasLiabilitiesByClient = computed(() => !!eventLiabilities.value.length
      || !!moneyLineLiabilities.value.length
      || !!spreadLiabilities.value.length
      || !!totalLiabilities.value.length);

    const isShowLiabilitiesByClient = ref(false);
    const showLiabilitiesByClient = computed(() => isSuperAdmin && SUPER_ADMIN_CLIENT === client);

    const toggleShowLiabilitiesByClient = () => {
      isShowLiabilitiesByClient.value = !isShowLiabilitiesByClient.value;
    };

    const liabilities = computed(() => {
      if (showLiabilitiesByClient.value) {
        return [
          globalEventLiabilities.value,
          globalMoneyLineLiabilities.value,
          globalSpreadLiabilities.value,
          globalTotalLiabilities.value,
        ];
      }
      return [
        eventLiabilities.value[0] || fillerObj,
        moneyLineLiabilities.value[0] || fillerObj,
        spreadLiabilities.value[0] || fillerObj,
        totalLiabilities.value[0] || fillerObj,
      ];
    });

    const clientsWithBets = computed(() => filter(uniqBy([
      ...map(eventLiabilities.value, 'operatorId'),
      ...map(moneyLineLiabilities.value, 'operatorId'),
      ...map(spreadLiabilities.value, 'operatorId'),
      ...map(totalLiabilities.value, 'operatorId'),
    ], (operatorId) => operatorId)), (operatorId) => operatorId);

    const liabilitiesByClient = computed(() => (
      map(clientsWithBets.value, (operatorId) => {
        const eventLiability = find(eventLiabilities.value, { operatorId });
        const moneyLineLiability = find(moneyLineLiabilities.value, { operatorId });
        const spreadLiability = find(spreadLiabilities.value, { operatorId });
        const totalLiability = find(totalLiabilities.value, { operatorId });

        return {
          operatorId,
          liabilities: [
            eventLiability || fillerObj,
            moneyLineLiability || fillerObj,
            spreadLiability || fillerObj,
            totalLiability || fillerObj,
          ],
        };
      })
    ));

    const loadEvent = () => {
      setParameters({
        ...state.value,
        eventId: props.event?.eventId,
      });
    };

    return {
      eventName,
      matchState,
      liabilities,
      loadEvent,
      isEventDisabled,
      showLiabilitiesByClient,
      liabilitiesByClient,
      hasLiabilitiesByClient,
      isShowLiabilitiesByClient,
      toggleShowLiabilitiesByClient,
    };
  },
};
</script>

<style lang="scss">
.liability-table-row {
  width: 100%;
  height: 44px;
  display: flex;
  border-bottom: 1px solid #F0F0F0;
  min-width: fit-content;
  cursor: pointer;

  &:last-child {
    border-bottom: 0;
  }

  &.row-by-client {
    background-color: $gray300;

    &:first-child {
      border-top: 1px solid $gray700;
    }
    &:last-child {
      border-bottom: 1px solid $gray700;
    }
  }

  &:nth-child(even) {
    background-color: #FAFAFA;
  }

  &.event-disabled {
    background-color: $gray400;
    border-left: 4px solid #A9A9A9;
  }

  .liability-table-row-column {
    height: 100%;
    padding: 0 8px;
    color: #191414;
    display: flex;
    width: 20%;
    min-width: 20%;
    align-items: center;
    border-right: 1px solid #F0F0F0;

    &:last-child {
      border-right: 0;
    }

    &.label-type {
      display: flex;
      align-items: center;
      & .chevron-icon {
        width: 32px;
        height: 16px;
        padding: 0 8px;
        svg path {
          stroke: $gray700;
        }
      }
      & .operator-label {
        padding-left: 32px;
      }
      & .label-event-name {
        width: 100%;
        &.has-liability {
          width: calc(100% - 32px);
        }
        display: flex;
        flex-direction: column;
        align-items: unset;
        justify-content: center;
        border-right: 0;

        .column-label {
          max-width: 95%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          &.info {
            font-size: 12px;
            color: #6D6D6D;

            &.live {
              span {
                color: #00BC57;
              }
            }
          }
        }
    }
  }

    .column-liability {
      height: 100%;
      width: 33.33%;
      padding: 0 8px;
      display: flex;
      align-items: center;

      .column-liability-pnl {
        border: 1px solid #00BC57;
        background-color: #E5F8EE;
        color: #004B23;
        height: 23px;
        padding: 0 8px;
        gap: 4px;
        border-radius: 12px;
        border: 1px 0px 0px 0px;
        display: flex;
        align-items: center;

        .icon {
          width: 16px;
          height: 16px;

          svg {
            path {
              fill: #004B23;
            }
          }
        }

        &.down {
          background-color: #FFEEEE;
          border-color: #FF2E2D;
          color: #661212;
          .icon {
            svg {
              path {
                fill: #661212;
              }
            }
          }
        }
      }
    }
  }
}

</style>
