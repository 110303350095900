import { Base64 } from 'js-base64';

const encode = (value) => Base64.btoa(value);

const decode = (encodedValue) => Base64.atob(encodedValue);

const decodeAndParse = (value) => {
  try {
    return JSON.parse(decode(value));
  } catch {
    return '';
  }
};

export default {
  encode,
  decode,
  decodeAndParse,
};
