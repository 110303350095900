<template>
  <div
    :class="[
      'dropdown-item',
      { 'dropdown-item--clickable': clickable },
      { 'dropdown-item--disabled': disabled },
    ]"
    :tabindex="clickable ? 0 : -1"
    @click.stop="click()"
    @keydown.stop="click($event)"
  >
    <Spinner
      v-if="loading"
      small
    />
    <slot />
  </div>
</template>

<script>
import { inject } from 'vue';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    Spinner,
  },
  props: {
    clickable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'click',
  ],
  setup(props, { emit }) {
    const isMenuOpen = inject('isMenuOpen');
    const click = (event) => {
      if (
        props.disabled
        || props.loading
        || !props.clickable
        || (event && event?.keyCode !== 13)
      ) return;

      isMenuOpen.value = false;
      emit('click');
    };

    return {
      click,
    };
  },
};
</script>

<style lang="scss">
.dropdown {
  .dropdown-item {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    min-width: 100%;
    height: 44px;
    padding: 0 12px;
    box-shadow: inset 0px -1px 0px #F0F0F0;
    cursor: default;

    .spinner {
      margin-right: 10px;
    }

    &:last-of-type {
      box-shadow: none;
    }

    &.dropdown-item--clickable {
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: #FAFAFA;
        outline: none;
      }
    }
    &.dropdown-item--disabled {
      color: $gray700;
      cursor: not-allowed;
    }
  }
}
</style>
