import {
  includes, find, assign, each,
  get,
} from 'lodash';

const lineMarketCodes = ['GOAL_OVER_UNDER', 'POINT_OVER_UNDER', 'RUN_OVER_UNDER', 'GOAL_HANDICAP', 'POINT_HANDICAP', 'RUN_HANDICAP'];

const updateEventLiability = (event, payload, isGlobal) => {
  if (isGlobal) {
    return {
      event: {
        ...event,
        globalEventLiabilities: {
          nodes: [
            {
              betsCount: payload.betsCount,
              pnl: payload.pnl,
              volume: payload.volume,
            },
          ],
        },
      },
      update: true,
    };
  }
  const eventToUpdate = find(event.eventLiabilities.nodes, (liability) => liability.operatorId === payload.operatorId);
  if (!eventToUpdate) {
    event.eventLiabilities.nodes.push(payload);
  } else {
    each(event.eventLiabilities.nodes, (liability) => {
      if (liability.operatorId === payload.operatorId) {
        assign(liability, { ...payload });
      }
    });
  }
  return {
    event,
    update: true,
  };
};

const updateMarketLiability = (event, payload, isGlobal) => {
  if (payload.marketType?.params?.SELECTIONS === 2 && includes(payload.marketCode, 'RESULT')) {
    const liabilityObjName = isGlobal ? 'globalMarketLiabilities' : 'marketLiabilities';
    const marketToUpdate = find(
      get(event, liabilityObjName).nodes,
      (market) => market.marketCode === payload.marketCode
      && (!isGlobal ? market.operatorId === payload.operatorId : true),
    );
    if (!marketToUpdate) {
      get(event, liabilityObjName).nodes.push(payload);
    } else {
      each(get(event, liabilityObjName).nodes, (market) => {
        if (
          market.marketCode === payload.marketCode
          && (!isGlobal ? market.operatorId === payload.operatorId : true)
        ) {
          assign(market, { ...payload });
        }
      });
    }
    return {
      event,
      update: true,
    };
  }
  return {
    event,
    update: false,
  };
};

const updateAggregatedMarketLiability = (event, payload, isGlobal) => {
  if (payload.marketTypeWithoutLine?.params?.SELECTIONS === 2 && includes(lineMarketCodes, payload.marketCode)) {
    const liabilityObjName = isGlobal ? 'globalLineMarketLiabilities' : 'lineMarketLiabilities';
    const marketToUpdate = find(
      get(event, liabilityObjName).nodes,
      (market) => market.marketCode === payload.marketCode
      && (!isGlobal ? market.operatorId === payload.operatorId : true),
    );
    if (!marketToUpdate) {
      get(event, liabilityObjName).nodes.push(payload);
    } else {
      each(get(event, liabilityObjName).nodes, (market) => {
        if (
          market.marketCode === payload.marketCode
          && (!isGlobal ? market.operatorId === payload.operatorId : true)
        ) {
          assign(market, { ...payload });
        }
      });
    }
    return {
      event,
      update: true,
    };
  }
  return {
    event,
    update: false,
  };
};

export const updateLiability = (event, payload) => {
  const isGlobal = includes(payload.messageType, 'GLOBAL');
  if (payload.messageType === 'MARKET_LIABILITY' || payload.messageType === 'GLOBAL_MARKET_LIABILITY') {
    return updateMarketLiability(event, payload, isGlobal);
  }
  if (payload.messageType === 'AGGREGATED_MARKET_LIABILITY' || payload.messageType === 'AGGREGATED_GLOBAL_MARKET_LIABILITY') {
    return updateAggregatedMarketLiability(event, payload, isGlobal);
  }
  return updateEventLiability(event, payload, isGlobal);
};
