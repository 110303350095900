<template>
  <PlayerParamsSingle
    :player-data="playerData"
    :active-tab="activeTab"
    :team-label="teamLabel"
    :is-calculated-mode="isCalculatedMode"
    :active-params="activeTabParams"
    :sport-id="FOOTBALL_ID"
    @onParamUpdate="onParamUpdate"
    @onPlayerParamUpdate="updateParam"
  />
</template>

<script>
import { computed } from 'vue';
import {
  map, has, get,
} from 'lodash';
import {
  createAFPlayerQuarterbackParams,
  createAFPlayerNonQuarterbackParams,
  createAFPlayerDefensiveParams,
} from '@/components/player-setup/params-helper';
import sportIds from '@/services/helpers/sports';
import PlayerParamsSingle from '@/components/player-setup/common/PlayerParamsSingle';

const {
  FOOTBALL_ID,
} = sportIds;

export default {
  emits: ['onParamUpdate'],
  props: {
    playerData: {
      type: Object,
      default: () => ({}),
    },
    activeTab: {
      type: Object,
      default: () => ({}),
    },
    teamLabel: {
      type: String,
      default: '',
    },
    currentCatcherIndex: {
      type: Number || null,
      default: null,
    },
    isCalculatedMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PlayerParamsSingle,
  },
  setup(props, { emit }) {
    const mergeParamsWithConfig = (params, config) => map(config, (param) => {
      const value = has(params, param.key) ? params[param.key] : 0;
      return {
        ...param,
        isError: value < param?.minValue || value > param?.maxValue,
        errorMessage: `${param?.shortName} must be a value between ${param?.minValue} and ${param?.maxValue}`,
        value,
      };
    });

    // Quarterback params
    const quarterbackConfig = createAFPlayerQuarterbackParams();
    const quarterbackParams = computed(() => {
      const hasQuarterbackParams = has(props.playerData, 'quarterbackParams');
      if (!hasQuarterbackParams) return {};
      const quarterbackParamsUpdated = get(props.playerData, 'quarterbackParams');

      const passingRunningRatio = get(quarterbackParamsUpdated, 'passingRunningRatio');
      const passingTouchdownFactor = get(quarterbackParamsUpdated, 'passingTouchdownFactor');
      const passingCompletionFactor = get(quarterbackParamsUpdated, 'passingCompletionFactor');
      const passingYardsFactor = get(quarterbackParamsUpdated, 'passingYardsFactor');
      const interceptionTurnoverRatio = get(quarterbackParamsUpdated, 'interceptionTurnoverRatio');
      const runShortNoScore = get(quarterbackParamsUpdated, 'runShortNoScore');
      const runLongNoScore = get(quarterbackParamsUpdated, 'runLongNoScore');
      const runShortScore = get(quarterbackParamsUpdated, 'runShortScore');
      const runLongScore = get(quarterbackParamsUpdated, 'runLongScore');
      return {
        ...quarterbackParamsUpdated,
        passingRunningRatio: passingRunningRatio ? parseFloat((passingRunningRatio).toFixed(2)) : 0,
        passingTouchdownFactor: passingTouchdownFactor ? parseFloat((passingTouchdownFactor).toFixed(2)) : 0,
        passingCompletionFactor: passingCompletionFactor ? parseFloat(passingCompletionFactor.toFixed(3)) : 0,
        passingYardsFactor: passingYardsFactor ? parseFloat(passingYardsFactor.toFixed(3)) : 0,
        interceptionTurnoverRatio: interceptionTurnoverRatio ? parseFloat(interceptionTurnoverRatio.toFixed(3)) : 0,
        runShortNoScore: runShortNoScore ? parseFloat(runShortNoScore.toFixed(3)) : 0,
        runLongNoScore: runLongNoScore ? parseFloat(runLongNoScore.toFixed(3)) : 0,
        runShortScore: runShortScore ? parseFloat(runShortScore.toFixed(3)) : 0,
        runLongScore: runLongScore ? parseFloat(runLongScore.toFixed(3)) : 0,
      };
    });
    const quarterbackParamsConfig = computed(() => mergeParamsWithConfig(quarterbackParams.value, quarterbackConfig));
    // Non-quarterback params
    const nonQuarterbackConfig = createAFPlayerNonQuarterbackParams();
    const nonQuarterbackParams = computed(() => {
      const hasNonQuarterbackParams = has(props.playerData, 'nonQuarterbackParams');
      if (!hasNonQuarterbackParams) return {};
      const nonQuarterbackParamsUpdated = get(props.playerData, 'nonQuarterbackParams');
      const receiveShortNoScore = get(nonQuarterbackParamsUpdated, 'receiveShortNoScore');
      const receiveLongNoScore = get(nonQuarterbackParamsUpdated, 'receiveLongNoScore');
      const receiveShortScore = get(nonQuarterbackParamsUpdated, 'receiveShortScore');
      const receiveLongScore = get(nonQuarterbackParamsUpdated, 'receiveLongScore');
      const runShortNoScore = get(nonQuarterbackParamsUpdated, 'runShortNoScore');
      const runLongNoScore = get(nonQuarterbackParamsUpdated, 'runLongNoScore');
      const runShortScore = get(nonQuarterbackParamsUpdated, 'runShortScore');
      const runLongScore = get(nonQuarterbackParamsUpdated, 'runLongScore');

      return {
        ...nonQuarterbackParamsUpdated,
        receiveShortNoScore: receiveShortNoScore ? parseFloat((receiveShortNoScore).toFixed(2)) : 0,
        receiveLongNoScore: receiveLongNoScore ? parseFloat((receiveLongNoScore).toFixed(2)) : 0,
        receiveShortScore: receiveShortScore ? parseFloat(receiveShortScore.toFixed(3)) : 0,
        receiveLongScore: receiveLongScore ? parseFloat(receiveLongScore.toFixed(3)) : 0,
        runShortNoScore: runShortNoScore ? parseFloat(runShortNoScore.toFixed(3)) : 0,
        runLongNoScore: runLongNoScore ? parseFloat(runLongNoScore.toFixed(3)) : 0,
        runShortScore: runShortScore ? parseFloat(runShortScore.toFixed(3)) : 0,
        runLongScore: runLongScore ? parseFloat(runLongScore.toFixed(3)) : 0,
      };
    });
    const nonQuarterbackParamsConfig = computed(() => mergeParamsWithConfig(nonQuarterbackParams.value, nonQuarterbackConfig));

    // Defensive params
    const defensiveConfig = createAFPlayerDefensiveParams();
    const defensiveParams = computed(() => {
      const hasDefensiveParams = has(props.playerData, 'defensivePlayerParams');
      if (!hasDefensiveParams) return {};
      const defensiveParamsUpdated = get(props.playerData, 'defensivePlayerParams');
      const interseption = get(defensiveParamsUpdated, 'interseption');
      const tackle = get(defensiveParamsUpdated, 'tackle');
      const sack = get(defensiveParamsUpdated, 'sack');
      return {
        ...defensiveParamsUpdated,
        interseption: interseption ? parseFloat(interseption.toFixed(2)) : 0,
        tackle: tackle ? parseFloat(tackle.toFixed(2)) : 0,
        sack: sack ? parseFloat(sack.toFixed(2)) : 0,
      };
    });
    const defensiveParamsConfig = computed(() => mergeParamsWithConfig(defensiveParams.value, defensiveConfig));

    const activeTabParams = computed(() => {
      if (props.activeTab.key === 'quarterbacks') {
        return quarterbackParamsConfig.value;
      }
      if (props.activeTab.key === 'non-quarterbacks') {
        return nonQuarterbackParamsConfig.value;
      }
      if (props.activeTab.key === 'defensive') {
        return defensiveParamsConfig.value;
      }
      return [];
    });
    const updateParam = ({ newVal, param }) => {
      let paramKey = '';
      switch (props.activeTab.key) {
      case 'quarterbacks':
        paramKey = `quarterbackParams.${param.key}`;
        break;
      case 'non-quarterbacks':
        paramKey = `nonQuarterbackParams.${param.key}`;
        break;
      case 'defensive':
        paramKey = `defensivePlayerParams.${param.key}`;
        break;
      default:
        paramKey = '';
      }
      emit('onParamUpdate', {
        playerId: props.playerData.playerId,
        paramKey,
        value: newVal,
        updateKey: param.updateKey,
        activeTabUpdated: props.activeTab.key,
      });
    };

    const onParamUpdate = (paramUpdated) => {
      emit('onParamUpdate', paramUpdated);
    };

    return {
      activeTabParams,
      FOOTBALL_ID,
      updateParam,
      onParamUpdate,
    };
  },
};
</script>

<style lang="scss">
</style>
