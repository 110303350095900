<template>
  <div
    class="liability-table-row-column liability-type"
    v-for="(liability, index) in liabilities"
    :key="index"
  >
    <div class="column-liability">
      {{ liability.betsCount }}
    </div>
    <div class="column-liability">
      {{ liability.volume }}
    </div>
    <div class="column-liability">
      <div
        :class="['column-liability-pnl', {'down': parseFloat(liability.pnl) < 0}]"
        v-if="liability.pnl !== '-' && liability.pnl !== '+0' && liability.pnl !== '-0'"
      >
        <Icon :name="parseFloat(liability.pnl) < 0 ? 'trend-down' : 'trend-up'" />
        {{ liability.pnl }}
      </div>
      <div v-else>
        {{ liability.pnl }}
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon';

export default {
  props: {
    liabilities: {
      type: Array,
      required: true,
    },
  },
  components: {
    Icon,
  },
  setup() {
    return {
    };
  },
};
</script>
