<template>
  <div class="baseball-add-players-modal-root">
    <Modal
      :title="`Add players for ${teamName}`"
      subtitle="Choose players you want to offer."
      :visible="isVisible"
      @close="onModalClose"
      class="add-players-modal-root"
    >
      <div class="header-actions">
        <div
          @click="toggleSelectAll"
          :class="{ 'deselect-active': !isSelectAllActive }"
          class="select-all-players-toggle-root"
        >
          <Checkbox
            :model-value="!isSelectAllActive"
            @update:modelValue="toggleSelectAll"
          />
          {{ isSelectAllActive ? 'Select all players' : 'Deselect all players' }}
        </div>
        <div
          class="show-all-players-toggle-root"
          @click="toggleShowAllPlayersActive"
        >
          <span>Show all players</span>
          <Toggle
            :model-value="isShowAllPlayersActive"
            @update:model-value="toggleShowAllPlayersActive"
          />
        </div>
      </div>
      <ParamsTabs
        v-if="playerTabs.length"
        class="baseball-players-tabs"
        :tabs="playerTabs"
        :active-tab="activeTab"
        @onTabChange="onTabChange"
      />
      <div
        v-if="!playersToAdd.length && !playersListLoading && !playerSearch"
        class="no-players-message"
      >
        <p>
          No players for selected team
        </p>
        <Button
          icon="link-external"
          @click="openTeamEdit"
        >
          Team edit
        </Button>
      </div>
      <div
        v-else
        class="modal-data"
      >
        <div class="search-field-container">
          <TextInput
            class="search-field"
            :model-value="playerSearch"
            @update:modelValue="updateSearch"
            icon="search"
            placeholder="Search player"
            small
          />
          <Button
            icon="link-external"
            @click="openTeamEdit"
          >
            Team edit
          </Button>
        </div>
        <div
          v-if="!playersToAddFiltered.length && !playersListLoading"
          class="no-players-message"
        >
          <p>
            No search results
          </p>
        </div>
        <div class="items-to-add">
          <Spinner
            v-if="playersListLoading"
            small
          />
          <div
            v-for="item in playersToAddFiltered"
            :key="item.value"
            class="player-to-add-item"
          >
            <div class="player-action">
              <Checkbox
                :model-value="isPlayerAdded(item.value)"
                :disabled="item.isAlreadyAdded"
                @update:modelValue="playerToggled(item.value)"
              />
              <p
                @click="playerToggled(item.value)"
                :title="item.playerIndex"
              >
                {{ item.label }}
              </p>
            </div>
            <div class="player-info-wrapper">
              <div class="player-info">
                <Tooltip
                  :text="item?.isInTeamLineup ? 'Player is in lineup' : 'Player is not in lineup'"
                  left
                >
                  <div
                    class="player-lineup-indicator"
                    :class="{ 'is-in-lineup': item?.isInTeamLineup }"
                  >
                    <Icon
                      :name="item?.isInTeamLineup ? 'users-check' : 'users-x'"
                    />
                  </div>
                </Tooltip>
              </div>
              <div class="player-info mapping-projections">
                <Tooltip
                  :text="isPlayerMapped(item.value) ? 'Player is mapped for projections' : 'Player is not mapped for projections'"
                  left
                >
                  <Icon
                    :name="isPlayerMapped(item.value) ? 'link-not-broken' : 'link-broken'"
                    :class="{ 'is-mapped': isPlayerMapped(item.value) }"
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="playersToAdd.length"
        class="modal-footer"
      >
        <div class="modal-actions">
          <Button
            variant="primary"
            :disabled="addPlayersDisabled"
            @click="addSelectedPlayers"
          >
            Add players
          </Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>

import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import {
  find, filter, map, forEach,
  includes, toLower, findIndex,
  cloneDeep,
} from 'lodash';
import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import TextInput from '@/components/common/TextInput';
import Checkbox from '@/components/common/Checkbox';
import Spinner from '@/components/common/Spinner';
import sportIds from '@/services/helpers/sports';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';
import ParamsTabs from '@/components/player-setup/ParamsTabs';
import Toggle from '@/components/common/Toggle';

const {
  FOOTBALL_ID,
} = sportIds;

export default {
  components: {
    Modal,
    Button,
    TextInput,
    Checkbox,
    Spinner,
    ParamsTabs,
    Icon,
    Tooltip,
    Toggle,
  },
  emits: [
    'onClose',
    'onAddSelectedPlayers',
    'onSelectedTabChange',
  ],
  props: {
    teamName: {
      type: String,
      default: () => '',
    },
    playersToAdd: {
      type: Array,
      default: () => [],
    },
    isVisible: {
      type: Boolean,
      default: () => false,
    },
    playerTabs: {
      type: Array,
      default: () => [],
      required: false,
    },
    activeTab: {
      type: Object,
      default: () => {},
      required: false,
    },
    teamId: {
      type: String,
      default: () => '',
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const playersListLoading = computed(() => store.getters.teamSquadListLoading);
    const playerSearch = ref('');
    const isShowAllPlayersActive = ref(false);
    const playersToAddFiltered = computed(() => filter(
      // If the show all players is active, we should show all players even those that are already added
      filter(props.playersToAdd, (player) => (!isShowAllPlayersActive.value ? !player.isAlreadyAdded : true)),
      (player) => includes(toLower(player.label), toLower(playerSearch.value)),
    ));

    const addedPlayers = ref([]);
    const addPlayersDisabled = computed(() => !addedPlayers.value.length);
    const isSelectAllActive = computed(() => {
      let addedPlayersByType;
      let alreadyAddedPlayersCount;
      if (props.activeTab.key === 'quarterbacks') {
        addedPlayersByType = filter(addedPlayers.value, (player) => player.isQuarterback);
        alreadyAddedPlayersCount = filter(props.playersToAdd, ({ isQuarterback }) => isQuarterback).length;
      } else if (props.activeTab.key === 'non-quarterbacks') {
        addedPlayersByType = filter(addedPlayers.value, (player) => player.isOffensiveNonQB);
        alreadyAddedPlayersCount = filter(props.playersToAdd, ({ isOffensiveNonQB }) => isOffensiveNonQB).length;
      } else if (props.activeTab.key === 'kickers') {
        addedPlayersByType = filter(addedPlayers.value, (player) => player.isKicker);
        alreadyAddedPlayersCount = filter(props.playersToAdd, ({ isKicker }) => isKicker).length;
      } else if (props.activeTab.key === 'defensive') {
        addedPlayersByType = filter(addedPlayers.value, (player) => player.isDefensive);
        alreadyAddedPlayersCount = filter(props.playersToAdd, ({ isDefensive }) => isDefensive).length;
      }
      return (props.playersToAdd.length - alreadyAddedPlayersCount) !== addedPlayersByType.length;
    });

    const toggleShowAllPlayersActive = () => {
      isShowAllPlayersActive.value = !isShowAllPlayersActive.value;
    };

    const onModalClose = () => {
      emit('onClose');
      addedPlayers.value = [];
      playerSearch.value = '';
      emit('onSelectedTabChange', props.playerTabs[0]);
    };
    const updateSearch = (val) => { playerSearch.value = val; };

    const isPlayerAdded = (val) => {
      if (find(props.playersToAdd, ({ value }) => value === val)?.isAlreadyAdded) {
        return true;
      }
      if (props.activeTab.key === 'quarterbacks') {
        return !!find(addedPlayers.value, (player) => player.value === val && player.isQuarterback);
      } if (props.activeTab.key === 'non-quarterbacks') {
        return !!find(addedPlayers.value, (player) => player.value === val && player.isOffensiveNonQB);
      } if (props.activeTab.key === 'kickers') {
        return !!find(addedPlayers.value, (player) => player.value === val && player.isKicker);
      } if (props.activeTab.key === 'defensive') {
        return !!find(addedPlayers.value, (player) => player.value === val && player.isDefensive);
      }
      return false;
    };
    const playerToggled = (val) => {
      const player = find(addedPlayers.value, ({ value }) => value === val);
      // If the player is not added yet, add him to the list
      if (!player) {
        addedPlayers.value.push({
          value: val,
          isQuarterback: props.activeTab.key === 'quarterbacks',
          isOffensiveNonQB: props.activeTab.key === 'non-quarterbacks',
          isKicker: props.activeTab.key === 'kickers',
          isDefensive: props.activeTab.key === 'defensive',
        });
        return;
      }
      // If the player is added we need to update him
      const playerIndex = findIndex(addedPlayers.value, ({ value }) => value === val);
      const addedPlayersUpdated = cloneDeep(addedPlayers.value);

      if (props.activeTab.key === 'quarterbacks') {
        player.isQuarterback = !player.isQuarterback;
      } else if (props.activeTab.key === 'non-quarterbacks') {
        player.isOffensiveNonQB = !player.isOffensiveNonQB;
      } else if (props.activeTab.key === 'kickers') {
        player.isKicker = !player.isKicker;
      } else if (props.activeTab.key === 'defensive') {
        player.isDefensive = !player.isDefensive;
      }

      // If player isn't a pitcher or a hitter, remove him from the list
      if (!player.isQuarterback && !player.isOffensiveNonQB && !player.isKicker && !player.isDefensive) {
        addedPlayers.value = filter(addedPlayers.value, ({ value }) => value !== val);
        return;
      }

      // Update the player in the list
      addedPlayersUpdated[playerIndex] = player;
    };

    const toggleSelectAll = () => {
      if (isSelectAllActive.value) {
        const playersValues = map(props.playersToAdd, (player) => player.value);
        forEach(playersValues, (val) => {
          let alreadyAddedByType;
          if (props.activeTab.key === 'quarterbacks') {
            alreadyAddedByType = find(addedPlayers.value, (player) => player.value === val && player.isQuarterback)
            || find(props.playersToAdd, (player) => player.value === val && player.isQuarterback);
          } else if (props.activeTab.key === 'non-quarterbacks') {
            alreadyAddedByType = find(addedPlayers.value, (player) => player.value === val && player.isOffensiveNonQB)
            || find(props.playersToAdd, (player) => player.value === val && player.isOffensiveNonQB);
          } else if (props.activeTab.key === 'kickers') {
            alreadyAddedByType = find(addedPlayers.value, (player) => player.value === val && player.isKicker)
            || find(props.playersToAdd, (player) => player.value === val && player.isKicker);
          } else if (props.activeTab.key === 'defensive') {
            alreadyAddedByType = find(addedPlayers.value, (player) => player.value === val && player.isDefensive)
            || find(props.playersToAdd, (player) => player.value === val && player.isDefensive);
          }
          if (!alreadyAddedByType) {
            // If the player is not added by type we should check if it is added at all
            const alreadyAdded = find(addedPlayers.value, (player) => player.value === val);
            if (alreadyAdded) {
              // If the player is added, we should update him
              const playerIndex = findIndex(addedPlayers.value, (player) => player.value === val);
              addedPlayers.value[playerIndex] = {
                value: val,
                isQuarterback: props.activeTab.key === 'quarterbacks' || addedPlayers.value[playerIndex].isQuarterback,
                isOffensiveNonQB: props.activeTab.key === 'non-quarterbacks' || addedPlayers.value[playerIndex].isOffensiveNonQB,
                isKicker: props.activeTab.key === 'kickers' || addedPlayers.value[playerIndex].isKicker,
                isDefensive: props.activeTab.key === 'defensive' || addedPlayers.value[playerIndex].isDefensive,
              };
            } else {
              // If the player is not added, we should add him
              addedPlayers.value.push({
                value: val,
                isQuarterback: props.activeTab.key === 'quarterbacks',
                isOffensiveNonQB: props.activeTab.key === 'non-quarterbacks',
                isKicker: props.activeTab.key === 'kickers',
                isDefensive: props.activeTab.key === 'defensive',
              });
            }
          }
        });
      } else {
        // If the select all is active, we should deselect all by type
        if (props.activeTab.key === 'quarterbacks') {
          addedPlayers.value = map(addedPlayers.value, (player) => ({
            ...player,
            isQuarterback: false,
          }));
        } else if (props.activeTab.key === 'non-quarterbacks') {
          addedPlayers.value = map(addedPlayers.value, (player) => ({
            ...player,
            isOffensiveNonQB: false,
          }));
        } else if (props.activeTab.key === 'kickers') {
          addedPlayers.value = map(addedPlayers.value, (player) => ({
            ...player,
            isKicker: false,
          }));
        } else if (props.activeTab.key === 'defensive') {
          addedPlayers.value = map(addedPlayers.value, (player) => ({
            ...player,
            isDefensive: false,
          }));
        }

        // At the end we should remove players that are not pitchers or hitters
        addedPlayers.value = filter(addedPlayers.value, (player) => player.isQuarterback || player.isOffensiveNonQB);
      }
    };

    const addSelectedPlayers = () => {
      emit('onAddSelectedPlayers', {
        addedPlayers: addedPlayers.value,
      });
      addedPlayers.value = [];
      playerSearch.value = '';
    };

    const openTeamEdit = () => {
      const { query } = route;
      const { href } = router.resolve({
        name: 'teamManualEdit',
        query: {
          ...query,
          ...(query.sport ? {} : { sport: FOOTBALL_ID }),
          teamId: props.teamId,
        },
      });
      window.open(href, '_blank');
    };

    const onTabChange = (tab) => emit('onSelectedTabChange', tab);
    const playerSetupMappedPlayersForProjections = computed(() => map(store.getters.playerSetupMappedPlayersForProjections, (player) => player.playerId));
    const isPlayerMapped = (playerId) => includes(playerSetupMappedPlayersForProjections.value, playerId);

    return {
      playersToAddFiltered,
      playersListLoading,
      addPlayersDisabled,
      isSelectAllActive,
      playerSearch,
      onModalClose,
      updateSearch,
      isPlayerAdded,
      playerToggled,
      toggleSelectAll,
      addSelectedPlayers,
      onTabChange,
      isShowAllPlayersActive,
      toggleShowAllPlayersActive,
      isPlayerMapped,
      openTeamEdit,
    };
  },
};
</script>

<style lang="scss">
.baseball-add-players-modal-root {
  .add-players-modal-root {
    .modal__dialog {
      position: relative;
    }
  }

  .baseball-players-tabs {
    padding: 0;
    height: auto;
    margin-bottom: 8px;
    .params-tabs-item {
      width: 100%;
    }
  }
  .header-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 16px 8px;

    .select-all-players-toggle-root {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
    }

    .show-all-players-toggle-root {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
    }
  }
  .search-field-container {
    display: flex;
    justify-content: space-between;
    gap: 4px;
    .search-field {
      flex: 1;
    }
  }
  .no-players-message {
    button {
      margin-top: 12px;
    }
  }
  .items-to-add {
    .player-to-add-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      .player-lineup-indicator {
        display: flex;
        padding: 2px;

        .icon svg path {
          stroke: $gray800;
        }

        &.is-in-lineup {
          background-color: $brandPrimary500;
          border-radius: 4px;
          .icon svg path {
            stroke: $white;
          }
        }
      }

      .tooltip {
        .tooltip-element {
          display: flex;
          align-items: center;
          }
          .tooltip-text {
            font-weight: 400;
          }
      }

      .player-action {
        display: flex;
        align-items: center;
        gap: 4px;
      }
      .player-info {
        display: flex;
      }
    }
  }
  .player-info-wrapper {
    display: flex;
    gap: 8px;

    .player-info {
      display: flex;

      &.mapping-projections {
        .icon {
          width: 16px;
          height: 16px;

          svg {
            path {
              fill: #ff2e2d;
            }
          }

          &.is-mapped {
            svg {
              path {
                fill: #191414;;
              }
            }
          }
        }
      }
    }
  }
  .modal-footer {
    .modal-actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;
    }
  }
}
</style>
