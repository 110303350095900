<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33301 4.00065C7.33301 3.63246 7.63148 3.33398 7.99967 3.33398C8.36786 3.33398 8.66634 3.63246 8.66634 4.00065V9.33398C8.66634 9.70217 8.36786 10.0007 7.99967 10.0007C7.63148 10.0007 7.33301 9.70217 7.33301 9.33398V4.00065Z"
      fill="#175FFF"
    />
    <path
      d="M8.66634 12.0007C8.66634 12.3688 8.36786 12.6673 7.99967 12.6673C7.63148 12.6673 7.33301 12.3688 7.33301 12.0007C7.33301 11.6325 7.63148 11.334 7.99967 11.334C8.36786 11.334 8.66634 11.6325 8.66634 12.0007Z"
      fill="#175FFF"
    />
  </svg>
</template>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
