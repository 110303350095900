<template>
  <div class="edit-team-modal-root">
    <div
      class="edit-team-modal-content"
      :class="{ 'no-tabs': isCreate }"
    >
      <div
        v-if="confirmDeleteActive"
        class="disabled-layer-root"
      />
      <div
        v-if="!isCreate"
        class="edit-team-tabs"
      >
        <div
          class="edit-team-tab-item"
          :class="{ 'selected': tab.value === selectedTab }"
          v-for="tab in editTeamTabs"
          :key="tab.value"
          @click="onTabChange(tab)"
        >
          {{ tab.label }}
        </div>
      </div>
      <div
        v-if="selectedTab === 'general'"
        class="edit-team-modal-content-section"
      >
        <div class="team-info-title">
          Team display information
        </div>
        <div class="team-info">
          <div class="team-info-label">
            <p>Team name</p>
          </div>
          <TextInput
            :model-value="data.name"
            :placeholder="'Input team name'"
            no-clear-button
            @update:modelValue="onTeamNameUpdate"
          />
          <div class="team-info-label">
            <p>Short team name</p>
          </div>
          <TextInput
            :model-value="data.shortName"
            :placeholder="'Input short team name'"
            no-clear-button
            @update:modelValue="onTeamShortNameUpdate"
          />
          <div class="team-info-label">
            <p>Division</p>
          </div>
          <TextInput
            :model-value="data.division"
            :placeholder="'Input division'"
            no-clear-button
            @update:modelValue="onTeamDivisionUpdate"
          />
          <div class="team-info-label">
            <p>Stadium</p>
          </div>
          <TextInput
            :model-value="data.stadium"
            :placeholder="'Input stadium'"
            no-clear-button
            @update:modelValue="onTeamStadiumUpdate"
          />
          <div class="team-info-label">
            <p>City</p>
          </div>
          <TextInput
            :model-value="data.city"
            :placeholder="'Input city'"
            no-clear-button
            @update:modelValue="onTeamCityUpdate"
          />
          <div class="team-info-label">
            <p>State</p>
          </div>
          <TextInput
            :model-value="data.state"
            :placeholder="'Input state'"
            no-clear-button
            @update:modelValue="onTeamStateUpdate"
          />
        </div>
      </div>
      <div
        v-if="selectedTab === 'squad'"
        class="edit-team-modal-content-section"
      >
        <div class="team-info-title">
          <p>Players assigned to squad</p>
        </div>
        <div class="add-player-to-squad">
          <AddPlayerToSquadSearch
            @onPlayerAdd="addPlayerToSquad"
            :team-squad="teamSquad"
          />
        </div>
        <div
          v-if="teamSquad.length"
          class="team-squad"
        >
          <div
            v-for="player in teamSquad"
            :key="player.playerId"
            class="player-item"
          >
            <div class="player-info">
              <span>{{ player?.personalInfo?.name }}</span>
              <TextInput
                class="jersey-num-input"
                :model-value="player?.jerseyNumber"
                :placeholder="'Jersey Nr.'"
                no-clear-button
                @update:modelValue="onPlayerJerseyNumberChange($event, player?.playerId)"
              />
            </div>
            <div class="player-actions">
              <div class="remove-player-btn">
                <Icon
                  @click="removePlayerFromSquad(player.playerId)"
                  name="trash"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="no-squad-data"
        >
          No squad data
        </div>
      </div>
      <div
        v-if="selectedTab === 'mapping'"
        class="edit-team-modal-content-section"
      >
        <div class="mappings-section-header">
          <div class="mappings-section-header-item">
            Source feed
          </div>
          <div class="mappings-section-header-item">
            External Reference
          </div>
        </div>
        <div
          v-for="mapping in data.mappings"
          :key="mapping.extId"
          class="team-info mappings"
        >
          <div class="team-info-item">
            <span>{{ mapping.feed }}</span>
          </div>
          <div class="team-info-item">
            <span>{{ mapping.extRef }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!confirmDeleteActive"
      class="player-squad-actions"
    >
      <div class="left-side">
        <div
          v-if="!isCreate"
          class="delete-btn"
          @click="toggleConfirmDelete"
        >
          Delete team
        </div>
      </div>
      <div class="right-side">
        <div
          @click="closeModal"
          class="dismiss-btn"
        >
          Dismiss
        </div>
        <Button
          class="save-btn"
          :variant="'primary'"
          @click="onSaveChanges"
          :disabled="isSubmitDisabled"
        >
          Save
        </Button>
      </div>
    </div>
    <div
      v-else
      class="confirm-delete"
    >
      <div class="left-side">
        Confirm delete for {{ teamData.name }}
      </div>
      <div class="right-side">
        <div
          @click="toggleConfirmDelete"
          class="dismiss-btn"
        >
          Dismiss
        </div>
        <Button
          :variant="'danger'"
          @click="deleteTeam"
        >
          Delete team
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import {
  map, cloneDeep, filter, isEqual, toLower, find, orderBy,
} from 'lodash';
import TextInput from '@/components/common/TextInput';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import AddPlayerToSquadSearch from './AddPlayerToSquadSearch';
import { getSportNameBySportId } from '@/services/helpers/mappings-mapper';

export default {
  emits: ['closeModal'],
  props: {
    teamData: {
      type: Object,
      default: () => {},
    },
    isCreate: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    TextInput,
    Icon,
    Button,
    AddPlayerToSquadSearch,
  },
  setup(props, { emit }) {
    const store = useStore();
    const editTeamTabs = ref([
      {
        value: 'general',
        label: 'General',
      },
      {
        value: 'squad',
        label: 'Squad',
      },
      {
        value: 'mapping',
        label: 'Mapping',
      },
    ]);
    const selectedTab = ref(editTeamTabs.value[0].value);
    const originalData = ref(cloneDeep({
      ...props.teamData,
      squad: props.teamData.squad || [],
    }));
    const data = ref(cloneDeep(originalData.value));
    const teamSquad = computed(() => orderBy(map(data.value.squad, (item) => (
      {
        jerseyNumber: item.discriminator,
        ...item.player,
      }
    )), 'personalInfo.name'));
    const isSubmitDisabled = computed(() => isEqual(originalData.value, data.value));
    const selectedSport = computed(() => store.getters.manualEditSelectedSport);
    const confirmDeleteActive = ref(false);

    const onTeamNameUpdate = (val) => { data.value.name = val; };
    const onTeamShortNameUpdate = (val) => { data.value.shortName = val; };
    const onTeamDivisionUpdate = (val) => { data.value.division = val; };
    const onTeamStadiumUpdate = (val) => { data.value.stadium = val; };
    const onTeamCityUpdate = (val) => { data.value.city = val; };
    const onTeamStateUpdate = (val) => { data.value.state = val; };
    const onPlayerJerseyNumberChange = (val, playerId) => {
      const player = find(data.value.squad, (item) => item.player.playerId === playerId);
      player.discriminator = val;
    };
    const removePlayerFromSquad = (playerId) => {
      data.value.squad = filter(data.value.squad, (item) => item.player.playerId !== playerId);
    };

    const addPlayerToSquad = (player) => {
      data.value.squad.push({
        discriminator: player.discriminator || '',
        player: {
          playerId: player.playerId,
          sport: toLower(getSportNameBySportId(selectedSport.value)),
          personalInfo: {
            name: player.name,
          },
        },
      });
    };
    const toggleConfirmDelete = () => { confirmDeleteActive.value = !confirmDeleteActive.value; };
    const onSaveChanges = () => {
      if (props.isCreate) {
        store.dispatch('manuallyCreateTeam', data.value);
      } else {
        store.dispatch('updateTeamData', data.value);
      }
    };
    const deleteTeam = () => store.dispatch('manuallyRemoveTeam', data.value);
    const closeModal = () => { emit('closeModal'); };

    const onTabChange = (tab) => {
      selectedTab.value = tab.value;
    };

    return {
      editTeamTabs,
      selectedTab,
      data,
      teamSquad,
      isSubmitDisabled,
      confirmDeleteActive,
      onTeamNameUpdate,
      onTeamShortNameUpdate,
      onTeamDivisionUpdate,
      onTeamStadiumUpdate,
      onTeamCityUpdate,
      onTeamStateUpdate,
      removePlayerFromSquad,
      onSaveChanges,
      closeModal,
      addPlayerToSquad,
      onPlayerJerseyNumberChange,
      toggleConfirmDelete,
      deleteTeam,
      onTabChange,
    };
  },
};
</script>

<style lang="scss">
  .edit-team-modal-root {
    .edit-team-modal-content {
      position: relative;
      padding: 16px;
      .disabled-layer-root {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .5);
        z-index: 1;
      }
      &.no-tabs {
        padding-top: 0;
      }
      .edit-team-tabs {
        display: flex;
        align-items: center;
        .edit-team-tab-item {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px;
          font-size: 14px;
          line-height: 16px;
          border-bottom: 2px solid $gray500;
          cursor: pointer;

          &.selected {
            border-bottom: 2px solid $brandPrimary500;
          }
        }
      }
      .edit-team-modal-content-section {
        width: 100%;
        padding: 32px 0;

        .mappings-section-header {
          display: flex;
          padding: 0 8px;
          background-color: $gray300;
          color: $gray800;
          .mappings-section-header-item {
            padding: 8px;
            width: 50%;
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
    input {
      height: auto;
    }
    .team-info-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      margin-bottom: 16px;
    }
    .team-info-label {
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
      color: $gray800;
      padding-bottom: 4px;
      margin-top: 16px;

      &.small {
        padding: 0;
        text-transform: capitalize;
      }
    }
    .team-info {
      padding-bottom: 32px;
      &.mappings {
        display: flex;
        padding: 8px;
        align-items: flex-start;
        border-bottom: 1px solid $gray400;
        &:last-child {
          border: none;
        }
        .team-info-item {
          width: 50%;
          padding: 8px;
        }
      }

      .text-field,
      .team-info-item {
        width: 100%;
      }
    }
    .team-squad {
      max-height: 250px;
      overflow: auto;
      padding-left: 1px;

      .player-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 16px;
        cursor: pointer;
        .player-info {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-right: 8px;
        }
        span {
          display: inline-block;
          padding: 4px 0;
        }

        .jersey-num-input {
          height: auto;
          max-width: 110px;
        }

        .player-actions {
          gap: 8px;
          align-items: center;
          .remove-player-btn {
            width: 18px;
            height: 18px;
            cursor: pointer;
            .icon {
              svg {
                path {
                  fill: $error500;
                }
              }
            }
          }
        }
      }
    }
    .no-squad-data {
      min-height: 150px;
      text-align: center;
      padding: 32px 0;
      color: $gray800;
    }
    .player-squad-actions,
    .confirm-delete {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      padding: 16px;
      border-top: 1px solid $gray300;

      .save-btn {
        padding: 0 24px;
      }
      .left-side,
      .right-side {
        width: 50%;
      }
      .right-side {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
      }

      .delete-btn,
      .dismiss-btn {
        cursor: pointer;
      }
      .delete-btn {
        color: $error500;
      }
    }
  }
</style>
